import React from 'react';

const DateControl = ({ name, value, className, onChange, disabled, min, max }) => {   
    // console.log(value + " " + name + " " + options.length) 
    // console.log("min date " + min)
    // console.log("max date " + max)

    return (
        <input
            type="date"              
            className={className || 'form-date-input'}    
            name={name}             
            value={value}                     
            disabled={disabled || false } 
            onChange={((e) => { 
                const d1 = new Date(e.target.value)                
                onChange(d1)                         
            })}  
            max={max}
            min={min}          
            >            
        </input>
    )
}

         

export default DateControl;