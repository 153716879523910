import React, { useState, useEffect } from 'react'
import { AiOutlineFileExcel, AiOutlineUndo } from 'react-icons/ai';
import FileSaver from 'file-saver';

import { FetchService, ExportService, StorageService } from '../../services/';
// import ExportService from '../../services/ExportService';
import ConfigIcon from '../../util/configicon';
// import FormGroup from '../../components/shared/form-group'
import { toShortDateString } from '../../util/date-utils';
import MassnahmenListItems from '../../context/MassnahmenListItems';
import { ZConfig } from '../../context/ZConfig';

const ZahlungenAktuell = () => {

    const [errorText, setErrorText] = useState(null)

    const [zahlungsLaufJahr, setZahlungsLaufJahr] = useState(null)
    const [zahlungsLaufNumber, setZahlungsLaufNumber] = useState(null);
    
    const [offeneBestaetigungen, setOffeneBestaetigungen] = useState(null) 
    const [nachweisbareMassnahmen, setNachweisbareMassnahmen] = useState(null)
    const [nachweisNichtGedruckt ,setNachweisNichtGedruckt ] = useState(null)
    const [anzahlZurZahlungVorgesehen , setAnzahlZurZahlungVorgesehen ] = useState(null)
    const [summeZurZahlungVorgesehem ,setSummeZurZahlungVorgesehem ] = useState(null)
    const [anzahlIbanFehler, setAnzahlIbanFehler] = useState(null)
    const [anzahlAspFehler, setAnzahlAspFehler] = useState(null)
    const [anzahlZuStornieren, setAnzahlZuStornieren] = useState(null)
    const [anzahlStornierungAufheben, setAnzahlStornierungAufheben] = useState(null)
    const [anzahlZahlungen, setAnzahlZahlungen] = useState(null)
    const [summeZahlungen, setSummeZahlungen] = useState(null)
    const [rechnungsDatum, setRechnungsDatum] = useState(null)


    useEffect(() => {
        async function asyncFetchZahlungsJahrStat()
        {                           
            try {
                const url = '/data/zahlungslaeufe/stat/0'
                const response = await fetch(url, { method: 'GET' })
                if (response.ok) {                                               
                    const result = await response.json();
                    // console.log(result)
                    
                    StorageService.setZahlungsLaufJahr(result.zahlungsLaufJahr)
                    setZahlungsLaufJahr(result.zahlungsLaufJahr)
                    
                    StorageService.setZahlungsLaufNumber(result.zahlungsLaufNumber)
                    setZahlungsLaufNumber(result.zahlungsLaufNumber)

                    setOffeneBestaetigungen(result.offeneBestaetigungen)
                    setNachweisbareMassnahmen(result.nachweisbareMassnahmen)

                    StorageService.setNotPrintedErrorCount(result.nachweisNichtGedruckt)
                    setNachweisNichtGedruckt(result.nachweisNichtGedruckt)

                    setAnzahlZurZahlungVorgesehen(result.anzahlZurZahlungVorgesehen)
                    setSummeZurZahlungVorgesehem(result.summeZurZahlungVorgesehem)
                    
                    StorageService.setIbanErrorCount(result.anzahlIbanFehler)
                    setAnzahlIbanFehler(result.anzahlIbanFehler)

                    StorageService.setAspErrorCount(result.anzahlAspFehler)
                    setAnzahlAspFehler(result.anzahlAspFehler)
                    
                    StorageService.setStornoCount(result.anzahlZuStornieren)
                    setAnzahlZuStornieren(result.anzahlZuStornieren)
                    setAnzahlStornierungAufheben(result.anzahlStornierungAufheben)
                    setAnzahlZahlungen(result.anzahlZahlungen)
                    setSummeZahlungen (result.summeZahlungen)
                    setRechnungsDatum(result.rechnungsDatum)
                    return;
                }
                else 
                {
                    throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                console.log(err)                    
                setErrorText(err.toString())                            
            }                
        }    
        StorageService.resetZahlungsLaufdata()
        asyncFetchZahlungsJahrStat();
    } , []) 

    const nichtGedrucktExport = () => {        
        document.location = '/Massnahmen/Liste?id=' + MassnahmenListItems.NotPrintedKey    
    }

    const fehlerhafteIbanExport = () => {      
        document.location = '/Massnahmen/Liste?id=' + MassnahmenListItems.IbanKey        
    }

    const fehlenderAnsprechpartnerExport = () => {       
        document.location = '/Massnahmen/Liste?id=' + MassnahmenListItems.AspKey
    }

    const stornierenAction = () => {
        document.location = '/Massnahmen/Liste?id=' + MassnahmenListItems.StornoKey
    }

    const stornierenAufhebenAction = () => {        
        document.location = '/Massnahmen/Liste?id=' + MassnahmenListItems.StornoKeyUndo
    }

    const ctrlListNext = async () => {        
        if (ZConfig.KontrollListenExportPage) 
        {
            document.location = '/Zahlungslauf/Aktuell/KontrollListenNachweis'
        }
        else 
        {
            ExportService.exportKontrollListen(9, zahlungsLaufNumber, zahlungsLaufJahr)
        }
    }

    const ctrlListReady = async () => {
        if (ZConfig.KontrollListenExportPage) 
        {
            document.location = '/Zahlungslauf/Aktuell/KontrollListenRechnung'
        }
        else 
        {
            ExportService.exportKontrollListenAbgerechnet(19, zahlungsLaufNumber, zahlungsLaufJahr)
        }
    }

    const rechnungsDaten = async () => {      
        if (ZConfig.RechnungsDatenExportPage) {
            document.location = '/Zahlungslauf/Aktuell/RechnungsListe'            
        }
        else {
            
        }

        // const res = await FetchService.generateRechnungsDaten()            
        // if (res.status === 200 && res.data) {                
        //     const ds = new Date().toISOString().slice(0,10)
        //     const filename = ds + "-Kontrolliste-Zahlungslauf-" + zahlungsLaufNumber + "-" + zahlungsLaufJahr + ".xlsx"                     
        //     FileSaver.saveAs( res.data, filename);             
        // }
        // else {
        //     alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
        // }
    }

    // const rechnungsLauf = async () => {
    //     if (window.confirm('Soll dieser Zahlungslauf finalisiert werden?')) 
    //     {           
    //         const res = await FetchService.rechnungsLaufAbschliessen(rechnungsDatum)            
    //         if (res.status === 200 && res.data) {
    //             // const ds = new Date().toISOString().slice(0,10)
    //             // const filename = ds + "-Rechnungs-Zahlungslauf-" + zahlungsLaufNumber + "-" + zahlungsLaufJahr + ".xlsx"
    //             // FileSaver.saveAs( res.data, filename);             
    //         }
    //         else {
    //             alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
    //         }
    //     }
    //     else 
    //     {
    //       // Do nothing!          
    //     }
    // }

    // const auszahlungenEasyDat = () => {
    //     console.log("NOT implemented")
    // }

//     Private Sub ZahlungenFinalisieren(Optional ByVal condition As String = "")
//     Dim Marker As String
//     Dim rs As Recordset
    
//     If MsgBox("Soll dieser Zahlungslauf finalisiert werden?", vbYesNo, "Rückfrage") = vbYes Then
//         Marker = txtJahrZahlungslauf & ":" & aktZahlungslauf.value
//         CurrentDb().Execute "Update Maßnahmen SET status=12, verwendungszweckzahlung=verwendungszweck, ausgezahlt_am=#" & Format$(CDate(txtZahlungsdatum), "mm\/dd\/yyyy") & "#,betragAusgezahlt=betragbestätigt, auszahlvermerk = '" & Marker & "', letzte_aenderung=now(),letzter_bearbeiter='" & WindowsNutzerName & "' where status in (13,19)" & IIf(condition = "", "", "AND " & condition)
//         Set rs = CurrentDb().OpenRecordset("Select count(id),sum(betragausgezahlt) from maßnahmen where ausgezahlt_am=#" & Format$(CDate(txtZahlungsdatum), "mm\/dd\/yyyy") & "# AND status=12 AND auszahlvermerk='" & Marker & "'", dbOpenSnapshot)
//         rs.MoveFirst
//         CurrentDb().Execute "update ZahlungsLäufe set anzahlLauf" & aktZahlungslauf.value & "=" & rs(0) & ", SummeLauf" & aktZahlungslauf.value & "=" & Replace(rs(1), ",", ".") & ", zahlungslauf" & aktZahlungslauf.value & "=#" & Format$(CDate(txtZahlungsdatum), "mm\/dd\/yyyy") & "# where zahlungslauf=" & txtJahrZahlungslauf
//         rs.Close
//         aktZahlungslauf.value = CStr(Val(aktZahlungslauf.value) + 1)
//         ZahlStatus = 2
//         UpdateFields
//     End If

// End Sub

    const sepaAuszahlung = async () => {
        let msg = ""
        
        try {
            const res = await FetchService.generateSepaAuszahlung()
            // console.log(res)
            // console.log(res.status + " " + res.statusText)
            // console.log(res.error)
            if (res.status === 200 && res.data) {            
                if (Array.isArray(res.data))
                {
                    const r = res.data
                    for(let num=0; num < r.length; num++)
                    {  
                        // console.log(r[num].anschreibenFilename)     
                        // console.log(r[num].protoFilename)
                        // console.log(r[num].xmlFilename)
                        
                        
                        const anschreibenArray = new Uint8Array(r[num].anschreibenData.data.length)
                        for (let x = 0; x < anschreibenArray.length; x++)
                        {
                            anschreibenArray[x] = r[num].anschreibenData.data[x];
                        }
                        const anschreibenBlob = new Blob([anschreibenArray])
                        FileSaver.saveAs( anschreibenBlob, r[num].anschreibenFilename ); 

                        const protokollData =  new Uint8Array(r[num].protoData.data.length)
                        for (let x = 0; x < protokollData.length; x++)
                        {
                            protokollData[x] = r[num].protoData.data[x];
                        }
                        const protokollBlob = new Blob([protokollData])
                        FileSaver.saveAs( protokollBlob, r[num].protoFilename ); 

                        const xmlBlob = new Blob([r[num].xmlData], {type: "text/plain;charset=utf-8"});  
                        
                        FileSaver.saveAs(xmlBlob, r[num].xmlFilename)
                    }

                    if (window.confirm("Soll dieser Zahlungslauf abgeschlossen werden?"))
                    {
                        try 
                        {
                            const r2 = await FetchService.finalizeSepaAuszahlung(
                                StorageService.getZahlungsLaufJahr(),
                                StorageService.getZahlungsLaufNumber(),
                                Date.now()
                            )
                            // console.log(r2)
                            // console.log(r2.status + " " + r2.statusText)
                            // console.log(r2.error)
                            if (r2.status === 200 && r2.data) { 
                                msg = "OK"
                            }
                            else {
                                msg = r2.status + " " + r2.statusText
                            }                            
                        }
                        catch (err) 
                        {
                            msg = err;
                        }
                    }
                }
                else
                {
                    msg = res
                }
            }
            else 
            {
                if ("No Data" === res.data)
                    msg = "Keine Daten"
                else
                    msg = res.error // response.status + " " + response.statusText;
            }
        }
        catch(err) {
            msg = err;
        }
        if (msg) {
            alert(msg)
        }        
    }

    // const sepaVorauszahlung = () => {
    //     console.log("NOT implemented")
    // }

    return (            
        <div className="sm_table_div">
            <table className="sm_table_50">
                <thead>
                    <tr className="table-blank-row"></tr>         
                    <tr><th colSpan="2"><h3>Zahlungen Aktuell</h3></th></tr>
                    <tr><th colSpan="2"><h3>{errorText}</h3></th></tr>
                </thead>
                <tbody>
                    <tr className="table-blank-row"/>
                    <tr>
                        <td className="td_label">Zahlungslauf für das Jahr:</td>
                        <td className="td_label">{zahlungsLaufJahr}</td>
                            {/* <FormGroup
                                type="text"
                                label=""
                                value={zahlungsLaufJahr}
                                readonly={true}
                            />
                        </td> */}
                        {/* <td className="td_select">
                            <FormGroup 
                                type="select"
                                options={zahlungsJahre}
                                value={zahlungsJahr}
                                onChange={setZahlungsJahr}
                            />
                        </td>                         */}
                        <td></td>                        
                        <td className="td_label">Lauf:</td>                        
                        <td className="td_label">{zahlungsLaufNumber}</td>                        
                    </tr>

                    <tr className="table-blank-row"/>
                    <tr>
                        <td className="td_label">Noch nachweisbare Ma&szlig;nahmen:</td>
                        <td className="td_label">{offeneBestaetigungen}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="td_label">Offene Nachweis&auml;nderungen:</td>
                        <td className="td_label">{nachweisbareMassnahmen}</td>
                        <td></td>
                    </tr>                    
                    <tr>
                        <td className="td_label">Nachweis nicht gedruckt:</td>
                        <td className="td_label">{nachweisNichtGedruckt}</td>
                        <td></td>
                        <td></td>
                        <td className="td_button">
                            <button className="exportbtn" onClick={nichtGedrucktExport}>
                                <ConfigIcon><AiOutlineFileExcel/></ConfigIcon>&nbsp;Excel
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td className="td_label">F&uuml;r Zahlung vorgesehen:</td>
                        <td className="td_label">{anzahlZurZahlungVorgesehen}</td>
                        <td className="td_label">{
                        (summeZurZahlungVorgesehem ?? 0).toLocaleString("de-DE", {minimumFractionDigits:2, maximumFractionDigits : 2})
                        } €</td> 
                        <td></td>
                        <td className="td_button">
                        <button className="exportbtn"
                                onClick={ctrlListNext}
                            >
                                Kontrollisten (n&auml;chster Zahlungslauf)
                        </button>                  
                        </td>
                    </tr>
                    <tr>
                        <td className="td_label">Fehlende/Fehlerhafte IBAN:</td>
                        <td className="td_label">{anzahlIbanFehler}</td>
                        <td></td>
                        <td></td>
                        <td className="td_button">
                            <button className="exportbtn" onClick={fehlerhafteIbanExport}>
                                <ConfigIcon><AiOutlineFileExcel/></ConfigIcon>&nbsp;Excel
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td className="td_label">Fehlender Ansprechpartner:</td>
                        <td className="td_label">{anzahlAspFehler}</td>
                        <td></td>
                        <td></td>
                        <td className="td_button">
                            <button className="exportbtn" onClick={fehlenderAnsprechpartnerExport}>
                                <ConfigIcon><AiOutlineFileExcel/></ConfigIcon>&nbsp;Excel
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td className="td_label">Zu stornierende Ma&szlig;nahmen:</td>
                        <td className="td_label">{anzahlZuStornieren}</td>
                        <td></td>
                        <td></td>
                        <td className="td_button">
                            <button className="exportbtn" onClick={stornierenAction}>
                                <ConfigIcon><AiOutlineUndo/></ConfigIcon>&nbsp;Stornieren
                            </button>
                        </td>
                    </tr>  
                    <tr>
                        <td className="td_label">Stornierung aufheben:</td>
                        <td className="td_label">{anzahlStornierungAufheben}</td>
                        <td></td>
                        <td></td>
                        <td className="td_button">
                            <button className="exportbtn" onClick={stornierenAufhebenAction}>
                                <ConfigIcon><AiOutlineUndo/></ConfigIcon>&nbsp;Stornierung aufheben
                            </button>
                        </td>                    
                    </tr>                 

                    <tr className="table-blank-row"/>
                    <tr>
                        <td className="td_label">Rechnungsdatum:</td>
                        <td className="td_label">{toShortDateString(rechnungsDatum)}</td>
                    </tr>
                    <tr className="table-blank-row"></tr>
                    <tr>
                        <td colSpan="2" className="al">
                            <button className="exportbtn"
                                onClick={ctrlListNext}
                            >
                                Kontrollisten (n&auml;chster Zahlungslauf)
                            </button>
                        </td>                        
                        <td colSpan="2" className="al">
                            <button className="exportbtn" onClick={ctrlListReady}
                            >
                                Kontrollisten (Rechnung gestellt)
                            </button>
                        </td>                        
                    </tr>
                    <tr className="table-blank-row"></tr>
                    <tr>
                        <td colSpan="2" className="al">
                            <button className="exportbtn" onClick={rechnungsDaten}>Rechnungsdaten</button>
                        </td>
                        <td colSpan="2" className="al">
                            <button className="exportbtn" onClick={sepaAuszahlung}>
                                SEPA Auszahlungsdateien
                            </button>
                        </td>
                        {/* <td colSpan="2" className="td_label_left">
                            <button className="exportbtn" onClick={rechnungsLauf}>Rechnungslauf</button>
                        </td> */}
                    </tr>

                    <tr className="table-blank-row"/>
                    <tr>
                        <td className="td_label">Volumen Rechnung:</td>
                        <td className="td_label">0</td>
                        <td className="td_label">0 €</td>                        
                    </tr>
                    <tr>
                        <td className="td_label">Volumen Zahlung:</td>
                        <td className="td_label">{anzahlZahlungen}</td>
                        <td className="td_label">{(summeZahlungen ?? 0).toLocaleString() } €</td>                       
                    </tr>
                    <tr>
                        <td className="td_label">Zahlungsdatum:</td>                        
                        <td className="td_label"></td>
                    </tr>
                    <tr className="table-blank-row"/>
                    <tr>
                        <td className="td_label">
                            {/* <button className="exportbtn" onClick={auszahlungenEasyDat}>
                                Auszahlungen f&uuml;r EasyDAT
                            </button> */}
                        </td>
                        <td className="td_label">
                            {/* <button className="exportbtn" onClick={sepaAuszahlung}>
                                SEPA Auszahlungsdateien
                            </button> */}
                        </td>
                        <td className="td_button">
                            {/* <button className="exportbtn" onClick={sepaVorauszahlung}>
                                SEPA Vorauszahlung
                            </button> */}
                        </td>
                    </tr>                    
                </tbody>
            </table>         
        </div>
    )
}
export default ZahlungenAktuell
