

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './DragDropUploadForm.css'

const DragDropUploadForm = props => 
{
	const [filemessage, setFilemessage] = useState("")
	const [canupload, setcanupload ] = useState(false)

	const submitFile = ( async (evt) => {		
        evt.preventDefault()        
        let form = document.getElementById('uploadform')
        if (!form) {
            form = evt.currentTarget
        }
        const url = '/data/documents/upload'
		let message = ""
		let status = false
        
        try {
            const formData = new FormData(form)
            const response = await fetch(url, {
                method: 'POST',
                body: formData
            });
			if (response.ok && response.status === 200) {            	
				status = true
			}
			message = "" + await response.text()
			if (!message) {
				message = response.status + " " + response.statusText
			} else if (props.callback) {
				props.callback('upload', null)
			}
        }
        catch (error) {
            console.log(error);	
			message = "Fehler"		
        }
		setFilemessage(message)
		if (status) {
			document.getElementById('fileData').files = new DataTransfer().files;
			setcanupload(false)
		}
    })    

    function readfiles(files) {
        const dT = new DataTransfer();
        for (var i = 0; i < files.length; i++) 
        {
            dT.items.add(files[i])
        //   document.getElementById('fileDragName').value = files[i].name
        //   document.getElementById('fileDragSize').value = files[i].size
        //   document.getElementById('fileDragType').value = files[i].type
        //   const reader = new FileReader();
        //   reader.onload = function(event) {
        //     document.getElementById('fileDragData').value = event.target.result;
        //   }
        //   reader.readAsDataURL(files[i]);
        }
        document.getElementById('fileData').files = dT.files;
		setcanupload(dT.files.length > 0)
    }

    const dndDragOver = ((e) => {
        // console.log("dndDragOver")
        // console.log(e.currentTarget)
        e.currentTarget.className = 'hover'
        e.preventDefault();
        // console.log(e.currentTarget)
        return false;
    }) 
    
    const dndDragEnd = ((e) => { 
        console.log("dndDragEnd")
        // console.log(e.currentTarget)
        e.currentTarget.className = ''
        return false
    })
    
    const dndOnDrop = ((e) => {
        console.log("dndOnDrop")
        e.currentTarget.className = ''
        e.preventDefault();
        console.log(e.dataTransfer.files)   
        readfiles(e.dataTransfer.files);   
        if (e.dataTransfer.files.length === 1) {
            setFilemessage ( e.dataTransfer.files[0].name )
        } else {
            setFilemessage ( e.dataTransfer.files.length + " Dateien ausgewählt")
        }
    })

  return (
	<form id='uploadform' onSubmit={submitFile} >	
		<input name="files" id="fileData" type="file" multiple hidden/>
		<input name='mid' type='hidden' value={props.mid}/>
		<input name='eid' type='hidden' value={props.eid}/>
		<input name='tid' type='hidden' value={props.tid}/>
		<input name='aid' type='hidden' value={props.aid}/>
		<div id='holder' 
			onDragOver={dndDragOver}
			onDragEnd={dndDragEnd}
			onDrop ={dndOnDrop} 
		>                                    
			<div className='sm_table_div'>
			<table className='sm_table'>
				<thead>
					<tr>
						<th className='td_center'>{props.title ?? 'Dokumente' }</th>
					</tr>
				</thead>
				<tbody>			
					<tr>
						<td className='td_center'>Drop HERE</td>
					</tr>
					<tr>
						<td className='td_center'>
							<svg xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43"><path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z" /></svg>
						</td>
					</tr>
					<tr>
						<td className='td_center'>
							<button
								className='exportbtn'
								onClick={submitFile}
								disabled={!canupload}
							>
								Hochladen
							</button>                                                    
						</td>
					</tr>
					<tr>
						<td className="td_center" align='center'>{filemessage}</td>
					</tr>
				</tbody>                                    
			</table>
			</div>
		</div>
	</form>    
  )
}

DragDropUploadForm.propTypes = {
	callback : PropTypes.func,	
	mid : PropTypes.number,
	eid : PropTypes.number,
	tid : PropTypes.number,
	aid : PropTypes.number,
	title : PropTypes.string
}

export default DragDropUploadForm


