import FileSaver from 'file-saver';
import FetchService from './FetchService';

export default class ExportService 
{
    static async exportKontrollListen(type, zahlungsLaufNumber, zahlungsLaufJahr)  
    {        
        const res = await FetchService.generateKontrollListen(type)            
        if (res.status === 200 && res.data) {                
            const ds = new Date().toISOString().slice(0,10)
            const filename = ds + "-Kontrolliste-Zahlungslauf-" + (type === 9 ? '' : 'Abgerechnet-') + zahlungsLaufNumber + "-" + zahlungsLaufJahr + ".xlsx"                     
            FileSaver.saveAs( res.data, filename);             
        }
        else 
        {
            alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
        }
    }

    static async exportIbanError(fileName ) {
        ExportService.exportToExcel(fileName, 'data/export/excel/ibanerror/' )
    }

    static async exportAspError(fileName) {
        ExportService.exportToExcel(fileName, 'data/export/excel/asperror/' )
    }

    static async exportNotPrintedError(fileName) {
        ExportService.exportToExcel(fileName, 'data/export/excel/notprinted/' )
    }

    static async exportToExcel(fileName, url) 
    {
        const res = await FetchService.generateXls(url)
        if (res.status === 200 && res.data) 
        {                
            const ds = new Date().toISOString().slice(0,10)
            const fName = ds + "-" + fileName + ".xlsx"
            FileSaver.saveAs( res.data, fName);             
        }
        else 
        {
            alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
        }
        console.log(res)
        // Format$(Now(), "yyyy-mm-dd-") & fileName & ".csv"
    }

    // static exportKontrollListenAbgerechnet(zahlungsLaufNumber, zahlungsLaufJahr) 
    // {
    //     const res = await FetchService.generateKontrollListen(19)            
    //     if (res.status === 200 && res.data) {                
    //         const ds = new Date().toISOString().slice(0,10)
    //         const filename = ds + "-Kontrolliste-Zahlungslauf-Abgerechnet-" + zahlungsLaufNumber + "-" + zahlungsLaufJahr + ".xlsx"
    //         FileSaver.saveAs( res.data, filename);             
    //     }
    //     else {
    //         alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
    //     }
    // }    
}
