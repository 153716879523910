import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import ColumnSorter from '../../util/ColumnSorter'
import SortableTableHeader from '../../components/shared/tables/SortableTableHeader'
// import SortableTableData from '../../components/shared/tables/SortableTableData'
import FormGroup from '../../components/shared/form-group/FormGroup'
import Spinner from '../../components/shared/spinner/Spinner';
import { CHECKBOX_STATES } from '../../components/shared/editor-controls'
import { Navlink } from '../../components/shared/navlink';
// import { Navlink } from 'guicommon';

const EditAnsprechpartnerListe = props => 
{
    const [list, setlist] = useState(null)
    const [listlength, setlistlength] = useState(0)

    const [columnsSorter, setColumnSorter] = useState(null)    
    const [sortOrder , setSortOrder] = useState(false)
    const [lastSort , setLastSort] = useState(null)

    const [optionnachname, setoptionnachname] = useState(CHECKBOX_STATES.Indeterminate)
    const [optionmassnahmen,setoptionmassnahmen] = useState(CHECKBOX_STATES.Indeterminate)
    const [optionduplicatemail, setoptionduplicatemail] = useState(false)
    const [reload, setreload] = useState(true)
    const [loadstate, setLoadState] = useState(1)
    const [message, setMessage] = useState(null)

    useEffect(() => {
        async function load()
        {
            try 
            {
                _setlist(null)
                const data = {
                    nachname : optionnachname,
                    massnahmen : optionmassnahmen,
                    duplicatemail : optionduplicatemail
                }
                setLoadState(2)
                const response = await fetch(
                    '/data/ansprechpartner',
                    {
                        headers: {                    
                            'Content-Type': 'application/json'
                        },
                        method: 'POST',
                        body: JSON.stringify(data),
                    }
                );
                if (response.ok) {
                    const result = await response.json();                        
                    if (Array.isArray(result))
                    {      
                        // console.log(result[0])                  
                        _setlist(result)                                                 
                        setColumnSorter(new ColumnSorter(result, 
                            [
                                [ 'ID' , 'id'],
                                [ 'Vorname' , 'vorname'],
                                [ 'Nachname', 'nachname'],
                                [ 'E-Mail', 'email'],
                                [ 'EID' , 'einrichtungen_id']
                            ]
                        ))      
                        setSortOrder(false)                    
                        setLastSort(null)
                        setLoadState(0)
                        return;
                    }
                    // console.log("E:" + result.name)
                    throw new Error(result.name)                                    
                }
                else
                {
                    // console.log("E:" + response.status)
                    throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                setMessage("" + err)
                // console.log(err)
            }
            setLoadState(1)
        }
        load()      
    }, [reload])

    const columnclick = (evt) => {
        if (columnsSorter) {
            const res = columnsSorter.sortColumn(evt)
            if (res) {                
                setlist(columnsSorter.rdata)
                setSortOrder(columnsSorter.sortOrder)
                setLastSort(columnsSorter.lastSort)
            }
        }
    }

    const reloaddata = (evt) => {        
        setreload( !reload)
    }

    const _setoptionnachname = (evt) => {        
        setoptionnachname(evt)
    }

    const _setoptionmassnahmen = (evt) => {        
        setoptionmassnahmen(evt)
    }

    const _setlist = (lst) => {
        setlist(lst)
        setlistlength( (lst ? lst.length : 0))
    } 

  return (
    <div>
        <div className='sm_table_div'>
        <table className='sm_table_60'>
            <tbody>
                <tr>                    
                    <td width='180px'>
                        <FormGroup
                            type='checkbox3'
                            name='nachname'
                            value={optionnachname}
                            label={
                                (optionnachname === CHECKBOX_STATES.Empty ? ` Ohne ` : 
                                 optionnachname === CHECKBOX_STATES.Checked ? ' Mit ' : ' ') + 'Nachname'}
                            onChange={_setoptionnachname}
                        />
                    </td>
                    <td width='180px'>
                        <FormGroup
                            type='checkbox3'
                            name='massnahmen'
                            value={optionmassnahmen}                            
                            label={ 
                                (optionmassnahmen === CHECKBOX_STATES.Empty ? ` Ohne ` : 
                                 optionmassnahmen === CHECKBOX_STATES.Checked ? ' Mit ' : ' ') + 'Maßnahmen'}
                            onChange={_setoptionmassnahmen}
                        />
                    </td>
                    <td width='180px'>
                        <FormGroup
                            type='checkbox'
                            name='email'
                            value={optionduplicatemail}
                            label='&nbsp;E-Mail Duplikate'
                            onChange={setoptionduplicatemail}
                        />
                    </td>
                    <td><button onClick={reloaddata}>Lesen</button></td>
                    <td>Datensätze:</td>
                    <td>{listlength}</td>
                </tr>
                <tr className='table-blank-row'></tr>
            </tbody>
        </table>
        </div>
        {loadstate === 0 && <div className='table-fixed-header' style={{height:'800px'}}> 
        <table className='sm_table'>
            <thead>
                <tr>                    
                    <SortableTableHeader className={'td_label'} onClick={columnclick} title='ID' lastSort={lastSort} sortOrder={sortOrder} />
                    <SortableTableHeader className={'td_label'} onClick={columnclick} title='Vorname' lastSort={lastSort} sortOrder={sortOrder} />
                    <SortableTableHeader className={'td_label'} onClick={columnclick} title='Nachname' lastSort={lastSort} sortOrder={sortOrder} />
                    <SortableTableHeader className={'td_label'} onClick={columnclick} title='E-Mail' lastSort={lastSort} sortOrder={sortOrder} />
                    <SortableTableHeader className={'td_label'} onClick={columnclick} title='EID' lastSort={lastSort} sortOrder={sortOrder} />          
                </tr>
            </thead>
            <tbody>
                { list && list.length>0 && list.map( ({id, vorname, nachname, email, einrichtungen_id }) => {
                    return (
                        <tr className='liste' key={id}>
                            <td className='td_fitwidth'>
                                <Navlink link={`Ansprechpartner/Edit?eid=${einrichtungen_id}&aspId=${id}`} title={""+id} />
                                {/* <a href={`Ansprechpartner/Edit?eid=${einrichtungen_id}&aspId=${id}`}>{id}</a> */}
                            </td>
                            <td className='td_fitwidth'>
                                {vorname}
                            </td>
                            <td className='td_fitwidth'>
                                {nachname}
                            </td>
                            <td className='td_fitwidth'>
                                {email}
                            </td>
                            <td className='td_fitwidth'>
                                <Navlink link={`Einrichtung/Edit?eid=${einrichtungen_id}`} title={""+einrichtungen_id} />
                                {/* <a href={`Einrichtung/Edit?eid=${einrichtungen_id}`}>{einrichtungen_id}</a> */}
                            </td>                            
                        </tr>
                    )
                })
            }   
            </tbody>
        </table>
        </div>}
        { loadstate===1 && <>{message}</>}           
        { loadstate===2 && <Spinner /> }       
        
    </div>
  )
}

EditAnsprechpartnerListe.propTypes = {}

export default EditAnsprechpartnerListe