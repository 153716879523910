import React from 'react';

const TextControl = ({ type, name, value, className, readonly, disabled, hidden, onChange, autocomplete, placeholder }) => {     
    const _value = value === null || value === undefined ? '' : value;

    // if (autocomplete) {
    //     console.log("AUTOComplete " + autocomplete)
    // }


    return (
        <input 
            type={type} //"text"
            // placeholder={placeholder || ""}
            autoComplete={(autocomplete === '1' ? 'on' : '0')}
            className={className || 'form-control'} 
            name={name} 
            value={_value}   
            readOnly={readonly}
            disabled={disabled}
            hidden={hidden}
            onChange={(e) => onChange(e.target.value)} /> 
    )
}

export default TextControl;