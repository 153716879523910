import { LOCALES } from '../locales';

const de_bundle = {
  [LOCALES.GERMAN] : 
  { 
    hello: 'Hallo Message',       
    
    loginPage_loginText: 'Bitte anmelden',
    loginPage_username: 'Benutzername',
    loginPage_password: 'Kennwort',
    loginPage_login: 'Anmelden',
    loginPage_loggining: 'Anmelden...',
    loginPage_userNameLabel: 'Benutzername',
    loginPage_passwordLabel: 'Kennwort',
    loginPage_oldpasswordLabel : 'Altes Kennwort',
    loginPage_newpasswordlabel1: 'Neues Kennwort',
    loginPage_newpasswordlabel2: 'Kennwort Wiederholung',
    loginPage_messages_invalidUsernameOrPassword: 'Benutzername oder Kennwort falsch' ,
    loginPage_messages_unknownUser: 'Unbekannter Benutzer' ,
    loginPage_messages_wrongPassword: 'Falsches Passwort', 
    loginPage_messages_loggedIn: 'Erfolgreich authentifiziert', 
    loginPage_messages_passwords_differ: 'Die neuen Passwörter unterscheiden sich.',
    loginPage_messages_password_rules: 'Das neue Passwort verstößt gegen die Passwortregeln',
    loginPage_messages_error : 'Unbekannter Fehler',
    loginPage_messages_notImplemented: 'Not implemented',
    
    loginPage_messages_400: '400 Bad Request',
    loginPage_messages_401: '401 Unauthorized', 
    loginPage_messages_404: '404 Not Found',
    loginPage_messages_500: '500 Internal Server Error',
    
  }
}

export default de_bundle;