
// https://material-table.com/#/docs/all-props


export function materialTableOptions() {
    const options = 
    {
        // tableLayout: "fixed",
        thirdSortClick : false,
        // exportButton : true,
        exportDelimiter : ';',
        pageSize : 30,
        pageSizeOptions : [10,30,50],   
    }
    return options
}

export function materialTableLocalization() {
    const localization = 
    {
        toolbar : {
            searchTooltip : 'Suche',
            searchPlaceholder : 'Suche',
            exportName : 'Export als CSV',
        },
        body : { 
            emptyDataSourceMessage : ''
        },
        pagination : {
            firstAriaLabel : 'Erste Seite',
            firstTooltip : 'Erste Seite',
            previousAriaLabel : 'Vorherige Seite',	
            previousTooltip: 'Vorherige Seite',
            nextAriaLabel : 'Nächste Seite',
            nextTooltip : 'Nächste Seite',
            lastAriaLabel : 'Letzte Seite',
            lastTooltip : 'Letzte Seite',
            labelDisplayedRows : '{from}-{to} von {count}',
            labelRowsPerPage : 'Datensätze pro Seite',
            labelRowsSelect:'Datensätze',
        }
    }
    return localization
}