import { useState, useEffect } from 'react';
import FileSaver from 'file-saver';
import { AiOutlineEdit, AiOutlineFileWord, AiOutlineMail, AiOutlineDelete } from 'react-icons/ai';
import { HiOutlineDocumentAdd } from 'react-icons/hi';


import ConfigIcon from '../../util/configicon';
import FetchService from '../../services/FetchService';
import { authenticationService } from '../../services';
import { PO } from '../edit-massnahme/PO';
import NonModal from '../../components/shared/nonmodal/NonModal';
import ColumnSorter from '../../util/ColumnSorter';
import SortableTableHeader from '../../components/shared/tables/SortableTableHeader';

const formatAnsprechpartner = (vorname, nachname, titel, anreden) => {
    return (
        (anreden && anreden.bezeichnung ? anreden.bezeichnung + " " : "") + 
        // (titel ? titel + " " : "") + 
        // (vorname ? vorname + " " :"") + 
        (nachname ? nachname : "")
    )
}

const po = new PO("AnsprechpartnerListPage")

function AnsprechpartnerListPage(props) {  
    
    const [showprintdialog, setshowprintdialog] = useState(false)
    const nonmodalid = 'NonModal_ASPList';
    po.initComponent(this, setshowprintdialog)


    function showPrintDialog()
    {  
        // console.log("showprintdialog " + po.printmessage)      
        if (po && po.printid && po.printmessage) 
        {
            const element = document.getElementById(nonmodalid)
            if (element) {
                element.style.display = 'block';                                    
            }        
        }
    }

    function documentprint_callback(cmd, param) { po.documentprint_callback(cmd, param)}

    useEffect(() => {
        async function func()
        { 
            if (showprintdialog) { showPrintDialog() }
        }
        // console.log("useeffect showprintdialog " + showprintdialog)    
        func()
    }, [showprintdialog]);   
    
    const [columnsorter, setcolumnsorter] = useState(null)    
    const [list, setlist] = useState(null)
    const [sortOrder , setSortOrder] = useState(false)
    const [lastSort , setLastSort] = useState(null)
    const [selectedAspIndex, setSelectedAspIndex] = useState(0)

    function initColumnSorter()
    {   
        if (props.isLoaded === true && props.liste && props.liste.length > 0) 
        {     
            setcolumnsorter( new ColumnSorter(props.liste, 
                [
                    [ 'Anrede' , 'nachname'],
                    [ 'Telefon' , 'telefon'],
                    [ 'eMail', 'email'],                      
                ]
            ))
            setSortOrder(false)                    
            setLastSort(null)
        }
    }

    useEffect(() => {
        async function load()
        {
            // console.log("ASP is loaded " + props.isLoaded)
            if (props.isLoaded === true && props.liste && props.liste.length > 0) 
            {
                setlist(props.liste)
                initColumnSorter()
                return;
            }
            else 
            {
                setlist(null)
                setcolumnsorter(null)
            }
        }
        load()
            
    }, [props.isLoaded])
    
    
    const [canEdit] = useState(authenticationService.currentUser.category > 0)
    
    const aspBearbeiten = (evt) => {                
        let aspId = evt.currentTarget.getAttribute('aspid');
        if (!aspId) 
        {    
            aspId = selectedAspIndex                    
        }
        if (aspId)
        {
            console.log("aspIndex " + selectedAspIndex)
            window.location.href="/Ansprechpartner/Edit?eid=" + props.eid + "&aspId=" + aspId
        }
    }

    const aspBrief = async (evt) => {        
        if (props.eid) {                  
            let aspId = evt.currentTarget.getAttribute('aspid'); 
            if (!aspId)
            {
                aspId = selectedAspIndex      
            }    
            if (aspId)
            { 
                const res = await FetchService.generateEinrichtungsBrief(props.eid, aspId)            
                if (res.status === 200 && res.data) {             
                    const filename = 'Brief_EID_' + props.eid + "_ASP_" + aspId + '.docx'
                    const pd = po.createPrintData(filename, res.data.printid , null)
                    FileSaver.saveAs( res.data.document, pd.filename );                    
                }
                else {
                    alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
                }
            }
        }
    }

    const aspMail = async (evt) => {
        if (props.eid) {            
            let aspId = evt.currentTarget.getAttribute('aspid');
            if (!aspId)
            {
                aspId = selectedAspIndex
            }
            if (aspId)
            {
                const res = await FetchService.generateEinrichtungsMail(props.eid, aspId)
                if (res.status === 200 && res.data) {  
                    // console.log(res.data)                         
                    window.location.href = res.data
                }
                else {
                    alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
                }
            }
        }
    }

    const aspDelete = (evt) => {
        if (props.eid) {            
            const aspid = Number.parseInt(evt.currentTarget.getAttribute('aspid'));
            if (props.callback && !Number.isNaN(aspid)) {
                props.callback('delete', aspid)
            }            
        }
    }
    
    const aspNeu = (evt) => {        
        window.location.href="/Ansprechpartner/New?eid=" + props.eid
    }

    const columnclick = (evt) => {        
        if (!columnsorter) 
        {
            console.log("columnclick sorter not found")
            initColumnSorter()        
        }
        if (columnsorter)
        {
            console.log("columnclick columnsorter found")
            const res = columnsorter.sortColumn(evt)
            if (res) {                
                setlist(columnsorter.rdata)
                setSortOrder(columnsorter.sortOrder)
                setLastSort(columnsorter.lastSort)
            }
        }        
    }

    const aspRowClick = ((evt) => {    
        const idattribute = evt.target.parentElement.attributes['id']
        if (idattribute) {
            const val = idattribute.value
            setSelectedAspIndex(Number.parseInt(val))
        }
    })

    return (       
        <div className="sm_table_detail_div">
            <table className="sm_table_detail" >
                <thead>
                    <tr style={{textAlign:'left'}}>
                        <th colSpan={1} className="th_fitwidth">Ansprechpartner</th>
                        <th colSpan={3}>
                            {props.eid && <>
                                <button className="exportbtn" title="Neues Konto" onClick={aspNeu} disabled={!canEdit}>
                                    <ConfigIcon><HiOutlineDocumentAdd/></ConfigIcon>
                                    &nbsp;Neu
                                </button>
                                &nbsp;
                                <button className="exportbtn" title="Bearbeiten" onClick={aspBearbeiten} disabled={!canEdit || selectedAspIndex === 0}>
                                <ConfigIcon><AiOutlineEdit/></ConfigIcon>
                                &nbsp;Bearbeiten                                        
                                </button>
                                &nbsp;
                                <button className="exportbtn" title="Brief" onClick={aspBrief} disabled={!canEdit || selectedAspIndex === 0}>
                                    <ConfigIcon><AiOutlineFileWord/></ConfigIcon>
                                    &nbsp;Brief                                        
                                </button>
                                &nbsp;
                                <button className="exportbtn" title="E-Mail" onClick={aspMail} disabled={!canEdit || selectedAspIndex === 0}>
                                    <ConfigIcon><AiOutlineMail/></ConfigIcon>
                                    &nbsp;E-Mail                                        
                                </button>                                
                            </>}
                        </th>
                    </tr>
                    <tr style={{textAlign:'left'}}>                        
                        <SortableTableHeader className='th_fitwidth w20' onClick={columnclick} title='Anrede' lastSort={lastSort} sortOrder={sortOrder} />                        
                        <SortableTableHeader className='th_fitwidth w15' onClick={columnclick} title='Telefon' lastSort={lastSort} sortOrder={sortOrder} />                        
                        <SortableTableHeader className='th_fitwidth w35' onClick={columnclick} title='eMail' lastSort={lastSort} sortOrder={sortOrder} />
                        { authenticationService.isAdmin && <>
                            <th className="th_fitwidth">Aktion                            
                            </th>
                        </>}
                    </tr>
                </thead>
                <tbody>
                    {
                        props.isLoaded === true && list && list.length > 0 && 
                            list.map( ({ id, vorname, nachname, titel, telefon, email , Anreden}) => {                                        
                            return (
                            <tr id={id} key={id} onClick={aspRowClick} style={ (id===selectedAspIndex) ? {backgroundColor:'#DDD'} : {} }>                          
                                <td className="td_fitwidth td_border">{formatAnsprechpartner(vorname, nachname, titel, Anreden)}</td>
                                <td className="td_fitwidth td_border">{telefon}</td>
                                <td className="td_fitwidth td_border">{email}</td>
                                { authenticationService.isAdmin && <>
                                    <td className='td_button'>
                                    &nbsp;                                   
                                    <button className="exportbtn adminbtn" title="Delete" aspid={id} onClick={aspDelete}>
                                        <ConfigIcon><AiOutlineDelete/></ConfigIcon>
                                        &nbsp;Löschen                             
                                    </button>
                                </td>
                                </>}
                            </tr>
                            )
                        })
                    }    
                    {
                        props.isLoaded === false &&
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    }
                </tbody>
            </table>   
            <div>
                <NonModal id={nonmodalid} type='yesno' title='Druckbestätigung' message={po.printmessage} callback={documentprint_callback} data={po.printid}></NonModal>
            </div>         
        </div>
    )
}

export default AnsprechpartnerListPage
