import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FormGroup from '../../components/shared/form-group'


const EtikettenPreview = props => 
{
    const [errormessage, seterrormessage] = useState(null)
    const [etilist, setetilist] = useState(null)
    const [landscape, setlandscape] = useState(false)
    const [hoch, sethoch] = useState(11)
    const [breit, setbreit] = useState(21)    
    const [margin, setmargin] = useState(31)  
    const [printed, setprinted] = useState(false)  

    const [hoehenlist, sethoehenlist] = useState(null)
    const [breitelist, setbreitelist] = useState(null)
    const [borderlist, setborderlist] = useState(null)

    
    useEffect(() => {
        async function loadEtitketten()
        {           
            try {                                
                const response = await fetch('/data/etiketten/')
                if (response.ok) {
                    const result = await response.json();    
                    if (Array.isArray(result))
                    {
                        // console.log(result)
                        if (result.length > 0) {
                            setetilist(result)                        
                        } else {
                            seterrormessage("Keine Etiketten zum Druck vorgesehen.")
                        }                        
                        return;
                    }
                    seterrormessage(result)                    
                }
                else
                {
                    seterrormessage(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                seterrormessage("" + err)                
                console.log(err)
            }
        }        
        loadEtitketten()      
    }, [])

    useEffect (() => {
        async function loadhoehelist() {
            try {                                
                const response = await fetch('/data/etiketten/params')
                if (response.ok) {
                    const result = await response.json();    
                    if (Array.isArray(result))
                    {
                        // console.log(result)
                        if (result.length > 0) {
                            const H = result.filter( (item) => { return item.gruppe === 'hoehe' })
                            const B = result.filter( (item) => { return item.gruppe === 'breite' })
                            const M = result.filter( (item) => { return item.gruppe === 'pageborder' })                           
                            sethoehenlist(H)  
                            setbreitelist(B)
                            setborderlist(M)                      
                        } else {
                            seterrormessage("Keine Etiketten zum Druck vorgesehen.")
                        }                        
                        return;
                    }
                    seterrormessage(result)                    
                }
                else
                {
                    seterrormessage(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                seterrormessage("" + err)                
                console.log(err)
            }
        }
        loadhoehelist()
    }, [])
    
    // const printpreviewClick = ( () => {
    //     const target = window.location.protocol + '//' + window.location.hostname + ':8000/data/etiketten/print' + window.location.hash
    //     window.open(target,'Etiketten Print');          
    // })

    const CLOSE_BUTTON_TEXT = 'Schließen'

    const etikettenprinted = ( async (e) => {
        e.preventDefault()  
        console.log("etikettenprinted") 
        const ids = []        
        if (etilist && etilist.length > 0) {           
            etilist.forEach(element => {
                ids.push(element.id)                
            });            
        }
        if (ids.length > 0) 
        {
            if (!window.confirm('Status "Etikettbenötigt" zurücksetzen?'))
            {
                return;
            }
            try {
                const response = await fetch("/data/etiketten/printed", {
                    headers: {                    
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify(ids),
                });
                if ((response.ok) && (response.status === 200)) 
                {    
                    setetilist([])                                                      
                } else {
                    seterrormessage(response.status + " " + response.statusText)
                }
            }
            catch(err) {
                seterrormessage(""+err)
            }
        }
    })

    const sendform = ( (e) => {
        e.preventDefault() 
        setprinted(true)
        document.getElementById('printform').submit();
    })    

    const closeform = ( (e) => {
        e.preventDefault()
        if (printed && etilist && etilist.length > 0) {
            if (!window.confirm("Der Etikettendruck wurde noch nicht bestätigt.\nTrotzdem Schließen?")) {
                return 
            }
        }
        window.close()
        return;
    })

    const formaction = ( () => {           
        const target = window.location.protocol + '//' + window.location.host + '/data/etiketten/print' + window.location.hash
        return target
    })

    const lschangedfalse = ( () => { setlandscape(false) })
    const lschangedtrue = ( () => { setlandscape(true) })

    const hchanged = ((value) => {    
        sethoch(Number.parseInt(value))
    })

    const mchanged = ((value) => {
        setmargin(Number.parseInt(value))
    })

    const brchanged = ((value) => {
        setbreit(Number.parseInt(value))
    })

    return (
    <div>
        { errormessage && <div align="center">
            <h3>&nbsp;</h3>
            <h3>{errormessage}</h3>
            <h3>&nbsp;</h3>
            <button                                                                 
                className='exportbtn'
                onClick={closeform}                                                                                
            >{CLOSE_BUTTON_TEXT}
            </button>
        </div>
        }
        { etilist && <div className='sm_table_div'>
            <table className='sm_table_80'>
                <tbody>
                    <tr>
                        <td>
                            <table className='sm_table'>
                                <thead>
                                    <tr>
                                        <th colSpan={5}><h2>Etiketten</h2></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='table-blank-row'></tr>
                                    <tr>
                                        <td>
                                            <form 
                                                id='printform'
                                                method='post' 
                                                action={formaction()}
                                                target='Etiketten Print'
                                            >
                                                <div className='sm_table_div'>
                                                <table className='sm_table_70'>                                                    
                                                    <tbody>
                                                        <tr>
                                                            <td valign='top'>
                                                                <div className='sm_table_div'>
                                                                <table className='sm_table'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th width='240px' colSpan={2}>Etiketthöhe</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {    
                                                                    hoehenlist && hoehenlist.length > 0 && hoehenlist.map( ({ id, feldname, wert}) => {                                        
                                                                        return (
                                                                            <tr key={id}>
                                                                                <td className='td_label'>{feldname} ({wert}mm)</td>
                                                                                <td className='td_label'>
                                                                                    <FormGroup
                                                                                        type="radio"                                                    
                                                                                        className=""
                                                                                        onChange={hchanged}
                                                                                        value={id}
                                                                                        checked={hoch===id}
                                                                                        name="hoch"
                                                                                    />
                                                                                </td>                    
                                                                            </tr>
                                                                            )
                                                                        })
                                                                    }                                                                        
                                                                    </tbody>
                                                                </table>
                                                                </div>
                                                            </td>

                                                            <td valign='top'>
                                                                <div className='sm_table_div'>
                                                                <table className='sm_table'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan={2}>Etikettbreite</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    { 
                                                                    breitelist && breitelist.length > 0 && breitelist.map( ({ id, feldname, wert}) => {                                        
                                                                        return (
                                                                            <tr key={id}>
                                                                                <td className='td_label'>{feldname} ({wert}mm)</td>
                                                                                <td className='td_label'>
                                                                                    <FormGroup
                                                                                        type="radio"                                                    
                                                                                        className=""
                                                                                        onChange={brchanged}
                                                                                        value={id}
                                                                                        checked={breit===id}
                                                                                        name="breit"
                                                                                    />
                                                                                </td>                    
                                                                            </tr>
                                                                                )
                                                                        })
                                                                    }                                                                  
                                                                    </tbody>
                                                                </table>
                                                                </div>
                                                            </td>

                                                            <td valign='top'>
                                                                <div className='sm_table_div'>
                                                                <table className='sm_table'>
                                                                    <thead>
                                                                        <tr>                                                                            
                                                                            <th colSpan={2}>Seitenränder</th>                                                            
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    { 
                                                                    borderlist && borderlist.length > 0 && borderlist.map( ({ id, feldname, wert}) => {                                        
                                                                        return (
                                                                            <tr key={id}>
                                                                                <td className='td_label'>{feldname} ({wert}mm)</td>
                                                                                <td className='td_label'>
                                                                                    <FormGroup
                                                                                        type="radio"                                                    
                                                                                        className=""
                                                                                        onChange={mchanged}
                                                                                        value={id}
                                                                                        checked={margin===id}
                                                                                        name="margin"
                                                                                    />
                                                                                </td>                    
                                                                            </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                                </div>
                                                            </td>

                                                            <td valign='top'>
                                                                <div className='sm_table_div'>
                                                                <table className='sm_table'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan={2}>Seitenformat</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className='td_label'>Hochformat</td>
                                                                            <td className='td_label'>
                                                                                <FormGroup
                                                                                    type="radio"                                                    
                                                                                    className=""
                                                                                    onChange={lschangedfalse}
                                                                                    value={landscape}
                                                                                    checked={!landscape}
                                                                                    name="landscape"
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='td_label'>Querformat</td>
                                                                            <td className='td_label'>
                                                                                <FormGroup
                                                                                    type="radio"                                                                    
                                                                                    onChange={lschangedtrue}
                                                                                    value={landscape}
                                                                                    checked={landscape}
                                                                                    name="landscape"
                                                                                />
                                                                            </td>                                         
                                                                        </tr>
                                                                        <tr>                                                                            
                                                                        </tr>
                                                                        <tr>                                                                            
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                </div>
                                                            </td>
                                                            <td valign='top'>
                                                                <div className='sm_table_div'>
                                                                <table className='sm_table'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className='td_button'>
                                                                                <button 
                                                                                    className='exportbtn' 
                                                                                    onClick={sendform}                                                                                
                                                                                    disabled={etilist.length === 0}
                                                                                >Druckvorschau
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='td_button'>
                                                                                <button                                                                 
                                                                                    className='exportbtn'
                                                                                    onClick={etikettenprinted}
                                                                                    disabled={!printed || etilist.length === 0}
                                                                                >Etiketten gedruckt
                                                                            </button>

                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='td_button'>
                                                                            <button                                                                 
                                                                                className='exportbtn'
                                                                                onClick={closeform}                                                                                
                                                                            >{CLOSE_BUTTON_TEXT}
                                                                            </button>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
                                                            <th colSpan={2}>Etiketthöhe</th>                                                            
                                                            <th colSpan={2}>Etikettbreite</th>                                                            
                                                            <th colSpan={2}>Seitenränder</th>
                                                            <th colSpan={2}>Seitenformat</th>                                                            
                                                        </tr>                                                        
                                                        <tr>
                                                             <td className='td_label'>Klein (70mm)</td>
                                                            <td className='td_label'>
                                                                <FormGroup
                                                                    type="radio"                                                    
                                                                    className=""
                                                                    onChange={hchanged70}
                                                                    value={hoch}
                                                                    checked={hoch===70}
                                                                    name="hoch"
                                                                />
                                                            </td>
                                                            
                                                            <td className='td_label'>Normal (30mm)</td>
                                                            <td className='td_label'>
                                                                <FormGroup
                                                                    type="radio"                                                    
                                                                    className=""
                                                                    onChange={brchangedfalse}
                                                                    value={breit}
                                                                    checked={!breit}
                                                                    name="breit"
                                                                />
                                                            </td>
                                                            <td className='td_label'>Klein (5mm)</td>
                                                            <td className='td_label'>
                                                                <FormGroup
                                                                    type="radio"                                                    
                                                                    className=""
                                                                    onChange={mchanged5}
                                                                    value={margin}
                                                                    checked={margin===5}
                                                                    name="margin"
                                                                />
                                                            </td>
                                                            <td className='td_label'>Hochformat</td>
                                                            <td className='td_label'>
                                                                <FormGroup
                                                                    type="radio"                                                    
                                                                    className=""
                                                                    onChange={lschangedfalse}
                                                                    value={landscape}
                                                                    checked={!landscape}
                                                                    name="landscape"
                                                                />
                                                            </td>
                                                            <td className='td_button' rowSpan={4} colSpan={2}>
                                                            </td>                                                            
                                                        </tr>
                                                        <tr>
                                                            
                                                            <td className='td_label'>Normal (80mm)</td>
                                                            <td className='td_label'>
                                                                <FormGroup
                                                                    type="radio"                                                    
                                                                    className=""
                                                                    onChange={hchanged80}
                                                                    value={hoch}
                                                                    checked={hoch===80}
                                                                    name="hoch"
                                                                />
                                                            </td>
                                                            
                                                            <td className='td_label'>Breit (45mm)</td>
                                                            <td className='td_label'>
                                                                <FormGroup
                                                                    type="radio"                                                    
                                                                    className=""
                                                                    onChange={brchangedtrue}
                                                                    value={breit}
                                                                    checked={breit}
                                                                    name="breit"
                                                                />
                                                            </td>
                                                     
                                                            <td className='td_label'>Normal (10mm)</td>
                                                            <td className='td_label'>
                                                                <FormGroup
                                                                    type="radio"                                                    
                                                                    className=""
                                                                    onChange={mchanged10}
                                                                    value={margin}
                                                                    checked={margin===10}
                                                                    name="margin"
                                                                />
                                                            </td>
                                                            <td className='td_label'>Querformat</td>
                                                            <td className='td_label'>
                                                                <FormGroup
                                                                    type="radio"                                                                    
                                                                    onChange={lschangedtrue}
                                                                    value={landscape}
                                                                    checked={landscape}
                                                                    name="landscape"
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='td_label'>Hoch (100mm)</td>
                                                            <td className='td_label'>
                                                                <FormGroup
                                                                    type="radio"                                                    
                                                                    className=""
                                                                    onChange={hchanged100}
                                                                    value={hoch}
                                                                    checked={hoch===100}
                                                                    name="hoch"
                                                                />
                                                            </td>
                                                            <td></td><td></td>
                                                            <td className='td_label'>Groß (15mm)</td>
                                                            <td className='td_label'>
                                                                <FormGroup
                                                                    type="radio"                                                    
                                                                    className=""
                                                                    onChange={mchanged15}
                                                                    value={margin}
                                                                    checked={margin===15}
                                                                    name="margin"
                                                                />
                                                            </td>
                                                            <td></td><td></td>
                                                            <td className='td_button' rowSpan={2} colSpan={2}>                                                            
                                                            </td>                                                                                                                                                            
                                                        </tr>
                                                        <tr>                                                            
                                                            <td className='td_label'>Sehr hoch (120mm)</td>
                                                            <td className='td_label'>
                                                                <FormGroup
                                                                    type="radio"                                                    
                                                                    className=""
                                                                    onChange={hchanged120}
                                                                    value={hoch}
                                                                    checked={hoch===120}
                                                                    name="hoch"
                                                                />
                                                            </td>
                                                            <td></td>
                                                            <td></td>                                                           
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                                
                                                </div>
                                            </form>

                                            {/* <button
                                                className='exportbtn'
                                                onClick={printpreviewClick}
                                            >Druckvorschau</button> */}
                                        </td>
                                    </tr>
                                    {/* <tr className='table-blank-row'></tr> */}
                                </tbody>
                            </table>    
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='sm_table_div'>
                            <table className='sm_table_80'>
                            <thead>                               
                                <tr className='table-blank-row'></tr>
                                <tr>
                                    <th>Ort</th>
                                    <th>PLZ</th>
                                    <th>Einrichtung</th>
                                    <th>Name</th>
                                    <th>Vorname</th>
                                </tr>
                            </thead>
                            <tbody>                
                                { etilist.map( ({id, ort, postleitzahl, kurzbezeichnung, nachname, vorname}) => {
                                    return <tr key={id}>
                                        <td className='td_label'>{ort}</td>
                                        <td className='td_label'>{postleitzahl}</td>
                                        <td className='td_label'>{kurzbezeichnung}</td>
                                        <td className='td_label'>{nachname}</td>
                                        <td className='td_label'>{vorname}</td>
                                    </tr>
                                })}
                            </tbody>            
                            </table>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        }
    </div>
  )
}

EtikettenPreview.propTypes = {}

export default EtikettenPreview