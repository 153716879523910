

export function json2array(json){
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function(key){
        result.push(json[key]);
    });
    return result;
}

export function toValidJsonObject(jsonstring) 
{   
    if (jsonstring)
    { 
        try 
        {
            return JSON.parse(jsonstring);
        } 
        catch (e) {            
        }
    }
    return null;
}

export function xls2ArrayBuffer(wb) {
    let buf = new ArrayBuffer(wb.length);
    let view = new Uint8Array(buf);
    for (let i=0; i<wb.length; i++) {
        view[i] = wb.charCodeAt(i) & 0xFF;
    }
    return [buf];
}

export default json2array;