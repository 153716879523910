import React from 'react'; 
import { FormattedMessage } from 'react-intl';
  
const translate = (text) => {

    const txt = text.replace(".", "_");
    // return txt
    
    return (<FormattedMessage id={txt} />);
}

export default translate;