import React, { useState, useEffect } from 'react'
import { AiOutlineFileExcel } from 'react-icons/ai';
// import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import { FcCancel } from 'react-icons/fc';

import Spinner from '../../components/shared/spinner/Spinner';
import ConfigIcon from '../../util/configicon';
import { FetchService, ExportService, StorageService } from '../../services';
// import ExportService from '../../services/ExportService';
import { formatTeilnehmerDetail } from '../../util/formate'
import { toShortDateString } from '../../util/date-utils';
import ColumnSorter from '../../util/ColumnSorter';
import SortableTableHeader from '../../components/shared/tables/SortableTableHeader';

const KontrollListenPage = (props) => 
{
    const [ldata, setLdata] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [columnsSorter, setColumnSorter] = useState(null)
    const [sortOrder , setSortOrder] = useState(false)
    const [lastSort , setLastSort] = useState(null)

    useEffect(() => {
        async function loadData()
        {
            try {
                const response = await FetchService.readKontollListen(Number.parseInt(props.mstatus))
                if (Array.isArray(response.data)) {
                    // if (response.data.length > 0) {
                    //     console.log(response.data[0])
                    // }
                    setLdata(response.data)
                    setErrorMessage(null)
                    setColumnSorter(new ColumnSorter(response.data, 
                        [
                            ['MID', 'massnahmen_id'],
                            ['TID', 'teilnehmer_id'],
                            ['Teilnehmer', 'nachname'],
                            ['Einrichtung', 'Krankenhausname'],
                            ['Von', 'f_von'],
                            ['Bis', 'f_bis'],
                        ]))
                }
                else {
                    setLdata(null)
                    setErrorMessage(response.status + " " + response.statusText)                    
                }
            }
            catch (err) {
                console.log(err)
                setLdata(null)
                setErrorMessage(err.toString())
            }
        }
        setColumnSorter(null)
        loadData()
    }, [props.mstatus])

    const exportAbbrechen = () =>
    {        
        window.history.back();        
    }

    const exportToExcel = () => 
    {
        console.log("export excel")
        ExportService.exportKontrollListen(Number.parseInt(props.mstatus), 
            StorageService.getZahlungsLaufNumber(), 
            StorageService.getZahlungsLaufJahr())
    }

    const columnclick = (evt) => {   
        if (columnsSorter) {
            const res = columnsSorter.sortColumn(evt)
            if (res) {
                // console.log(res[0])
                setLdata(columnsSorter.rdata)
                setSortOrder(columnsSorter.sortOrder)
                setLastSort(columnsSorter.lastSort)
            }
        }
    }

    return (
        <>
            { ldata && <>
                <div>
                <table>
                    <thead>
                        <tr className="table-blank-row"></tr>
                        <tr>
                            <th>Kontrolllisten {props.mstatus === '9' ? "Nachweis bestätigt (9)" : "Rechnung gestellt (19)" }</th>
                            { ldata && ldata.length > 0 && 
                            <th className="td_button">
                                <button className="exportbtn" title="Als Excel exportieren" onClick={exportToExcel}>
                                    <ConfigIcon><AiOutlineFileExcel/></ConfigIcon>&nbsp;Exportieren
                             </button>
                            </th>
                            }
                            <th className="td_button">
                                <button className="cancelbtn" title="Zurück" onClick={exportAbbrechen}>
                                    <ConfigIcon><FcCancel/></ConfigIcon>&nbsp;Abbrechen
                                </button>
                            </th>                        
                        </tr>
                        <tr>
                            { ldata && ldata.length > 0 && <th className="td_input">Anzahl Datensätze: { ldata.length }</th> }
                        </tr>
                        <tr>
                            {errorMessage && <th>{errorMessage}</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr className="table-blank-row"></tr> */}
                        <tr className="table-blank-row"></tr>
                    </tbody>
                </table>
                </div>
                <div>
                { ldata && 
                    <table className="sm_table">
                        { (ldata.length === 0) && <thead>
                            <tr><th>Keine Datensätze gefunden</th></tr>
                        </thead> 
                        }
                        { (ldata.length > 0) && <thead>
                            <tr>
                                <SortableTableHeader className='th_sortable' onClick={columnclick} title='MID' lastSort={lastSort} sortOrder={sortOrder} />
                                <SortableTableHeader className='th_sortable' onClick={columnclick} title='TID' lastSort={lastSort} sortOrder={sortOrder} />
                                <SortableTableHeader className='th_sortable' onClick={columnclick} title='Teilnehmer' lastSort={lastSort} sortOrder={sortOrder} />
                                <SortableTableHeader className='th_sortable' onClick={columnclick} title='Einrichtung' lastSort={lastSort} sortOrder={sortOrder} />
                                <SortableTableHeader className='th_sortable' onClick={columnclick} title='Von' lastSort={lastSort} sortOrder={sortOrder} />
                                <SortableTableHeader className='th_sortable' onClick={columnclick} title='Bis' lastSort={lastSort} sortOrder={sortOrder} />

                                {/* <th className="th_sortable" onClick={clickSort}>MID{lastSort === 'MID' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>
                                <th className="th_sortable" onClick={clickSort}>TID{lastSort === 'TID' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>
                                <th className="th_sortable" onClick={clickSort}>Teilnehmer{lastSort === 'Teilnehmer' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>                        
                                <th className="th_sortable" onClick={clickSort}>Einrichtung{lastSort === 'Einrichtung' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>
                                <th className="th_sortable" onClick={clickSort}>Von{lastSort === 'Von' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>
                                <th className="th_sortable" onClick={clickSort}>Bis{lastSort === 'Bis' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th> */}
                                <th className='th_sortable'>Betrag berechnet</th>
                                <th className='th_sortable'>Betrag bestaetigt</th>
                            </tr>
                        </thead>
                        }
                        <tbody>                        
                        {
                            ldata.map( ({ Krankenhausname, massnahmen_id, teilnehmer_id, 
                                BetragBerechnet, BetragBestaetigt, ort, f_von, f_bis,
                                geburtsname, geburtsdatum, nachname, vorname
                            })  => 
                            {                                
                            return <tr className="liste" key={massnahmen_id}>
                                <td className="td_fitwidth"><a href={`/Massnahme/Edit?mid=${massnahmen_id}`}>{massnahmen_id}</a></td>  
                                <td className="td_fitwidth"><a href={`/Teilnehmer/Edit?tid=${teilnehmer_id}`}>{teilnehmer_id}</a></td>  
                                <td className="td_fitwidth">{ formatTeilnehmerDetail(null,nachname,vorname, geburtsname, geburtsdatum, "nvd" )}</td>
                                <td className="td_fitwidth">{Krankenhausname ?? ''}</td>
                                <td className="td_fitwidth">{toShortDateString(f_von)}</td>
                                <td className="td_fitwidth">{toShortDateString(f_bis)}</td>
                                <td className="td_fitwidthR">{(BetragBerechnet ?? 0).toLocaleString("de-DE", {minimumFractionDigits:2, maximumFractionDigits : 2})}</td>
                                <td className="td_fitwidthR">{(BetragBestaetigt ?? 0).toLocaleString("de-DE", {minimumFractionDigits:2, maximumFractionDigits : 2})}</td>
                            </tr>
                            })
                        }
                            <tr className="table-blank-row"><td></td></tr>
                        </tbody>
                    </table> 
                }
                </div>
            </>
            }
            { !ldata && !errorMessage && <Spinner /> }
        </>
    )
}

export default KontrollListenPage
