import React from 'react'
import PropTypes from 'prop-types'

import { AiFillQuestionCircle } from 'react-icons/ai'; // AiOutlineQuestionCircle,
import { ConfigGreenIcon } from '../../../util/configicon';
import './tooltip.css'

const Tooltip = props => 
{
  return (
    <div className="tooltip">
      <ConfigGreenIcon><AiFillQuestionCircle/></ConfigGreenIcon> {props.tttitle}<span className="tooltiptext">{props.tttext}</span>
    </div> 
  )
}

Tooltip.propTypes = {
  tttitle : PropTypes.string,
  tttext : PropTypes.string
}

export default Tooltip