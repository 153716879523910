
// import NumberUtils from './NumberUtils';
// import Config from '../config/config';

const NumberUtils = require('./NumberUtils')
const Config = require('../config/config')
// const config = require('config')

class PasswordUtils
{
    static get PLATFORM_MIN_LENGTH() { return Config.password.minlength || 14 };
    static get PLATFORM_MAX_LENGTH() { return Config.password.maxlength || 16 };
    static get PASSWORD_USE_NUM_CHAR() { return Config.password.numchars || false};
    static get PASSWORD_USE_EXTRA_CHAR() { return Config.password.extrachars || false};

    static get CHAR_NUM() { return "0" }
    static get CHAR_ALPHA() { return "X" }
    static get CHAR_EXTRA() { return "." }

    static get NUM_CHARS() { return "0123456789" }
    static get ALPHA_CHARS() { return "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz" }
    static get EXTRA_CHARS() { return "!$%&()#+.,-=;:_><" }

    static generate(minLength, maxLength, charClassesString)
    {       
        // console.log("PASSWORD_USE_NUM_CHAR " + this.PASSWORD_USE_NUM_CHAR)
        // console.log("PLATFORM_MIN_LENGTH   " + this.PLATFORM_MIN_LENGTH)
        // console.log("PLATFORM_MAX_LENGTH   " + this.PLATFORM_MAX_LENGTH)
        minLength = minLength ?? this.PLATFORM_MIN_LENGTH;
        maxLength = maxLength ?? this.PLATFORM_MAX_LENGTH;
        if (minLength > maxLength) {
            let ml = minLength
            minLength = maxLength
            maxLength = ml
        }
        // console.log("minlength " + minLength)
        // console.log("maxlength " + maxLength)

        let result = ""
        let tmp = ""  
        let ch_num = (charClassesString) ? charClassesString.indexOf(this.CHAR_NUM) >= 0 : this.PASSWORD_USE_NUM_CHAR;
        let ch_extra = (charClassesString) ? charClassesString.indexOf(this.CHAR_EXTRA) >= 0 : this.PASSWORD_USE_EXTRA_CHAR;
          
        let generateLen = minLength + NumberUtils.getRandomInt(maxLength-minLength+1)        
        let numCount = (ch_num ? Math.floor(generateLen/5) : 0)
        let extraCount = (ch_extra ? Math.floor(generateLen/8) : 0)
        // console.log("generateLen " + generateLen);
        // console.log("numcount    " + numCount);
        // console.log("xtracount   " + extraCount);
        let charCount = generateLen - numCount - extraCount

        let XA = [];
        for (let num=0; num<charCount; num++) { XA.push(this.CHAR_ALPHA) }
        for (let num=0; num<numCount; num++) { XA.push(this.CHAR_NUM) }
        for (let num=0; num<extraCount; num++) { XA.push(this.CHAR_EXTRA) }
        
        while (tmp.length < generateLen) {
            let index = NumberUtils.getRandomInt(XA.length)
            let X = XA[index]                        
            XA.splice(index, 1)
            
            if ((charCount > 0) && (X === this.CHAR_ALPHA)) {
                tmp += this.ALPHA_CHARS[NumberUtils.getRandomInt(this.ALPHA_CHARS.length)]
                charCount--;
            }
            if ((numCount > 0) && (X === this.CHAR_NUM)) {
                tmp += this.NUM_CHARS[NumberUtils.getRandomInt(this.NUM_CHARS.length)]
                numCount--;
            }
            if ((extraCount > 0) && (X === this.CHAR_EXTRA)) {
                tmp += this.EXTRA_CHARS[NumberUtils.getRandomInt(this.EXTRA_CHARS.length)]
                extraCount--;
            }
        }

        result += tmp
        // result += "\nPassword min=" + minLength + " max=" + maxLength + 
        //    " numerisch=" + ch_num + " alpha=" + ch_alpha + " sonderzeichen=" + ch_extra + "\n" + 
        //    "generiere Länge=" + generateLen
        return result;
    }

    static validate(pass) 
    {
        // console.log(typeof(pass))
        // console.log(pass)
        // console.log(this.ALPHA_CHARS.length)
        // console.log(this.NUM_CHARS.length)

        if (pass) 
        {
            if (typeof(pass) === "string") 
            {
                if (pass.length < this.PLATFORM_MIN_LENGTH ) {
                    return "1;Das Passwort ist zu kurz"
                }
                if (this.PASSWORD_USE_NUM_CHAR && !this.checkCharClass(this.NUM_CHARS, pass)) {
                    return "2;Das Passwort muss mindestens eine Ziffer enthalten"
                }
                if (!this.checkCharClass(this.ALPHA_CHARS, pass)) {
                    return "3;Das Passwort muss mindestens einen Buchstaben enthalten"
                }
                if (this.PASSWORD_USE_EXTRA_CHAR && !this.checkForExtraChars(pass)) {
                     return "4;Das Passwort muss mindestens ein Sonderzeichen enthalten"
                }     
                return "0;OK"       
            }
            return "5;Passwort ist kein String"
        }
        return "6;Kein Passwort angegeben"
    }
    
    static rules()
    {
        return "OK;" + this.PLATFORM_MIN_LENGTH + "/" + 
            (this.PASSWORD_USE_NUM_CHAR ? "1" : "0") + "/" + 
            (this.PASSWORD_USE_EXTRA_CHAR ? "1" : "0");        
    }

    static checkCharClass(cls, pass) {            
        for (let num=0; num<cls.length; num++) {
            if (pass.indexOf(cls[num]) >= 0) {                
                return true;
            }
        }
        return false;
    }

    static checkForExtraChars(pass) {
        for (let num=0; num < pass.length; num++) {
            if (this.ALPHA_CHARS.indexOf(pass[num]) >= 0)
                continue;
            if (this.NUM_CHARS.indexOf(pass[num]) >= 0)
                continue;
            
            return true; // Zeichen ist nicht alphanum -> also Sonderzeichen
        }
        return false;
    }
}

module.exports = PasswordUtils;