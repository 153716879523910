import React from 'react';

const RadioControl = ({ name, value, className, onChange, disabled, checked }) => {   
    // console.log(value + " " + name + " " + options.length) 

    return (
        <input
            type="radio"  
            className={className || 'form-radio-input'}             
            name={name}             
            value={value}
            checked={checked}
            disabled={disabled || false } 
            onChange={(e) => onChange(e.target.value)}            
            >            
        </input>
    );
}

export default RadioControl;