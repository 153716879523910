import React, { useEffect, useState }  from 'react';
import PropTypes from 'prop-types';

// import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import { toShortDateString } from '../../util/date-utils'
import { formatTeilnehmer, formatEinrichtung } from '../../util/formate'
import Auth from '../../services/authenticationService';
import SortableTableHeader from '../../components/shared/tables/SortableTableHeader';

const MGekuerzt = (props) => {

    const [mResult, setmResult] = useState(null)

    useEffect( () => {
        async function asyncFetchMassnahmenNachKuerzung()
        {            
            const response = await fetch('/data/kuerzungennachzahlung/kid', { method: 'GET' })
            if (response.ok) {                        
                const result = await response.json();    
                if (Array.isArray(result))
                {
                    // if (result.length > 0)                      
                    //     console.log(result)
                    setmResult(result)
                }
            }           
        }
        asyncFetchMassnahmenNachKuerzung()
    },[])

    function getValue(rec, index) {
        if (rec) {
            const idxOf = index.indexOf(".")            
            if (idxOf > 0) {
                
                return getValue( rec[index.substring(0, idxOf)], index.substring(idxOf+1) )
            }
            else {
                return rec[index]
            }
        }
        return null;
    }

    const [sortOrder , setSortOrder] = useState(false)
    const [lastSort , setLastSort] = useState(null)

    const columnclick = (evt) => 
    {       
        const txt = evt.currentTarget.innerText.trim()        
        if (lastSort !== txt) {
            setSortOrder(false)
            setLastSort(txt)
        }
        let idx = null;
        switch (txt) 
        {
            case 'TID' : idx = 'teilnehmer_id'; break;
            case 'MID' : idx = 'id'; break;
            case 'KID' : idx = 'KuerzungenNachZahlung.id'; break;      
            case 'Teilnehmer': idx = 'Teilnehmer.nachname'; break;
            case 'Einrichtung': idx = 'Einrichtungen.kurzbezeichnung'; break;
            case 'Von' : idx = 'f_von'; break;
            case 'Bis' : idx = 'f_bis'; break;
            case 'Verechnet mit' : idx = 'KuerzungenNachZahlung.korr_mid_verrechnung'; break;                
            default : idx=null; break;      
        }
        if (idx)
        {
            mResult.sort( (a, b) => {  
                if (getValue(a, idx) < getValue(b , idx)) {
                    return (sortOrder ? -1 : 1);
                } else if(getValue(a, idx) > getValue(b , idx)) {
                    return (sortOrder ? 1 : -1);
                }
                return 0;
            })
            setSortOrder(!sortOrder)            
            setmResult(mResult)
        }
    }

  return (
  <div>
      {mResult && mResult.length > 0 && <div className="data_table_div" >
            <table className="data_table">
                <colgroup>
                    <col style={{"width":"50px"}} />
                    <col style={{"width":"50px"}} />
                    <col style={{"width":"50px"}} />
                </colgroup>
                <thead>
                    <tr>
                        <SortableTableHeader className='th_sortable' onClick={columnclick} title='MID' lastSort={lastSort} sortOrder={sortOrder} />
                        <SortableTableHeader className='th_sortable' onClick={columnclick} title='TID' lastSort={lastSort} sortOrder={sortOrder} />
                        { Auth.isAdmin &&
                            <SortableTableHeader className='th_sortable'  onClick={columnclick} title='KID' lastSort={lastSort} sortOrder={sortOrder} />
                        }
                        <SortableTableHeader className='th_sortable'  onClick={columnclick} title='Teilnehmer' lastSort={lastSort} sortOrder={sortOrder} />
                        <SortableTableHeader className='th_sortable'  onClick={columnclick} title='Einrichtung' lastSort={lastSort} sortOrder={sortOrder} />
                        <SortableTableHeader className='th_sortable'  onClick={columnclick} title='Von' lastSort={lastSort} sortOrder={sortOrder} />
                        <SortableTableHeader className='th_sortable'  onClick={columnclick} title='Bis' lastSort={lastSort} sortOrder={sortOrder} />
                        <SortableTableHeader className='th_sortable'  onClick={columnclick} title='Verechnet mit' lastSort={lastSort} sortOrder={sortOrder} />                       
                    </tr>
                </thead>
                <tbody> 
                    {
                        mResult.map( ({ id, Teilnehmer, f_von, f_bis, KuerzungenNachZahlung, Einrichtungen }) => 
                        {   
                            return (
            <tr className="liste" key={id}>
                <td className="td_fitwidth"><a href={`/Massnahme/Edit?mid=${id}`}>{id}</a></td>
                <td className="td_fitwidth"><a href={`/Teilnehmer/Edit?tid=${Teilnehmer.id}`}>{Teilnehmer.id}</a></td>
            {   Auth.isAdmin &&
                <td className="td_fitwidth">
                    <a href={`/KuerzungNachZahlung/Edit?kid=${KuerzungenNachZahlung.id}`}>{KuerzungenNachZahlung.id}</a>
                </td>
            }
                <td className="td_fitwidth">{formatTeilnehmer(Teilnehmer,"vngd")}</td> 
                <td className="td_fitwidth" >{formatEinrichtung(Einrichtungen,"no" )}</td>
                <td className="td_fitwidth">{toShortDateString(f_von)}</td>
                <td className="td_fitwidth">{toShortDateString(f_bis)}</td>
                <td className="td_fitwidth">
                { KuerzungenNachZahlung.korr_mid_verrechnung && KuerzungenNachZahlung.korr_mid_verrechnung.split(",").map( (item) => 
                    {
                    return <span key={item}><a href={`/Massnahme/Edit?mid=${item.trim()}&tabpage=2`}>{item.trim()}</a>&nbsp;</span>
                    })
                }
                </td>
            </tr>
                                )
                        })
                    }
                </tbody>
            </table>
            </div>
            }

  </div>
  );
};

MGekuerzt.propTypes = {};

export default MGekuerzt;
