
export default class ParseResult
{
    static parseIdFromText(text, fieldName = "ID=") {
        let idx = text.indexOf(fieldName); 
        if (idx >= 0) {
            return text.substring(idx + fieldName.length)
        }
        throw new Error("Error parsing ID from result '" + text + "'")
    }
} 