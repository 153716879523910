import React, { useEffect, useState }  from 'react';
import PropTypes from 'prop-types';

// import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import { toShortDateString } from '../../util/date-utils'
import { formatCurrency } from '../../util/number-utils'
import { formatTeilnehmer, formatEinrichtung } from '../../util/formate'
import Auth from '../../services/authenticationService';
import SortableTableHeader from '../../components/shared/tables/SortableTableHeader';


const MVerrechnet = (props) => {

    const [mResult, setmResult] = useState(null)

    useEffect( () => {
        async function asyncFetchMassnahmenVerrechnetNachKuerzung()
        {            
            const response = await fetch('/data/verrechnungen/mid', { method: 'GET' })
            if (response.ok) {                        
                const result = await response.json();   
                if (Array.isArray(result))
                { 
                    // if (result.length > 0)                      
                    //     console.log(result [0])
                    setmResult(result)
                }
            }          
        }
        asyncFetchMassnahmenVerrechnetNachKuerzung()
    },[])

    function getValue(rec, index) {
        if (rec) {
            const idxOf = index.indexOf(".")            
            if (idxOf > 0) {
                
                return getValue( rec[index.substring(0, idxOf)], index.substring(idxOf+1) )
            }
            else {
                return rec[index]
            }
        }
        return null;
    }

    const [sortOrder , setSortOrder] = useState(false)
    const [lastSort , setLastSort] = useState(null)
    const columnclick = ((evt) => 
    {   
        const txt = evt.currentTarget.innerText.trim()              
        if (lastSort !== txt) {
            setSortOrder(false)
            setLastSort(txt)
        }
        let idx = null;
        switch (txt) 
        {
            case 'TID' : idx = 'teilnehmer_id'; break;
            case 'MID' : idx = 'id'; break;
            case 'VID' : idx = 'Verrechnungen.id'; break;      
            case 'Teilnehmer': idx = 'Teilnehmer.nachname'; break;
            case 'Einrichtung': idx = 'Einrichtungen.kurzbezeichnung'; break;
            case 'Von' : idx = 'f_von'; break;
            case 'Bis' : idx = 'f_bis'; break;
            case 'Berechnet' : idx = 'betragBerechnet'; break;
            case 'Bestaetigt' : idx = 'betragBestaetigt'; break;   
            case 'Gekürzte Maßnahme' : idx  = 'Verrechnungen.massnahmen_id_quelle'; break;
            default : idx=null; break;      
        }
        if (idx)
        {            
            mResult.sort( (a, b) => {  
                const av = getValue(a, idx)
                const bv = getValue(b , idx)
                if (av < bv) {
                    return (sortOrder ? -1 : 1);
                } else if(av > bv) {
                    return (sortOrder ? 1 : -1);
                }
                return 0;
            })
            setSortOrder(!sortOrder)            
            setmResult(mResult)
        }
    })

  return (
  <div>
      {mResult && mResult.length > 0 && <div className="data_table_div" >
            <table className="data_table">
                <colgroup>
                    <col style={{"width":"50px"}} />
                    <col style={{"width":"50px"}} />
                    <col style={{"width":"50px"}} />
                </colgroup>
                <thead>
                    <tr>
                        <SortableTableHeader className='th_sortable' onClick={columnclick} title='MID' lastSort={lastSort} sortOrder={sortOrder} />
                        <SortableTableHeader  className='th_sortable' onClick={columnclick} title='TID' lastSort={lastSort} sortOrder={sortOrder} />
                        { Auth.isAdmin &&
                        <SortableTableHeader  className='th_sortable' onClick={columnclick} title='VID' lastSort={lastSort} sortOrder={sortOrder} />
                        }
                        <SortableTableHeader  className='th_sortable' onClick={columnclick} title='Teilnehmer' lastSort={lastSort} sortOrder={sortOrder} />
                        <SortableTableHeader  className='th_sortable' onClick={columnclick} title='Einrichtung' lastSort={lastSort} sortOrder={sortOrder} />
                        <SortableTableHeader  className='th_sortable' onClick={columnclick} title='Von' lastSort={lastSort} sortOrder={sortOrder} />
                        <SortableTableHeader  className='th_sortable' onClick={columnclick} title='Bis' lastSort={lastSort} sortOrder={sortOrder} />
                        <th  className='th_sortable' >Berechnet</th>
                        <th  className='th_sortable' >Best&auml;tigt</th>
                        <SortableTableHeader  className='th_sortable'  onClick={columnclick} title='Gekürzte Maßnahme' lastSort={lastSort} sortOrder={sortOrder} />                        
                    </tr>
                </thead>
                <tbody>                
                    {
                        mResult.map( ({ id, Teilnehmer, Einrichtungen, f_von, f_bis, Verrechnungen, betragBerechnet, betragBestaetigt }) => 
                        {   
                            return (
        <tr className="liste" key={id}>
            <td className="td_fitwidth"><a href={`/Massnahme/Edit?mid=${id}`}>{id}</a></td>                                    
            <td className="td_fitwidth"><a href={`/Teilnehmer/Edit?tid=${Teilnehmer.id}`}>{Teilnehmer.id}</a></td>
            { Auth.isAdmin && <td className="td_fitwidth"><a href={`/Verrechnung/Edit?vid=${Verrechnungen.id}`}>{Verrechnungen.id}</a></td> }
            <td className="td_fitwidth">{formatTeilnehmer(Teilnehmer, "vngd")}</td> 
            <td className="td_fitwidth">{formatEinrichtung(Einrichtungen,"no" )}</td>
            <td className="td_fitwidth">{toShortDateString(f_von)}</td>
            <td className="td_fitwidth">{toShortDateString(f_bis)}</td>
            <td className="td_fitwidth">{formatCurrency(betragBerechnet)}</td>
            <td className="td_fitwidth">{formatCurrency(betragBestaetigt)}</td>
            <td className='td_fitwidth'>
                <a href={`/Massnahme/Edit?mid=${Verrechnungen.massnahmen_id_quelle}&tabpage=3`}>{Verrechnungen.massnahmen_id_quelle}</a>
            </td>
        </tr>
                                )
                        })
                    }
                </tbody>
            </table>
            </div>
            }

  </div>
  );
};

MVerrechnet.propTypes = {};

export default MVerrechnet;
