
import FetchService from '../../services/FetchService'
import { toShortDateString } from '../../util/date-utils'


async function BescheinigungErstellen(mid, fbis) 
{   
    if (new Date(fbis) > new Date()) {
        window.alert("Die Massnahme ist erst am " + toShortDateString(fbis) + " beendet\nund kann jetzt noch nicht nachgewiesen werden.")
        return null
    }
    const zielNachweisDatum = new Date(fbis.getFullYear()+1, 5, 30, 23, 59, 59)
    if (zielNachweisDatum < new Date())
    {
        if (!window.confirm("Die Maßnahme hätte bis zum " + toShortDateString(zielNachweisDatum) + 
            " nachgewiesen werden müssen!\nWollen Sie den Nachweis trotzdem drucken?"))
        {
            return null;
        }
    }
    const res = await FetchService.generateNachweisSchreiben(mid)
    return res;
}

export default BescheinigungErstellen;