import React from 'react';

const TextAreaControl = ({ name, value, className, onChange, rows, cols, readonly, disabled }) => {     
    const _value = (value === null || value === undefined) ? '' : value;
    const _rows = (rows ? rows : 5)

    // console.log("textarea value " + _value)

    return (
        <textarea className={className || 'form-control'} 
            value={_value} 
            name={name}  
            rows={_rows}
            onChange={(e) => onChange(e.target.value)}
            readOnly={readonly}
            disabled={disabled}
        >{_value}
        </textarea> 
    )
}

export default TextAreaControl;