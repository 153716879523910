// "use strict";
// const Strings = require('./Strings');

class DatesClass 
{
    constructor() {   
        // console.log("constructor dkgcommon.Dates")    
    }

    get INV_DATE() {
        return 'Invalid Date'
    }

    // letzer Tag im Monat = Datum vom 00.MM.YYYY --> Datum LASTDAY.MM-1.YYYY oder LASTDAY.12.YYYY-1
    lastDateInMonth(jsDate)
    {     
        return new Date(jsDate.getFullYear(), jsDate.getMonth()+1, 0);
    }

    lastDayInMonth(jsDate)
    {
        return this.lastDateInMonth(jsDate).getDate()
    }

    toDatabaseDate() {
        return this.toDatabaseDate_d(new Date())       
    }

    toDatabaseDate_d(jsdate) {
        return this.toDatabaseDateWorker(jsdate.getFullYear(), jsdate.getMonth(), jsdate.getDate())        
    }

    toDatabaseDate_ym(year, month) {
        return this.toDatabaseDateWorker(year, month-1, 1)
    }

    toDatabaseDate_ymd(year, month, day) {
        return this._toDatabaseDateWorker(year, month-1, day)
    }

    _toDatabaseDateWorker(year, month, day) {
        return new Date(Date.UTC(year, month, day)).toISOString().substring(0, 10)
    } 
    
    toValidDate(date)
    {
        if (this.isvalid(date))
        {
            return date;
        }        
        return null; // return (d && d !== 'Invalid Date') ? d : null;        
    }

    isvalid(date) 
    {    
        if (this.testNullOrInvalid(date)) {
            // console.log("data valid1 " + date + " " + typeof(date) + " " + false)
            return false;
        }
        const d = new Date(date);
        if (this.testNullOrInvalid(d)) {
            // console.log("data valid2 " + d + " " + typeof(d) + " " + false)
            return false;
        }
        // console.log("data valid2 " + d + " " + typeof(d) + " " +  true)
        return true
    }

    testNullOrInvalid(date) {
        if ( (date === null) || (date.toString() === this.INV_DATE)) {
            return true;
        }
        return false;
    }

    now(years) 
    {
        return this.addYears(new Date(), years);
    }

    addYears(date, years)
    {
        return this._toDatabaseDateWorker(date.getFullYear() + years, date.getMonth(), date.getDate())        
    }
}

const Dates = new DatesClass();

module.exports = Dates 