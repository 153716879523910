
export class MassnahmenListItems {

    static IbanKey = '1'
    static AspKey = '2'
    static NotPrintedKey = '3'
    static StornoKey = '4'
    static StornoKeyUndo  = '5'

    static items = [
        { 
            key: this.IbanKey, 
            title: 'Maßnahmen mit fehlerhafter/fehlender IBAN', 
            url:'/data/zahlungslaeufe/ibanerror/',
            kid:1, eid:1, mid:1,
        },
        { 
            key: this.AspKey, 
            title: 'Maßnahmen mit fehlendem Ansprechpartner', 
            url:'/data/zahlungslaeufe/asperror/',
            aid:1, eid:1, mid:1
        },
        { 
            key: this.NotPrintedKey, 
            title: 'Maßnahmen ohne Bestätigung', 
            url:'/data/zahlungslaeufe/notprinted/',
            mid:1, aid:1
        },
        { 
            key: this.StornoKey, 
            title: 'Maßnahmen die storniert werden können', 
            url:'/data/zahlungslaeufe/storno/',
            check:1,
            mid:1,
            eid:1,
            ende:1
        },
        { 
            key: this.StornoKeyUndo, 
            title: 'Stornierung der Maßnahme aufheben', 
            url:'/data/zahlungslaeufe/stornoundo/',
            check:1,
            mid:1,
            eid:1,
            ende:1
        }
    ] 

    static findItem(id) {
        const result = this.items.find( element => element.key === id)
        return result;
    }
}

export default MassnahmenListItems
