export function toShortDateString(datestring) 
{
    if (datestring) {
        const date = new Date(datestring);
        const dateOptions = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        }    
        return date.toLocaleDateString("de-DE", dateOptions)
    }
    return null;
}

export function toShortDateTimeString(datestring) 
{
    if (datestring) {
        const date = new Date(datestring);
        const dateOptions = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        }    
        return date.toLocaleDateString("de-DE", dateOptions)
    }
    return null;
}
