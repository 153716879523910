import React, { useState, useEffect } from 'react';
import { registerLocale } from 'react-datepicker';
import { AiOutlineEdit, AiOutlineSearch, AiOutlineCloseCircle  } from 'react-icons/ai';
import 'react-datepicker/dist/react-datepicker.css';
import MaterialTable from 'material-table';

import de from "date-fns/locale/de";
import ConfigIcon from '../../util/configicon';
import FormGroup from '../../components/shared/form-group';
import json2array, { toValidJsonObject } from '../../util/json2array';
import DoubleClickDetector from '../../util/DoubleClickDetector';
import { materialTableOptions, materialTableLocalization } from '../../util/material-table-options';
import { authenticationService, StorageService } from "../../services/";
// import StorageService from '../../services/StorageService';

registerLocale("de", de)

const SearchTeilnehmer  = () => {
    
    const storageValue = toValidJsonObject(localStorage.getItem(StorageService.TeilnehmerSearchData))
    // console.log(storageValue)
    
    const TSearchData = (storageValue ? storageValue :
    {
        id: null,
        titel: null,
        nachname: null,
        vorname: null,
        geburtsname: null,
        geburtsdatum: null,
    })

    const [id, setId] = useState(TSearchData.id)
    const [titel, setTitel] = useState(TSearchData.titel)
    const [nachname, setNachname] = useState(TSearchData.nachname)
    const [vorname, setVorname] = useState(TSearchData.vorname)
    const [geburtsname, setGeburtsname] = useState(TSearchData.geburtsname)   
    const [geburtsdatum, setGeburtsdatum] = useState(TSearchData.geburtsdatum ? new Date(TSearchData.geburtsdatum) : "Invalid Date")

    const [selectedRow, setSelectedRow] = useState(null)

    const style = getComputedStyle(document.body)
    const fontSize = style.getPropertyValue('--labelfontscale')
    const menuFontSize = style.getPropertyValue('--menufontscale')

    const resultColumns = [        
        { 
            title:'Id',           field:'id',                             width:'5%',
            headerStyle: { 'font-size' : fontSize, padding: '4px' }, 
            cellStyle: { 'font-size' : fontSize, padding: '4px',}
        },
        // { title:'Titel',        field:'titel',                          width:'5%'  },
        { 
            title:'Nachname',     field:'nachname',                       width:'50%', 
            headerStyle: { 'font-size' : fontSize, padding: '4px' }, minWidth:'50%',maxWidth:'50%' ,
            cellStyle: { 'font-size' : fontSize, padding: '4px',}
        },
        { 
            title:'Vorname',      field:'vorname',                        width:'50%', 
            headerStyle: { 'font-size' : fontSize, padding: '4px' }, minWidth:'50%',maxWidth:'50%' ,
            cellStyle: { 'font-size' : fontSize, padding: '4px', }
        },
        { 
            title:'Geburtsname',  field:'geburtsname',                    width:'5%',
            headerStyle: { 'font-size' : fontSize, padding: '4px', width : '10%' },
            cellStyle: { 'font-size' : fontSize, padding: '4px',}
        },
        { 
            title:'Geburtsdatum', 
            field:'geburtsdatum', 
            type:'date',  
            // dateSetting: { locale: "de-DE"  }, 
            width:'5%' , 
            headerStyle: { 'font-size' : fontSize, padding: '4px'}, 
            cellStyle: { 'font-size' : fontSize, padding: '4px',}
        }        
    ];
    
    const adminColumnEinrichtung =  {
        title: 'Einrichtung',
        field: 'einrichtung',
        width: '20%',
    }
    const adminColumnOrt = {
        title : 'Ort',
        field : 'ort',
        width : '20%',
    }

    function getResultColumns() 
    {
        const result = []
        resultColumns.forEach((item) => result.push(item))
    
        if (authenticationService.isAdmin) {            
            result.push(adminColumnEinrichtung)
            result.push(adminColumnOrt)
        }
        return result;
    }

    const [resultData, setResultData] = useState([
        // {
            // id
            // ik
            // ik_alt
            // kurzbezeichnung
            // postleitzahl
            // ort
            // strasse_hausnr_postfach
            // wb_verbund
            // kvid_kbv
            // kv_bezirk
        // }
    ])

    const _setGeburtsdatum = (d) => { setGeburtsdatum(d) }

    useEffect(() => {
        
    }, [])    

    const tableRef = React.createRef();

    const sucheStarten = () => {
        TSearchData.id = id
        TSearchData.titel = titel
        TSearchData.nachname = nachname
        TSearchData.vorname = vorname
        if (geburtsdatum) {
            // console.log("string " + geburtsdatum)
            // const md = Moment(geburtsdatum,'DD.MM.YYYY', "de").format('YYYY-MM-DD')
            // console.log("mdate  " + md)            
            TSearchData.geburtsdatum =  geburtsdatum // md
        }
        else {
            TSearchData.geburtsdatum = null;
        }
        TSearchData.geburtsname = geburtsname
        
        const sv = JSON.stringify(TSearchData);        
        localStorage.setItem(StorageService.TeilnehmerSearchData, sv); 

        fetch('/data/teilnehmer/search/' + sv, {
            method: 'POST'
        })
        .then( (res) => {
            // console.log("then " + res)
            if (res.ok) {
                return res.json()
            }
            throw new Error(res.status + " " + res.statusText)
        })
        .then( (json) => {
            // console.log(JSON.stringify(json))
            if (Array.isArray(json))
            {                
                let mySource = json2array(json); 
                // console.log("mySource.length " + mySource.length)   
                // console.log("then json " + JSON.stringify(json))      
                // console.log(mySource[0].Beschaeftigungen.Einrichtungen.kurzbezeichnung)
                // console.log(mySource[0].Beschaeftigungen.Einrichtungen.ort)
                let myResult = [];
                for (let num=0; num < mySource.length; num++)
                {   
                    const einrichtung = (mySource[num].Beschaeftigungen ? mySource[num].Beschaeftigungen.Einrichtungen : null)                     
                    myResult.push({
                        id : mySource[num].id,
                        titel : mySource[num].titel,
                        vorname : mySource[num].vorname,
                        nachname : mySource[num].nachname,                      
                        geburtsname :  mySource[num].geburtsName,
                        geburtsdatum : mySource[num].geburtsDatum, // toShortDateString( mySource[num].geburtsdatum),   
                        einrichtung : (einrichtung ? einrichtung.kurzbezeichnung : ''),
                        ort : (einrichtung ? einrichtung.ort : '')                             
                    })
                // console.log(mySource[num].geburtsdatum);
                }
                // console.log("myResult.length " + myResult.length)                                               
                setResultData(myResult);          
            }
            else
            {
                setResultData([]);      
                throw new Error("unexpected result")
            }
        })
        .catch((err) => {
            console.log("catch " + err)
        })

    }

    const sucheClear = () => {

        setId(null)
        setTitel(null)
        setNachname(null)
        setVorname(null)
        setGeburtsname(null)
        setGeburtsdatum('Invalid Date')
        setResultData( [] );

        localStorage.setItem(StorageService.TeilnehmerSearchData, null); 
    }

    const teilnehmerEdit = () => {
        if ((selectedRow) || Number.isInteger(selectedRow))  {                                  
            window.location.href = "/Teilnehmer/Edit?tid="+resultData[selectedRow].id
        }
    }

    const teilnehmerNew = () => {
        console.log("Neuer Teilnehmer")
        const sd = localStorage.getItem(StorageService.TeilnehmerSearchData)        
        if (sd) 
        {
            // console.log(sd)
            const td = toValidJsonObject(sd)
            if (td) {
                // console.log(td)
                localStorage.setItem(StorageService.TeilnehmerData, JSON.stringify(td))
                window.location.href = "/Teilnehmer/New"
            }
        }
    }

    return (
        <div>
        <div className="sm_table_div">  
        <table>
            <thead>
                <tr className="table-blank-row"></tr>         
                <tr>
                    <th colSpan="4"><h3>Suche Teilnehmer</h3></th><th></th>
                </tr>
            </thead>
            <tbody>
                <tr className="table-blank-row"></tr>
                <tr>
                    <td className="td_label">ID:</td>
                    <td className="td_input">
                        <FormGroup 
                            type="text"
                            label=""
                            name="teilnehmerId"                             
                            value={id} 
                            onChange={setId} 
                        />
                    </td>
                    <td className="td_label">Titel:</td>
                    <td className="td_input">
                        <FormGroup 
                            type="text"
                            label=""
                            name="titel"                             
                            value={titel} 
                            onChange={setTitel} 
                        />
                    </td>
                </tr>
                <tr>
                    <td className="td_label">Nachname:</td>
                    <td className="td_input">
                        <FormGroup 
                            type="text"
                            label=""
                            name="nachname"                             
                            value={nachname} 
                            onChange={setNachname} 
                        />
                    </td>
                    <td className="td_label">Vorname:</td>
                    <td className="td_input">
                        <FormGroup 
                            type="text"
                            label=""
                            name="vorname"                             
                            value={vorname} 
                            onChange={setVorname} 
                        />
                    </td>
                </tr>
                <tr>
                    <td className="td_label">Geburtsname:</td>
                    <td className="td_input">
                        <FormGroup 
                            type="text"
                            label=""
                            name="geburtsname"                             
                            value={geburtsname} 
                            onChange={setGeburtsname} 
                        />
                    </td>
                    <td className="td_label">Geburtsdatum:</td>
                    <td className="td_input">  
                        <FormGroup
                            type="html-date"
                            className="form-control" //"td_datepicker"
                            value={geburtsdatum}
                            onChange={_setGeburtsdatum} 
                            // max={Moment().add(-16,'years').toDate()}
                            // min={Moment().add(-100, 'years').toDate()}                      
                        />
                        {/* <DatePicker
                                popperClassName="react-datepicker-popper"
                                className="form-control" //"td_datepicker"
                                selected={geburtsdatum}
                                onChange={setGeburtsdatum} 
                                dateFormat={"dd.MM.yyyy"}
                                // minDate
                                // isClearable={true}
                                maxDate={Moment().add(-16,'years').toDate()}
                                minDate={Moment().add(-100, 'years').toDate()}
                                // showFullMonthYearPicker={true}
                                // showMonthYearPicker={true}
                                // filterDate={ date => date.getDay() != 6 && date.getDay() != 0 }
                                showYearDropdown={true}
                                locale={de}                           
                        /> */}
                    </td>
                    {/* <td className="td_input">                        
                        <FormGroup 
                            type="text"
                            label=""
                            name="geburtsdatum"                             
                            value={geburtsdatum} 
                            onChange={setGeburtsdatum} 
                        /> 
                    </td> */}
                    <td>                            
                    </td>
                </tr>
                <tr className="table-blank-row"></tr>   
                <tr>   
                    <td>
                        <button className="exportbtn" onClick={sucheStarten}>
                            <ConfigIcon><AiOutlineSearch/></ConfigIcon>&nbsp;Suche starten
                        </button>
                    </td>
                    <td>
                        <button className="exportbtn" onClick={sucheClear}>
                            <ConfigIcon><AiOutlineCloseCircle/></ConfigIcon>&nbsp;Suche l&ouml;schen
                        </button>
                    </td>
                    <td>
                        <button className="exportbtn btndefault" onClick={teilnehmerNew}>
                            <ConfigIcon><AiOutlineEdit/></ConfigIcon>&nbsp;Neuer Teilnehmer
                        </button>                    
                    </td>  
                    <td><button className="exportbtn btndefault" onClick={teilnehmerEdit}>
                        <ConfigIcon><AiOutlineEdit/></ConfigIcon>&nbsp;Teilnehmer bearbeiten
                    </button></td>
                </tr>
                <tr className="table-blank-row"></tr>   
            </tbody>
        </table>
        </div>
        <div>
        
        <MaterialTable 
            tableRef={tableRef}
            columns={getResultColumns()} 
            data={resultData} 
            title={'Teilnehmer ('  + resultData.length + ')'} 
            localization= {
                materialTableLocalization()
            }             
            options={
                Object.assign(
                    {                              
                        rowStyle : rowData => ({
                            backgroundColor:selectedRow === rowData.tableData.id ? "#bbb" : "" 
                        }),
                        searchFieldStyle : { 'font-size' : menuFontSize},     
                    },
                    materialTableOptions()
                )
            }

            onRowSelected= { (evt, path, dataclicked) => {
                console.log("onRowSelected")
            }}        
            onSelectionChange={ (dataClicked) => {
                console.log("onSelectionChange")
            }}   
            
            onRowClick={ (evt, selectedRow) => {
                setSelectedRow(selectedRow.tableData.id);
                if (DoubleClickDetector.mouseClickEvent(evt)) {
                    teilnehmerEdit();
                }
            }}
            onRowsPerPageChange={ (evt) => {
            }}

            onPageChange = { (evt) => {
            }}
            />
        </div>
    </div>
    )        
}



export default SearchTeilnehmer;