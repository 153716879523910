import React, { useState, useEffect } from 'react'

const TicketHistory = (props) => 
{
    // console.log(props)
    // console.log(props.data)

    const [list, setlist] = useState(null)

    useEffect(() => 
    {
        async function loadComments()
        {
            // console.log("Kommentare Selected TicketID " + props.data)
            if (props.data)
            {
                try 
                {                                
                    const response = await fetch('/data/admin/redmine/issues/' + props.data)
                    if (response.ok) {
                        const result = await response.json();
                        // console.log(result)
                        const lst = []
                        result.issue.journals.forEach(element => {
                            if (!element.notes && element.details && element.details.length > 0) {
                                lst.push(element)
                            }                            
                        });                        
                        setlist(lst)
                        return;
                    }
                    else {
                        console.log(response.status + " " + response.statusText)
                    }
                }
                catch (err) {
                    console.log("" + err)
                }
            }
            setlist(null)
        }
        loadComments()
    }, [props.data])


    return (
        <div style= {{display:'none'}}>
        {
            list && list.length > 0 && <div>
                <table className='sm_table'>
                    <tbody>
                        {list.map ( ({id, notes, created_on, user }) => {
                            return (<tr key={id}>
                                <td>
                                    {created_on}
                                </td>
                                <td>
                                    {user.name}
                                </td>
                                <td>
                                    {notes}
                                </td>
                            </tr>)
                        })
                    }
                    </tbody>
                </table>
                {/* {property: 'attr', name: 'description', old_value: '', new_value: 'Das ist die Beschreibung der Aufgabe'}                */}
            </div>
        }
        {  
            (!list || list.length === 0) && <h3>keine Kommentare</h3>
        }
        </div>
        
    )
}

export default TicketHistory