
export class UpdateHandler
{
    static setStorageBL(tid)  { localStorage.setItem('UPDATEBL', JSON.stringify( { tid : tid } )) }
    static setStorageTN(tid)  { localStorage.setItem('UPDATETN', JSON.stringify( { tid : tid } )) }
    static setStorageKTO(eid) { localStorage.setItem('UPDATEKTO', JSON.stringify( { eid : eid } )) }
    static setStorageASP(eid) { localStorage.setItem('UPDATEASP', JSON.stringify( { eid : eid } )) }
    static setStorageMM(mid)  { localStorage.setItem('UPDATEMM', JSON.stringify( {  mid : mid } ))  }
    static setStorageEID(eid)  { localStorage.setItem('UPDATEEID', JSON.stringify( {  eid : eid } ))  }



    static hasStorageBL() { return this.#hasUpdateStorage('BL') }
    static hasStorageTN() { return this.#hasUpdateStorage('TN') }
    static hasStorageKTO() { return this.#hasUpdateStorage('KTO') }
    static hasStorageASP() { return this.#hasUpdateStorage('ASP') }
    static hasStorageMM() { return this.#hasUpdateStorage('MM') }
    static hasStorageEID() { return this.#hasUpdateStorage('EID') }

    static removeStorageBL() { localStorage.removeItem('UPDATEBL') }
    static removeStorageTN() { localStorage.removeItem('UPDATETN') }
    static removeStorageKTO() { localStorage.removeItem('UPDATEKTO') }
    static removeStorageASP() { localStorage.removeItem('UPDATEASP') }
    static removeStorageMM() { localStorage.removeItem('UPDATEMM') }
    static removeStorageEID() { localStorage.removeItem('UPDATEEID') }

    static updateStorageMM(mid) {
        if (this.hasStorageMM() ) { this.removeStorageMM() }  this.setStorageMM(mid); this.removeStorageMM();
    }

    static updateStorageTN(tid) {
        if (this.hasStorageTN()) { this.removeStorageTN() } this.setStorageTN(tid); this.removeStorageTN();
    }

    static updateStorageBL(tid) {
        if (this.#hasUpdateStorage('BL')) { this.removeStorageBL() } this.setStorageBL(tid); this.removeStorageBL();
    }

    static updateStorageKTO(eid) {
        if (this.#hasUpdateStorage('KTO')) { this.removeStorageKTO() } this.setStorageKTO(eid); this.removeStorageKTO();
    }

    static updateStorageASP(eid) {
        if (this.#hasUpdateStorage('ASP')) { 
            this.removeStorageASP() 
        } 
        this.setStorageASP(eid); 
        this.removeStorageASP();
    }

    static updateStorageEID(eid) {
        if (this.#hasUpdateStorage('EID')) { this.removeStorageEID() } this.setStorageEID(eid); this.removeStorageEID();
    }

    static #hasUpdateStorage(key) {        
        const ls = localStorage.getItem('UPDATE'+key)
        if (ls) {
            return true           
        }
        return false;
    }

    static get listener() { return this.#listener }

    static #listener = []
}

export default UpdateHandler
