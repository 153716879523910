
export default class DoubleClickDetector 
{
    static eventTs = -1
    static clientX = -1
    static clientY = -1

    static mouseClickEvent(evt) {
        const ts = new Date().valueOf();
        let result = false
                   
        if (
            (ts - this.eventTs < 300) &&
            Math.abs(evt.clientY - this.clientY) < 2 &&
            Math.abs(evt.clientX - this.clientX) < 2
        ) 
        {
            result = true
        }        
    
        this.eventTs = ts
        this.clientX = evt.clientX
        this.clientY = evt.clientY
        return result;
    }

}
