
import React, { useState, useEffect } from 'react'
import MaterialTable, { MTableToolbar  } from 'material-table';
import { TablePagination, TablePaginationProps } from '@material-ui/core';

import { AiOutlineEdit, AiOutlineSearch, AiOutlineCloseCircle, AiOutlineUnorderedList, AiOutlineFileExcel } from 'react-icons/ai';
import { HiOutlineDocumentAdd } from 'react-icons/hi';
// import { GrView } from 'react-icons/gr';

import XLSX from 'xlsx';
import FileSaver from 'file-saver';

import Spinner from '../../components/shared/spinner/Spinner';
import ConfigIcon from '../../util/configicon';
import FormGroup from '../../components/shared/form-group';
import json2array, { toValidJsonObject, xls2ArrayBuffer } from '../../util/json2array';
import { toShortDateString, toShortDateTimeString } from '../../util/date-utils';
import { selectForStatusCombo } from '../../util/combo-utils';
import ContextMenu from '../../components/shared/context-menu/ContextMenu';
import DoubleClickDetector from '../../util/DoubleClickDetector';
import { materialTableOptions, materialTableLocalization } from '../../util/material-table-options';
import flat from 'flat';
import Dates from '../../util/Dates';
import { round } from '../../util/number-utils'
import { formatTeilnehmer } from '../../util/formate';

// import FetchService, { fetchService } from '../../services';

// export default class SearchMassnahme extends Component {

// 1 basierter Index der Excelspalten
// 1=A, 2=B, 26=Z, 27=AA, 52=AZ, 53=BA ...
function columnIndexToExcelIndex(colIndex)
{
    let col = ""
    if (colIndex > 26)
    {            
        col = String.fromCharCode(64 +  Math.floor((colIndex-1) / 26) )         
        col = col + String.fromCharCode(64 + 1 + (colIndex-1) % 26)               
    }
    else
    {
        col = String.fromCharCode(64 + colIndex)            
    }
    return col;
}

const SearchMassnahme = () =>
{   
    const cbc = 1; // checkbox columnspan
    const storageKey = "massnahmenSearchData";
    const storageValue = toValidJsonObject(localStorage.getItem(storageKey))    
    // console.log("LOAD  storageValue " + JSON.stringify(storageValue));
    const searchData = (storageValue ? storageValue : 
    {
        teilnehmerOhneBestaetigung : false,
        fehlerInDatumsbereichen : false,
        massnahmenOhneNachweis : false,
        fehlendeIBAN : false,
        teilnehmerOhneBeginn : false,
        fehlenderBearbeiter : false,
        nurOffenePosten : false,
        fehlendeKvZuordnung : false,
        massnahmenFuerSchliessung : false,
        ueberlappendeMassnahmen : false,
        startJahr : "",
        endJahr : "",
        laengerAls : "",
        kvBezirk : "",
        massnahmenId : null,
        teilnehmerId : null,
        nachname: null,
        vorname: null,
        ik : null,
        einrichtungOrId : null,
        ortOrPlz: null,
        status: "",
    })

    const [kvBezirkData, setkvBezirkData] = useState([{key:0, value:""}])
    const [massnahmenStatus, setMassnahmenStatus] = useState([{key:0, value:""}])

    const style = getComputedStyle(document.body)
    const fontSize = style.getPropertyValue('--labelfontscale')
    const menuFontSize = style.getPropertyValue('--menufontscale')
    // console.log("FontSize " + fontSize + " Menufontsize " + menuFontSize)

    // font-size : var(--inputfontscale);
    // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  

    const resultColumns = [
        {           
            title:'Teilnehmer',  field:'Teilnehmer',   
            cellStyle: { 'font-size' : fontSize, padding: '4px', width : '10%', whiteSpace: 'nowrap' }, 
            headerStyle: { 'font-size' : fontSize, padding: '4px', width : '10%' }, 
            width:'10%', minWidth:'10%',maxWidth:'10%'
        },
        { 
            title:'Einrichtung', field:'Einrichtung',  
            cellStyle: { 'font-size' : fontSize, padding: '4px', width : '50%', whiteSpace: 'nowrap'}, 
            headerStyle: { 'font-size' : fontSize, padding: '4px', width : '50%' }, 
            width:'50%', minWidth:'50%',maxWidth:'50%' 
        },
        { 
          title:'MID',         field:'MID',          
          cellStyle: { 'font-size' : fontSize, padding: '4px', width:'10%' },
          headerStyle: { 'font-size' : fontSize, padding: '4px'}
        },        
        { 
            title:'Von',         field:'Von',          
            cellStyle: { 'font-size' : fontSize, padding: '4px', width:'5%' } , 
            headerStyle: { 'font-size' : fontSize, padding: '4px' },
            type: "date" , render: rd => toShortDateString(rd.Von) 
        },
        { 
            title:'Bis',         field:'Bis',          
            cellStyle: { 'font-size' : fontSize, padding: '4px', width:'5%' }, 
            headerStyle: { 'font-size' : fontSize, padding: '4px'},
            type: "date" , render: rd => toShortDateString(rd.Bis) 
        },
        { 
            title:'Status',      field:'Status',       
            cellStyle: { 'font-size' : fontSize, padding: '4px', width:'5%' } ,
            headerStyle: { 'font-size' : fontSize, padding: '4px' }
        },
        { 
            title:'Fachgebiet',  field:'Fachgebiet',   
            cellStyle: { 'font-size' : fontSize, padding: '4px', width:'5%' } ,
            headerStyle: { 'font-size' : fontSize, padding: '4px' }
        },
        { 
            title:'Monate',      field:'Monate',
            cellStyle: { 'font-size' : fontSize, padding: '4px', width:'5%' } ,
            headerStyle: { 'font-size' : fontSize, padding: '4px' }
        },
        { 
            title:'Auszahlung',  field:'Auszahlung',   
            cellStyle: { 'font-size' : fontSize, padding: '4px', width:'5%' } , 
            headerStyle: { 'font-size' : fontSize, padding: '4px' }
        },
    ];
    // const resultColumnsMUI = [
    //     { label:'Teilnehmer',  name:'Teilnehmer',   width:'10%', minWidth:'10%',maxWidth:'10%' },
    //     { label:'Einrichtung', name:'Einrichtung',  width:'50%', minWidth:'50%',maxWidth:'50%' },
    //     { label:'MID',         name:'MID',          width:'10%' },        
    //     { label:'Von',         name:'Von',          width:'5%'  },
    //     { label:'Bis',         name:'Bis',          width:'5%'  },
    //     { label:'Status',      name:'Status',       width:'5%'  },
    //     { label:'Fachgebiet',  name:'Fachgebiet',   width:'5%'  },
    //     { label:'Monate',      name:'Monate',       width:'5%'  },
    //     { label:'Auszahlung',  name:'Auszahlung',   width:'5%'  },
    // ];
    const [resultData, setResultData] = useState([])
    const [rawData, setRawData] = useState([])

    const tableRef = React.createRef();

    useEffect(() => {
        async function asyncFetchKvForSelect()
        {
            const defaultError = [{
                key : 0,
                value: "",
            }]
            try {
                const response = await fetch('/data/kv/select')
                if (response.ok) {
                    const result = await response.json();    
                    if (Array.isArray(result))
                    {
                        setkvBezirkData(result)
                        return;
                    }
                    defaultError[0].value = "E:" + result.name
                    throw new Error(result.name)                                    
                }
                else
                {
                    defaultError[0].value = "E:" + response.status
                    throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                setkvBezirkData(defaultError)
                console.log(err)
            }
        }        
        asyncFetchKvForSelect()
    }, [])

    useEffect(() => {
        async function asyncFetchMassnahmenStatusForSelect()
        {            
            setMassnahmenStatus(await selectForStatusCombo())           
        }
        asyncFetchMassnahmenStatusForSelect()
    }, [])

    const [teilnehmerOhneBestaetigung, setTeilnehmerOhneBestaetigung] = useState(searchData.teilnehmerOhneBestaetigung)
    const [fehlerInDatumsbereichen, setFehlerInDatumsbereichen] = useState(searchData.fehlerInDatumsbereichen)
    const [massnahmenOhneNachweis, setMassnahmenOhneNachweis] = useState(searchData.massnahmenOhneNachweis)
    const [fehlendeIBAN, setFehlendeIBAN] = useState(searchData.fehlendeIBAN)
    const [teilnehmerOhneBeginn, setTeilnehmerOhneBeginn] = useState(searchData.teilnehmerOhneBeginn)
    const [fehlenderBearbeiter, setFehlenderBearbeiter] = useState(searchData.fehlenderBearbeiter)
    const [nurOffenePosten, setNurOffenePosten] = useState(searchData.nurOffenePosten)
    const [fehlendeKvZuordnung, setFehlendeKvZuordnung] = useState(searchData.fehlendeKvZuordnung)
    const [massnahmenFuerSchliessung, setMassnahmenFuerSchliessung] = useState(searchData.massnahmenFuerSchliessung)
    const [ueberlappendeMassnahmen, setUeberlappendeMassnahmen] = useState(searchData.ueberlappendeMassnahmen)
    const [startJahr,setStartJahr] = useState(searchData.startJahr)
    const [endJahr,setEndJahr] = useState(searchData.endJahr)
    const [laengerAls, setLaengerAls] = useState(searchData.laengerAls)
    const [kvBezirk, setKvBezirk] = useState(searchData.kvBezirk)
    const [ortOrPlz, setOrtOrPlz] = useState(searchData.ortOrPlz)
    const [massnahmenId, setMassnahmenId] = useState(searchData.massnahmenId)
    const [teilnehmerId, setTeilnehmerId] = useState(searchData.teilnehmerId)
    const [nachname, setNachname] = useState(searchData.nachname)
    const [vorname, setVorname] = useState(searchData.vorname)
    const [ik, setIk] = useState(searchData.ik)
    const [einrichtungOrId, setEinrichtungOrId] = useState(searchData.einrichtungOrId)
    const [status, setStatus] = useState(searchData.status)
    const [selectedRow, setSelectedRow] = useState(null)
    const [buttonState, setButtonState] = useState(true)
    const [loadedState, setLoadState] = useState(0)    

    const sucheClear = () => {
        setTeilnehmerOhneBestaetigung(false)
        setFehlerInDatumsbereichen(false)
        setMassnahmenOhneNachweis(false)
        setFehlendeIBAN(false)
        setTeilnehmerOhneBeginn(false)
        setFehlenderBearbeiter(false)
        setNurOffenePosten(false)
        setFehlendeKvZuordnung(false)
        setMassnahmenFuerSchliessung(false)        
        setUeberlappendeMassnahmen(false)

        setMassnahmenId(null)         
        setTeilnehmerId(null)         
        setNachname(null)         
        setVorname(null)         
        setIk(null)         
        setEinrichtungOrId(null)         
        setOrtOrPlz(null)         
        setStatus(null)   
        setKvBezirk(null)      
        setLaengerAls("")
        setStartJahr("")
        setEndJahr("")
        setResultData( [] );
        localStorage.setItem(storageKey, null); 
        setButtonState(true)
    }
        
    const sucheMassnahme = async() =>
    {        
        searchData.massnahmenId = massnahmenId
        searchData.teilnehmerId = teilnehmerId
        searchData.nachname = nachname
        searchData.vorname = vorname
        searchData.ik = ik        
        searchData.einrichtungOrId = einrichtungOrId
        searchData.ortOrPlz = ortOrPlz
        searchData.status = status
        searchData.kvBezirk = kvBezirk
        searchData.laengerAls = laengerAls
        searchData.startJahr = startJahr
        searchData.endJahr = endJahr

        searchData.teilnehmerOhneBestaetigung = teilnehmerOhneBestaetigung
        searchData.fehlerInDatumsbereichen = fehlerInDatumsbereichen
        searchData.massnahmenOhneNachweis = massnahmenOhneNachweis
        searchData.fehlendeIBAN = fehlendeIBAN
        searchData.teilnehmerOhneBeginn = teilnehmerOhneBeginn
        searchData.fehlenderBearbeiter = fehlenderBearbeiter
        searchData.nurOffenePosten = nurOffenePosten
        searchData.fehlendeKvZuordnung = fehlendeKvZuordnung
        searchData.massnahmenFuerSchliessung = massnahmenFuerSchliessung        
        searchData.ueberlappendeMassnahmen = ueberlappendeMassnahmen

        const sv = JSON.stringify(searchData);        
        localStorage.setItem(storageKey, sv); 
        
        setRawData([])
        setLoadState(2)

        try
        {
            const res = await fetch('/data/massnahmen/search/data=' + sv, {  method: 'POST' })                        
            if (res.ok)
            {
                const json = await res.json()
                if (Array.isArray(json))
                {                    
                    let mySource = json2array(json); 
                    setRawData(mySource)
                    // console.log(mySource[0])
                    // console.log("mySource.length " + mySource.length)                    
                    // console.log('moment ' + moment.locales())           
                    let myResult = [];
                    for (let num=0; num < mySource.length; num++)
                    { 
                        // if (num === 0)
                        //     console.log(mySource[num]) 
                        const proz = mySource[num].Prozentual ? (mySource[num].Prozentual/100)  : 1.0;   
                        const vonDate = new Date(mySource[num].Von);
                        const bisDate = new Date(mySource[num].Bis);
                        const auszahlung = mySource[num].Auszahlvermerk;
                        myResult.push({
                            Teilnehmer: formatTeilnehmer(mySource[num].Teilnehmer, "nvgd"),
                            // mySource[num].Teilnehmer.Nachname + ", " + mySource[num].Teilnehmer.Vorname + ", (" + mySource[num].Teilnehmer.GeburtsDatum + ")",
                            Einrichtung: mySource[num].Einrichtungen.Kurzbezeichnung, 
                            MID: mySource[num].Id,
                            Von: mySource[num].Von, // toShortDateString(mySource[num].f_von) ,  // vonDate.toLocaleDateString("de-DE", dateOptions),
                            Bis: mySource[num].Bis, // toShortDateString(mySource[num].f_bis) ,  // bisDate.toLocaleDateString("de-DE", dateOptions),
                            Status: mySource[num].Massnahmenstatus.bezeichnung, 
                            Fachgebiet: mySource[num].Fachgebiete.bezeichnung,
                            Monate: round(Dates.monthDiff(vonDate, bisDate) * proz, 2) ,
                            Auszahlung: (auszahlung) ? auszahlung : "", 
                            EID: mySource[num].Einrichtungen.Id,
                            TID: mySource[num].Teilnehmer.Id
                        })
                    }
                    // console.log("myResult.length " + myResult.length)               
                    // console.log("myResult " + JSON.stringify(myResult));     
                    setResultData(myResult);          
                }
                else
                {               
                    throw new Error("unexpected result " + JSON.stringify(json))
                }                
            }
            else
            {
                throw new Error(res.status + " " + res.statusText)
            }
        }
        catch (err)
        {
            console.log("catch " + err)       
        }
        setLoadState(1)


        // fetch('/data/massnahmen/search/data=' + sv, {
        //     method: 'POST'
        // })
        // .then( (res) => {
        //     console.log("then " + res)
        //     if (res.ok) {
        //         return res.json()
        //     }
        //     throw new Error(res.status + " " + res.statusText)
        // })
        // .then( (json) => {
        //     if (Array.isArray(json))
        //     {
        //         // console.log("then json " + JSON.stringify(json))
                
        //         let mySource = json2array(json); 
        //         setRawData(mySource)
        //         // console.log(mySource[0])
        //         // console.log("mySource.length " + mySource.length)                    
        //         // console.log('moment ' + moment.locales())           
        //         let myResult = [];
        //         for (let num=0; num < mySource.length; num++)
        //         {        
        //             const vonDate = new Date(mySource[num].Von);
        //             const bisDate = new Date(mySource[num].Bis);
        //             const auszahlung = mySource[num].Auszahlvermerk;
        //             myResult.push({
        //                 Teilnehmer: mySource[num].Teilnehmer.Nachname + "," + mySource[num].Teilnehmer.Vorname,
        //                 Einrichtung: mySource[num].Einrichtungen.Kurzbezeichnung, 
        //                 MID: mySource[num].Id,
        //                 Von: mySource[num].Von,  // toShortDateString(mySource[num].f_von) ,  // vonDate.toLocaleDateString("de-DE", dateOptions),
        //                 Bis: mySource[num].Bis, // toShortDateString(mySource[num].f_bis) ,  // bisDate.toLocaleDateString("de-DE", dateOptions),
        //                 Status: mySource[num].Massnahmenstatus.kurzbezeichnung, 
        //                 Fachgebiet: mySource[num].Fachgebiete.bezeichnung,
        //                 Monate: monthDiff(vonDate, bisDate), 
        //                 Auszahlung: (auszahlung) ? auszahlung : "", 
        //                 EID: mySource[num].Einrichtungen.Id,
        //                 TID: mySource[num].Teilnehmer.Id
        //             })
        //         }
        //         // console.log("myResult.length " + myResult.length)               
        //         // console.log("myResult " + JSON.stringify(myResult));     
        //         setResultData(myResult);          
        //     }
        //     else
        //     {               
        //         throw new Error("unexpected result")
        //     }
        // })
        // .catch((err) => {
        //     console.log("catch " + err)                
        // })
    }

    const teilnehmerOverview = () =>  {
        if ((selectedRow) || Number.isInteger(selectedRow))  {   
        //     console.log("myResult[idx] " + resultData[selectedRow])
        //     console.log("Massnahmen  ID " + resultData[selectedRow].MID)
            // console.log("Einrichtung ID " + resultData[selectedRow].EID)
            // console.log("Teilnehmer  ID " + resultData[selectedRow].TID)   	                
            window.location.href = "/Teilnehmer/Overview?tid="+resultData[selectedRow].TID + "&eid=" + resultData[selectedRow].EID
        }
    }

    const teilnehmerEdit = () => {
        if ((selectedRow) || Number.isInteger(selectedRow))  {  
            // console.log(resultData[selectedRow].Teilnehmer) 
            const tid = resultData[selectedRow].TID
            // window.open("/Teilnehmer/Edit?tid="+tid, "TID" + tid)
            window.location.href = "/Teilnehmer/Edit?tid="+tid
        }
    }

    const einrichtungEdit = () => {
        if ((selectedRow) || Number.isInteger(selectedRow))  {             
            const eid = resultData[selectedRow].EID
            // window.open("/Einrichtung/Edit?eid="+eid, "EID" + eid)
            window.location.href = "/Einrichtung/Edit?eid="+eid            
        }
    }

    const massnahmeEdit = () => {          
        if ((selectedRow) || Number.isInteger(selectedRow))  {
            const mid = resultData[selectedRow].MID
            // window.open("/Massnahme/Edit?mid="+mid, "MID" + mid)
            window.location.href = "/Massnahme/Edit?mid="+mid
        }
    }

    const massnahmeNeu = () => {
        window.location.href = "/Massnahme/New"
    }

    const exportList = () => {
        
        let wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "SheetJS Tutorial",
            Subject: "Test",
            Author: "Ich",
            CreatedDate: new Date()
        };
        wb.SheetNames.push("Test Sheet")     
        // console.log(resultData)   
        let ws = XLSX.utils.json_to_sheet(resultData)
        // let ws = XLSX.utils.aoa_to_sheet(ws_data);
        wb.Sheets["Test Sheet"] = ws;
        let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});              

        const data = new Blob(xls2ArrayBuffer(wbout) ,{ type:"application/octet-stream" });
        FileSaver.saveAs( data, 'test.xlsx');     
    }

    const exportExcel = () => {

        // const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        // const sheet = XLSX.utils.json_to_sheet(rawData),
        //     workbook = { Sheets: { 'Plan Information': sheet }, SheetNames: ['Plan Information'] },
        //     excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        //     const data = new Blob([excelBuffer], {type: fileType});
        // FileSaver.saveAs(data, 'test.xlsx');


        let wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "SheetJS Tutorial",
            Subject: "Test",
            Author: "Ich",
            CreatedDate: new Date()
        };
        wb.SheetNames.push("Test Sheet")             
        // console.log(resultData)          
        const rd = []
        let columnCount = 0
        rawData.forEach( (elem) => {
            const obj = flat.flatten(elem)  
            
            obj.Von = new Date(obj.Von) // toShortDateString(obj.Von)
            obj.Bis = new Date(obj.Bis) // toShortDateString(obj.Bis)            
            obj.Eingang_antrag = toShortDateString(obj.Eingang_antrag)
            
            obj.Ausgang_bestaet = toShortDateString(obj.Ausgang_bestaet)
            obj.Nachweis_bearbeitet_am = toShortDateString(obj.Nachweis_bearbeitet_am)
            obj.nachweis_gedruckt = toShortDateString(obj.nachweis_gedruckt)
            obj.Ausgezahlt_am = toShortDateString(obj.Ausgezahlt_am)
            obj.LetzteAenderung = toShortDateTimeString(obj.LetzteAenderung)

            if (elem.Teilnehmer) {
                obj['Teilnehmer.geburtsDatum'] = toShortDateString(obj['Teilnehmer.geburtsDatum'])
            }
            if (elem.Beschaeftigungen) {
                // console.log(elem.Beschaeftigungen)
                obj['Beschaeftigungen.BeginnBeschaeftigung'] = toShortDateString(obj['Beschaeftigungen.BeginnBeschaeftigung'])
                obj['Beschaeftigungen.DatumLetzteBestaetigung'] = toShortDateString(obj['Beschaeftigungen.DatumLetzteBestaetigung'])
            }
            if (elem.Kuerzungen) {
                // console.log(elem.Kuerzungen)
                obj['Kuerzungen.A_Von'] = toShortDateString(obj['Kuerzungen.A_Von'])
                obj['Kuerzungen.A_Bis'] = toShortDateString(obj['Kuerzungen.A_Bis'])
            }

            delete obj.Verrechnungen
            delete obj.KuerzungenNachZahlung
            delete obj.Kuerzungen
            delete obj.Ansprechpartner
            delete obj.Auszahlungen

            rd.push(obj)
            columnCount = Math.max(columnCount, Object.keys(obj).length)           
        })  
        // console.log("columnsCount " +       columnCount)
        let ws = XLSX.utils.json_to_sheet(rd)
        
        // let ws = XLSX.utils.aoa_to_sheet(ws_data);
        wb.Sheets["Test Sheet"] = ws;
        let col = columnIndexToExcelIndex(columnCount)
        
        // console.log( "column " + col )     

        ws['!autofilter'] = { ref: "A1:" + col + "1" }

        let cols = []
        for (let num=0; num<columnCount; num++) {
            let width = 10;
            let cr = columnIndexToExcelIndex(num+1)
            // console.log(ws[cr+(rawData.length+1)])
            for (let rc=0; rc<=rawData.length; rc++)
            {
                let cellIndex = cr + (rc+1)
                // console.log(cellIndex)
                let cell = ws[cellIndex]
                if (cell) 
                {                        
                    if (cell.t === 'n') {
                        width = Math.max(width, 10)
                    } else if (cell.t === 's') {
                        width = Math.max(width, cell.v.length)
                    }                    
                }
                else {
                    // console.log("no cell at index " + cellIndex)
                }
            }
            cols.push( {wch:width} )
        }

        ws['!cols'] = cols

        // console.log(wb)
        // console.log("rows " + rawData.length)
        // console.log(ws['A1'])
        // console.log(ws['A2'])
        // console.log(ws['B2'])
        // console.log(ws['C2'])
        // console.log(ws['!cols'])
        // console.log(ws['!rows'])
        // console.log(ws['!type'])

        // ws['!cols'].forEach( (columnInfo, index ) => {
        //      let maxLength = 0;
        //      columnInfo["eachCell"]({ includeEmpty: true }, function (cell) 
        //      {
        //          let columnLength = cell.value ? cell.value.toString().length : 10;
        //          if (columnLength > maxLength ) {
        //              maxLength = columnLength;
        //          }
        //      });
        //      columnInfo.width = maxLength < 10 ? 10 : maxLength;
        // })
        
        

        let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});          

        const data = new Blob(xls2ArrayBuffer(wbout) ,{ type:"application/octet-stream" });
        FileSaver.saveAs( data, 'test.xlsx');     
    }

    const contextMenuClick = (key) => {        
        // console.log("contextClick " + key + " selectedrow " + selectedRow)
        switch (key)
        {
            case '1':
                massnahmeEdit()
                break;
            case '2':
                einrichtungEdit()
                break;
            case '3':
                teilnehmerEdit()
                break;
            case '4':
                teilnehmerOverview()
                break;
            default:
                console.log("Unknown Menu-ID")            
        }
    }

    const contextMenuItems = [
        { title : 'Maßnahme bearbeiten' , key : 1},
        { title : 'Einrichtung bearbeiten' , key : 2},
        { title : 'Teilnehmer bearbeiten' , key : 3},
        { title : 'Maßnahmen des Teilnehmers', key:4}
    ];

    return (
        <div>
            <div> {/* className="sm_table_div">          */}
                <table className='sm_table_90'>
                    <thead>
                        <tr className="table-blank-row"></tr>         
                        <tr>
                            <th colSpan="6"><h3>Ma&szlig;nahme suchen</h3></th><th></th><th></th><th></th>
                        </tr>
                    </thead>
                    <tbody>  
                        <tr className="table-blank-row"></tr>                      
                        <tr className='tr_scale'>
                            <td className="td_label">Maßnahmen ID:</td> {/*-- width="120" */}
                            <td className="td_input"> {/*  width="180" */}
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="massnahmenId"                             
                                    value={massnahmenId} 
                                    onChange={setMassnahmenId}                                    
                                />
                            </td>
                            <td className="td_label">IK:</td> {/* width="120" */}
                            <td className="td_input">    {/*  width="150" */}
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="ik"                             
                                    value={ik} 
                                    onChange={setIk} 
                                />
                            </td>
                            <td className="td_check" colSpan={cbc}>  {/*  width={240} */}
                                <FormGroup 
                                    
                                    type="checkbox" label=" Nur Teilnehmer ohne Bestätigung" 
                                    value={teilnehmerOhneBestaetigung} 
                                    onChange={setTeilnehmerOhneBestaetigung} 
                                    name='id-tno'
                                />
                            </td>
                            {/* <td></td> */}
                            <td className="td_check"> {/*  width={180} */}
                                <FormGroup 
                                    type="checkbox" label=" Fehler in Datumsbereichen" 
                                    value={fehlerInDatumsbereichen} 
                                    onChange={setFehlerInDatumsbereichen} 
                                    name='id-fid'
                                />
                            </td>
                            {/* <td></td> */}
                        </tr>
                        <tr className='tr_scale'>
                            <td className="td_label">Status:</td>
                            <td className="td_select">
                                <FormGroup
                                    type="select"
                                    name="status" 
                                    options={massnahmenStatus}
                                    value={status} 
                                    onChange={setStatus}
                                />
                            </td>    
                            <td className="td_label">Einrichtung oder EID:</td>
                            <td className="td_input">    
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="einrichtungOrId"                             
                                    value={einrichtungOrId} 
                                    onChange={setEinrichtungOrId} 
                                />
                            </td>                          
                            <td className="td_check" colSpan={cbc} >
                                <FormGroup 
                                    type="checkbox" 
                                    label=" Nur Maßnahmen ohne Nachweis" 
                                    value={massnahmenOhneNachweis} 
                                    onChange={setMassnahmenOhneNachweis} 
                                    name='id-fmon'
                                />
                            </td>
                            {/* <td></td> */}
                            <td className="td_check">
                                <FormGroup 
                                    type="checkbox" 
                                    label=" Fehlende oder ungültige IBAN" 
                                    value={fehlendeIBAN} 
                                    onChange={setFehlendeIBAN} 
                                    name='id-fiban'
                                />
                            </td>
                            {/* <td></td> */}
                        </tr>
                        <tr className='tr_scale'>
                            <td className="td_label">Teilnehmer ID:</td>
                            <td className="td_input">    
                               <FormGroup 
                                    type="text"
                                    label=""
                                    name="teilnehmerId"                             
                                    value={teilnehmerId} 
                                    onChange={setTeilnehmerId} 
                                />
                            </td>
                            <td className="td_label">Ort oder PLZ:</td>
                            <td className="td_input">    
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="ortOrPlz"                             
                                    value={ortOrPlz} 
                                    onChange={setOrtOrPlz} 
                                />
                            </td>
                            <td className="td_check" colSpan={cbc}>
                                <FormGroup 
                                    type="checkbox" 
                                    label=" Nur Teilnehmer ohne Beschäftigungsbeginn"
                                    value={teilnehmerOhneBeginn} 
                                    onChange={setTeilnehmerOhneBeginn} 
                                    name='id-ftob'
                                />
                            </td>
                            {/* <td></td> */}
                            <td className="td_check">
                                <FormGroup 
                                    type="checkbox" 
                                    label=" Fehlender Bearbeiter"
                                    value={fehlenderBearbeiter} 
                                    onChange={setFehlenderBearbeiter} 
                                    name='id-fbear'
                                />
                            </td>
                            {/* <td></td> */}
                        </tr>
                        <tr className='tr_scale'>
                        <td className="td_label">Nachname:</td>
                            <td className="td_input">    
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="nachname"                             
                                    value={nachname} 
                                    onChange={setNachname} 
                                />
                            </td>
                            
                            <td className="td_label">KV Bezirk:</td>
                            <td className="td_select">    
                                <FormGroup 
                                    type="select"
                                    label=""
                                    name="kvBezirk" 
                                    options={kvBezirkData}                            
                                    value={kvBezirk} 
                                    onChange={setKvBezirk} 
                                />
                            </td>
                            
                            <td className="td_check" colSpan={cbc}>
                                <FormGroup 
                                    type="checkbox" 
                                    label=" Nur offene Posten" 
                                    value={nurOffenePosten}
                                    onChange={setNurOffenePosten} 
                                    name='id-fnop'
                                />                                    
                            </td>
                            {/* <td></td> */}
                            <td className="td_check">
                                <FormGroup 
                                    type="checkbox" 
                                    label=" Fehlende KV-Zuordnung" 
                                    value={fehlendeKvZuordnung} 
                                    onChange={setFehlendeKvZuordnung} 
                                    name='id-fkvz'
                                />
                            </td>
                            {/* <td></td> */}
                        </tr>
                        <tr className='tr_scale'>
                            <td className="td_label">Vorname:</td>
                            <td className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="vorname"                             
                                    value={vorname} 
                                    onChange={setVorname} 
                                />
                            </td>
                            <td></td>
                            <td></td>
                            <td className="td_check" colSpan={cbc}>
                                <FormGroup 
                                    type="checkbox" 
                                    label=" Nur Maßnahmen für Schließung" 
                                    value={massnahmenFuerSchliessung} 
                                    onChange={setMassnahmenFuerSchliessung} 
                                    name='id-fmfs'
                                />
                            </td>
                            {/* <td></td> */}
                            <td className="td_check">
                                <FormGroup 
                                    type="checkbox" 
                                    label=" Überlappende Maßnahmen" 
                                    value={ueberlappendeMassnahmen} 
                                    onChange={setUeberlappendeMassnahmen} 
                                    name='id-fuebm'
                                />
                            </td>
                            {/* <td></td> */}
                        </tr>
                        <tr className='tr_scale'>
                            <td className="td_label">Teilnehmer mehr als:</td>
                            <td className="td_input">                                
                                <FormGroup               
                                    type="text"
                                    label=""
                                    name="laengerAls"                             
                                    value={laengerAls} 
                                    onChange={setLaengerAls} 
                                />                            
                            </td>
                            <td className="td_label_left">Monate</td>                            
                            <td className="td_label">In den Jahren:</td>
                            <td colSpan={2}>
                                <table> {/* className='sm_table'> */}
                                    <tbody>
                                    <tr>                            
                                        <td className="td_input">   
                                            <FormGroup
                                                type="text" 
                                                label="" 
                                                value={startJahr} 
                                                onChange={setStartJahr}         
                                            />        
                                        </td>
                                        <td className="td_label">Bis:</td>
                                        <td className="td_input">
                                        <FormGroup 
                                                type="text" 
                                                label="" 
                                                value={endJahr} 
                                                onChange={setEndJahr} 
                                            />        
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                            {/* <td></td> */}
                            {/* <td></td>                             */}
                        </tr>
                        <tr className="table-blank-row"></tr>
                        <tr>
                            <td colSpan="7">
                                <table className="sm_table_div">
                                    <tbody>
                                    <tr>
                                        <td className="td_button">
                                            {/* <button onClick={sucheMassnahme}>Suche starten</button> */}
                                            <button className="exportbtn" title="Starten der Suche" onClick={sucheMassnahme}>
                                                <ConfigIcon><AiOutlineSearch/></ConfigIcon>&nbsp;Suche starten
                                            </button>
                                        </td>
                                        <td className="td_button">
                                            {/* <button onClick={sucheClear}>Suche l&ouml;schen</button> */}
                                            <button className="exportbtn" title="Löschen aller Suchfelder" onClick={sucheClear}>
                                                <ConfigIcon><AiOutlineCloseCircle/></ConfigIcon>&nbsp;Suche l&ouml;schen
                                            </button>
                                        </td>
                                        <td className="td_button">
                                            {/* <button disabled={buttonState} onClick={teilnehmerOverview}>Maßnahmen des Teilnehmers</button> */}
                                            <button disabled={buttonState} className="exportbtn" onClick={teilnehmerOverview}>
                                                <ConfigIcon><AiOutlineUnorderedList/></ConfigIcon>&nbsp;Maßnahmen des Teilnehmers
                                            </button>
                                        </td>
                                        <td className="td_button">
                                            {/* <button disabled={buttonState}  onClick={teilnehmerEdit}>Teilnehmer bearbeiten</button> */}
                                            <button disabled={buttonState} className="exportbtn" title="Teilnehmer bearbeiten (den gewählten)" onClick={teilnehmerEdit}>
                                                <ConfigIcon><AiOutlineEdit/></ConfigIcon>&nbsp;Teilnehmer bearbeiten
                                            </button>
                                        </td>
                                        <td className="td_button">
                                            {/* <button disabled={buttonState}  onClick={einrichtungEdit}>Einrichtung bearbeiten</button> */}
                                            <button disabled={buttonState} className="exportbtn" title="Einrichtung bearbeiten (die gewählte)" onClick={einrichtungEdit}>
                                                <ConfigIcon><AiOutlineEdit/></ConfigIcon>&nbsp;Einrichtung bearbeiten
                                            </button>
                                        </td>
                                        <td className="td_button">
                                            {/* <button disabled={buttonState} onClick={massnahmeEdit}>Maßnahme bearbeiten</button> */}
                                            <button disabled={buttonState} className="exportbtn" title="Maßnahme bearbeiten (die gewählte)" onClick={massnahmeEdit}>
                                                <ConfigIcon><AiOutlineEdit/></ConfigIcon>&nbsp;Maßnahme bearbeiten
                                            </button>
                                        </td>
                                        <td className="td_button">
                                            {/* <button onClick={massnahmeNeu}></button> */}
                                            <button className="exportbtn" onClick={massnahmeNeu}>
                                                <ConfigIcon><HiOutlineDocumentAdd/></ConfigIcon>&nbsp;Neue Ma&szlig;nahme
                                            </button>
                                        </td>
                                        <td className="td_button">
                                        <button disabled={resultData.length === 0} className="exportbtn" onClick={exportList}>
                                                <ConfigIcon><AiOutlineFileExcel/></ConfigIcon>&nbsp;Listen Export
                                            </button>

                                        </td>
                                        <td className="td_button">                                            
                                            <button disabled={rawData.length === 0} className="exportbtn" onClick={exportExcel}>
                                                <ConfigIcon><AiOutlineFileExcel/></ConfigIcon>&nbsp;Excel Export
                                            </button>
                                        </td>                                                        
                                    </tr>
                                    </tbody>
                                </table>
                            </td>                            
                            {/* <td></td>                             */}
                        </tr>

                        <tr className="table-blank-row"></tr>                        
                    </tbody>
                </table>
            </div>

            {loadedState===1 && <>
            <div>
                <ContextMenu eventhandler={contextMenuClick} menuitems={contextMenuItems}></ContextMenu>
            </div>
    
            <div onContextMenu={ (evt) => 
            {                
                const attrElement = evt.target.parentElement.attributes['index']
                if (attrElement)
                {
                    evt.preventDefault()
                    const idx = attrElement.value
                    // console.log("INDEX = " + idx)       
                    // evt.target = cell 
                    // evt.target.parentElement = row
                    // evt.target.parentElement.parentElement = table
                    const table = evt.target.parentElement.parentElement;
                    const rows = table.getElementsByClassName("MuiTableRow-root MuiTableRow-hover")
                    Array.prototype.forEach.call(rows, (r) => {
                        if (r.attributes['index'].value === idx)
                        {                                                        
                            r.click()
                        }
                        else
                        {
                            r.style["backgroundColor"] = "#FFF";
                        }                    
                    })
                    const ctxMenu = document.getElementById("context-menu");   
                    ctxMenu.style.top = evt.clientY + "px";
                    ctxMenu.style.left = evt.clientX + "px";
                    ctxMenu.classList.add("active");                    
                }
            }}>            
                <MaterialTable
                    components={
                        {
                        Pagination: PatchedPagination
                        }
                        // {
                        // Toolbar: props => (
                        //     <div style={{ 'font-size' : menuFontSize }}> // !important
                        //         <MTableToolbar {...props}  />
                        //     </div>
                        // )
                        // }
                    }
                    tableRef={tableRef}
                    columns={resultColumns} 
                    data={resultData} 
                    title={'Maßnahmen ('  + resultData.length + ')'}         
                    localization= {
                        materialTableLocalization()
                    }                                  
                    
                    options = {                    
                        Object.assign(                        
                            {       
                                searchFieldStyle : { 'font-size' : menuFontSize},                                                    
                                rowStyle: rowData => ({ 
                                backgroundColor:selectedRow === rowData.tableData.id ? "#bbb" : ""
                                }),
                            },
                            materialTableOptions()                     
                        )
                        // https://material-table.com/#/docs/all-props
                    }
                    // style =  {{'font-size': '0.8em'}}
                    
                    onRowSelected= { (evt, path, dataclicked) => {
                        console.log("onRowSelected")
                    }}        
                    onSelectionChange={ (dataClicked) => {
                        console.log("onSelectionChange")
                    }}   

                    onRowClick={ (evt, selectedRow) => {                       
                        setSelectedRow(selectedRow.tableData.id);
                        setButtonState(false)
                        if (DoubleClickDetector.mouseClickEvent(evt)) {
                            massnahmeEdit();
                        }
                    }}   
                    
                    onRowsPerPageChange={ (evt) => {
                    }}

                    onPageChange = { (evt) => {
                    }
                }/>        
            </div>
            </>
            }
            { loadedState===2 && <Spinner /> }
        </div>
    )
}



function monthDiff(dateFrom, dateTo) {
    // return dateTo.getMonth() - dateFrom.getMonth() + 
    //   (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))

      const diffTime = Math.abs(dateTo - dateFrom);
      console.log("diffTime   " + diffTime)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; 
      console.log("diffDays   " + diffDays)
      const diffMonth = diffDays/30
      console.log("diffMonths " + diffMonth)
      const result = Math.round(diffMonth * 100) / 100
      console.log("result     " + result)      
      return result 

      // return Math.floor((diffDays/30.4) + 0.1)
}

export default SearchMassnahme; 


// For those who can't wait for this PR to be released, I wrote a small TablePagination patch.

// import MaterialTable, { MaterialTableProps } from 'material-table';
// import { TablePagination, TablePaginationProps } from '@material-ui/core';

function PatchedPagination(props: TablePaginationProps) {
  const {
    ActionsComponent,
    onChangePage,
    onChangeRowsPerPage,
    ...tablePaginationProps
  } = props;

  return (
    <TablePagination
      {...tablePaginationProps}
      // @ts-expect-error onChangePage was renamed to onPageChange
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={(subprops) => {
        const { onPageChange, ...actionsComponentProps } = subprops;
        return (
          // @ts-expect-error ActionsComponent is provided by material-table
          <ActionsComponent
            {...actionsComponentProps}
            onChangePage={onPageChange}
          />
        );
      }}
    />
  );
}
// Then you can use this like:

// <MaterialTable
//   components={{
//     Pagination: PatchedPagination,
//   }}
// />