
export default class StorageService 
{

    static ZahlungsLaufJahr_KeyId = "ZahlungsLaufJahr";
    static ZahlungsLaufNumber_KeyId = "ZahlungsLaufNumber";

    static IbanErrorCount_KeyId = "IbanErrorCount";
    static AspErrorCount_KeyId = "AspErrorCount";
    static NotPrintedErrorCount_KeyId = "NotPrintedErrorCount";
    static StornoCount_KeyId = "StornoCount";

    static TeilnehmerSearchData = "teilnehmerSearchData"
    static TeilnehmerData = "teilnehmerData"

    static TeilnehmerEinrichtungsData = "teilnehmerEinrichtungsData";

    // static MassnahmeNewData = "massnahmeNewData";
    // static Einrichtungen_KeyId = "EinrichtungsID";
    // static Teilnehmer_KeyId = "Teilnehmer_KeyId";
    // static getEinrichtungenId() { return localStorage.getItem(this.Einrichtungen_KeyId) }     
    // static setEinrichtungenId(eid) { localStorage.setItem(this.Einrichtungen_KeyId, eid) }
    // static getAndClearEinrichtungenId() {
    //     const res = this.getEinrichtungenId()
    //     this.setEinrichtungenId(null)
    //     return res
    // }
    // static getTeilnehmerId() { return localStorage.getItem(this.Teilnehmer_KeyId) } 
    // static setTeilnehmerId(tid) { localStorage.setItem(this.Teilnehmer_KeyId, tid) }

    static updateMptData( sdata ) {
        if (!sdata || (!sdata.tid && !sdata.eid)) {
            localStorage.removeItem("MPT")
            return;
        }        
        const idata = this.getMptData()
        idata.tid = sdata.tid ?? idata.tid;
        idata.eid = sdata.eid ?? idata.eid;
        localStorage.setItem("MPT", JSON.stringify(idata))
    }

    static getMptData () {
        const result = localStorage.getItem("MPT")
        if (!result) {
            return { tid:null, eid: null}
        }
        return JSON.parse(result)
    }    

    static getZahlungsLaufJahr() { return localStorage.getItem(this.ZahlungsLaufJahr_KeyId)  }   
    static getZahlungsLaufNumber() { return localStorage.getItem(this.ZahlungsLaufNumber_KeyId) }
    static getIbanErrorCount() { return localStorage.getItem(this.IbanErrorCount_KeyId) }
    static getAspErrorCount() { return localStorage.getItem(this.AspErrorCount_KeyId) }
    static getNotPrintedErrorCount() { return localStorage.getItem(this.NotPrintedErrorCount_KeyId) }
    static getStornoCount() { return localStorage.getItem(this.StornoCount_KeyId) }


    static setZahlungsLaufNumber(value) { localStorage.setItem(this.ZahlungsLaufNumber_KeyId, value)  }
    static setZahlungsLaufJahr(value) { localStorage.setItem(this.ZahlungsLaufJahr_KeyId, value) }
    static setIbanErrorCount(value) { localStorage.setItem(this.IbanErrorCount_KeyId, value) }    
    static setAspErrorCount(value) { localStorage.setItem(this.AspErrorCount_KeyId, value) }
    static setNotPrintedErrorCount(value) { localStorage.setItem(this.NotPrintedErrorCount_KeyId, value) }
    static setStornoCount(value) { localStorage.setItem(this.StornoCount_KeyId, value) }

    static resetZahlungsLaufdata() {
        this.setZahlungsLaufNumber(0) 
        this.setZahlungsLaufJahr(0)
        this.setIbanErrorCount(0)
        this.setAspErrorCount(0)
        this.setNotPrintedErrorCount(0)
        this.setStornoCount(0)
    }        
}
