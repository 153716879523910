// import { singletonHook } from 'react-singleton-hook';

class AuthenticationService
{
    currentUserKey = 'currentUser';
    static instance = null;
    currentUser = this.emptyUser(); 

    constructor() {   
        const usr = this.getUserFromStorage();
        if (usr) {
            // console.log("Load user from Storage " + JSON.stringify(usr));
            this.currentUser = usr;        
        }             
        else
        {
            console.log("No user found in storage")
        }
    }

    static getInstance()
    {
        if (!AuthenticationService.instance) {
            // console.log("Use new instance")
            AuthenticationService.instance = new AuthenticationService();
            return AuthenticationService.instance;
        }
        // console.log("Use instance");
        return AuthenticationService.instance;
    }

    get loggedIn() {
        return this.currentUser.loggedIn;
    }

    get isAdmin() {
        return this.loggedIn && this.currentUser.category === 2;
    }

    get userName() {
        return this.loggedIn ? this.currentUser.name : "";
    }

    async login(userName, password, newpassword1, newpassword2) {

        let txtResult = null
       
        let result = 
        {
            isSuccess : false,
            result: {
                message : "loginPage_messages_notImplemented"
            },
            user: null
        }

        // send Data
        const data = 
        {
            name : userName,
            password : password,
            newPassword1 : (newpassword1 ?? null),
            newPassword2 : (newpassword2 ?? null)
        }

        // response
        let response = null;
        
        await fetch('/data/user/login', 
        {
            method: 'post',
            headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then( res => {            
            response = res;
            return res.text();
            // txtResult = await res.text()
            // return JSON.parse(txtResult) // res.json(); 
        })
        .then( txt => {                        
            txtResult = txt;
            const json = JSON.parse(txt);
            txtResult = null;
            result.result.message = json.msgid;
            if (json.user)
            {
                this.currentUser.id = json.user.id;
                this.currentUser.name = json.user.name;
                this.currentUser.category = json.user.category;
                this.currentUser.loggedIn = true;
                this.saveUserInStorage(this.currentUser)
                // result.user = 
                // {
                //     id : json.user.id,
                //     name: json.user.name,
                //     category: json.user.category
                // }           
                result.isSuccess = true
            // currentUserSubject.value.loggedIn = true;
            // saveUserInStorage(json.user);            
            }    
        })
        .catch(err => {
            if (!txtResult) {
                console.log("Catch err " + err)
            }
            else {
                console.log("Catch err " + txtResult)
            }
            console.log("Error Response " + response.status)        
            result.result.message = "" + response.status; 
        })
    
        return result;    
    }

    async logout() {        
        if (this.currentUser.loggedIn) {
            // console.log(" cookie " + document.cookie);
            await fetch('/data/user/logout')

            // const res = await fetch('/data/user/logout')
            // const txt = await res.text()
            // console.log(txt);
            // console.log("Auth logout")
            this.currentUser.id = 0;
            this.currentUser.loggedIn = false;
            this.currentUser.name = '';
            this.currentUser.category = 0;
            this.saveUserInStorage(this.currentUser);
            return true
        }
        return false
    }
    
    emptyUser() {
        return {
            id: 0,
            name: "" ,
            loggedIn : false,
            category : 0    
        }
    }

    getUserFromStorage() {
        // PROD !!! auf sessionStorage umstellen
        // return JSON.parse(sessionStorage.getItem(this.currentUserKey));
        return JSON.parse(localStorage.getItem(this.currentUserKey));
    }

    saveUserInStorage(user) {
        // PROD !!! auf sessionStorage umstellen
        // sessionStorage.setItem(this.currentUserKey, JSON.stringify(user));
        localStorage.setItem(this.currentUserKey, JSON.stringify(user));
    }
}

const service = AuthenticationService.getInstance();
Object.freeze(service);

// const init = { loading: true };
// export const authenticationService = singletonHook(init, service);

export default service;
