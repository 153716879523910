import React from 'react' // , { useEffect, useState }
// import { AiOutlineConsoleSql } from 'react-icons/ai';

const Hilfe = () => 
{   
    // console.log(window.location)
    // async function loadhtml()
    // {
    //   const response = await fetch('data/help')
    //   return response.text()

    //     // return "<html><head></head><body><div>HILFE</div></body></html>"
    // }

    // console.log(window.location.hash)

    // const [innerhtml, setinnerhtml] = useState ('loading...')

    // useEffect(() => {
    //   async function load()
    //   {
    //     fetch('data/help')
    //       .then( (response) => {
    //         response.text()
    //           .then( (txt) => {
    //             // console.log(txt)
    //             setinnerhtml(txt)            
    //             // setinnerhtml("<pre>Lorem Ipsum</pre>")
    //           })             
    //       })
    //       .catch( (err) => {
    //         setinnerhtml("" + err)
    //       })        
    //   }
    //   load()      
    // }, [])

    // useEffect(() => {     
     
    //   async function jump()
    //   {
    //     window.location.href = window.location.href;
    //   }

    //   if ((innerhtml) && innerhtml.length > 20) {        
    //     jump()
    //   }
    // }, [innerhtml])

    // function jumpto(mark) {
    //   console.log("mark " + mark)
    // }
    // // import DOMPurify from "dompurify";
    // // const myHTML = `<h1>John Doe</h1>`;
    // // const mySafeHTML = DOMPurify.sanitize(myHTML);

    //<div dangerouslySetInnerHTML={{__html:innerhtml}} />           allowfullscreen

    console.log(" " + window.location.hash)

  return (   
    <div className='help_div'>
      <iframe 
        src={window.location.protocol + '//' + window.location.hostname + ':8000/data/help/' + window.location.hash} 
        name="iFrame" title="Hilfe"
        width="100%" height="100%"   
      >        
      </iframe>
    </div>
  )
}

// 
//  width='100%' height='100%' backcolor='red'>

export default Hilfe