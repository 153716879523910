import React from 'react'
import { toShortDateTimeString } from '../../util/date-utils.js'

function LastEdit(props) 
{
    const editUser = props.edituser;
    const editTime = props.edittime;

    return (
        <>
            Geändert von: {editUser} am: {toShortDateTimeString(editTime)}            
        </>
    )
}

export default LastEdit
