import React, { useState, useEffect } from 'react'
import { AiOutlineFileExcel } from 'react-icons/ai';
// import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import { FcCancel } from 'react-icons/fc';
// import DatePicker from 'react-datepicker';
import Moment from 'moment';

import FormGroup from '../../components/shared/form-group';
import Spinner from '../../components/shared/spinner/Spinner';
import HorizontalTabs from '../../components/shared/horizontal-tabs/HorizontalTabs'
// import FormGroup from '../../components/shared/form-group'
import { FetchService, StorageService } from '../../services/';
// import { toShortDateString } from '../../util/date-utils';
import ConfigIcon from '../../util/configicon';
import { ZConfig } from '../../context/ZConfig';
// import { office } from '@microsoft/office-js';
// import { xls2ArrayBuffer } from '../../util/json2array';
// import Excel from 'exceljs';
// import XLSX from 'xlsx';

import FileSaver from 'file-saver';
import RechnungsListePageZahlungsLauf from './RechnungsListePageZahlungsLauf';
import RechnungsListePageOffenePosten from './RechnungsListePageOffenePosten';


const RechnungsListePage = (props) => {

    const [loaded, setLoaded] = useState(false)
    // const [sortOrder , setSortOrder] = useState(false)
    // const [lastSort , setLastSort] = useState(null)

    const [errorMessage, setErrorMessage] = useState(null)
    const [rdata, setRdata] = useState(null)
    const [countBestaetigt, setCountBestaetigt] = useState(0)
    const [nachweisBestaetigt, setNachweisBestaetigt] = useState(0)
    const [ibanErrorCount, setIbanErrorCount] = useState(0)
    const [aspErrorCount, setAspErrorCount] = useState(0)
    const [notPrintedErrorCount, setNotPrintedErrorCount] = useState(0)
    const [rechnungsDatum, setRechnungsDatum] = useState(Date.now)
    

    useEffect(() => {
        async function loadRData()
        {
            const res = await FetchService.readRechnungsDaten()            
            if (res.status === 200 && res.data) { 
                if (Array.isArray(res.data)) {
                    // if (res.data.length > 0) 
                    // {
                    //     console.log(res.data[0])
                    // }
                    setRdata(res.data)
                    setErrorMessage(null)
                    let cb = 0
                    let cn = 0
                    res.data.forEach(element => {
                        if (element.status === 3 || element.status === 5)
                            cb += 1;
                        else if (element.status === 9)
                            cn += 1;
                    });
                    setCountBestaetigt(cb)
                    setNachweisBestaetigt(cn)
                }
                else {
                    setRdata(null)
                    setErrorMessage(res.status + " " + res.statusText)
                }                    
            }
            else 
            {
                setErrorMessage(( res.error ? "" + res.error : "" + res.status + " " + res.statusText))               
            }
            setLoaded(true)
        }
        if (!ZConfig.IgnoreIbanAspPrintError) {
            setIbanErrorCount (StorageService.getIbanErrorCount())
            setAspErrorCount (StorageService.getAspErrorCount())
            setNotPrintedErrorCount(StorageService.getNotPrintedErrorCount())
        }
        loadRData()
    }, [])

    // const clickSort = (evt) => {        
    //     const txt = evt.currentTarget.innerText        
    //     if (lastSort !== txt) {
    //         setSortOrder(false)
    //         setLastSort(txt)
    //     }
    //     let idx = null;
    //     console.log(txt)
    //     switch (txt) 
    //     {
    //         case 'Status' : idx = 'status'; break;
    //         case 'MID' : idx = 'id'; break;
    //         case 'Prozentual': idx = 'prozentual'; break;
    //         case 'Fachrichtung': idx = 'fachId'; break;
    //         case 'Von' : idx = 'f_von'; break;
    //         case 'Bis' : idx = 'f_bis'; break;
    //         case 'Neu berechnet' : idx = 'BetragBerechnetIstNeu'; break;
    //         // case 'Vermerk' : idx = 'auszahlvermerk'; break;
    //         // case 'Verwendungszweck' : idx = 'Verwendungszweck'; break;  
    //         default : idx = null; break;
    //     }
    //     if (idx)
    //     {
    //         console.log("sort by column " + idx)
    //         rdata.sort( (a, b) => {  
    //             if (a[idx] < b[idx]) {
    //                 return (sortOrder ? -1 : 1);
    //             } else if(a[idx] > b[idx]) {
    //                 return (sortOrder ? 1 : -1);
    //             }
    //             return 0;
    //         })
    //         setSortOrder(!sortOrder)            
    //         setRdata(rdata)
    //     }
    // }


    const exportAbbrechen = () =>
    {        
        window.history.back();        
    }

    const exportToExcel = async () => 
    {
        console.log("export excel")  
        
        // // const wb = new Excel.Workbook()
        
        const result = await FetchService.loadRechnungsDatenFile()
        if (result.status === 200 && result.data) {
            try 
            {            
                const ds = new Date().toISOString().slice(0,10)
                const filename = ds + "-Rechnungslisten.xlsx"      

                // const abuf = await result.data.arrayBuffer() 
                // const data = new Blob([abuf] ,{ type:"application/octet-stream" });
                FileSaver.saveAs( result.data, filename);              
            }
            catch (err) {
                console.log("" + err)
            }
        }
        else {
           console.log(result.error + " " + result.status + " " + result.statusText)
        }        
    }

    const rechnungslaufFinalize = async() => {        
        const rd = new Date(rechnungsDatum).toLocaleDateString()
        if (StorageService.getStornoCount() > 0) {
            if (!window.confirm("Achtung, es existieren noch zu stornierende Maßnahmen. Dies verfälscht die Offene-Posten-Liste. " + 
                    "Soll der Rechnungslauf zum " + rd + " trotzdem abgeschlossen werden?"))
            {
                return;
            }
        }
        else
        {
            if (!window.confirm("Soll der Rechnungslauf zum " + rd + " abgeschlossen werden?")) {
                return;
            }        
        }
        // RechnungslaufAbschließen CDate(txtRechnungsdatum)
        // console.log("rechnungslauf finalisieren")        
        const result = await FetchService.finalizeZahlungslauf(
            StorageService.getZahlungsLaufJahr(),
            StorageService.getZahlungsLaufNumber(),
            rechnungsDatum)

        console.log(result)
        if (result.status !== 200) {
            window.alert("Fehler: " + result.status + " " + result.statusText)
        }
        else {
            window.alert("Rechnungslauf erfolgreich gespeichert")            
        }        
    }

    const tabitems = 
    [   
        { title : 'Zahlungslauf', component : RechnungsListePageZahlungsLauf, data: rdata },     
        { title : 'Offene Posten', component : RechnungsListePageOffenePosten, data : rdata },     
    ]

    let tabitemsActionFunc = null
    const tabitemsAction = (action) => { 
        tabitemsActionFunc = action 
    }
    const calcDisabled = (flags) => { }
    const tabitemsCallback = (pagename, cmd) => { }

    useEffect(() => {
        async function func()
        {
            if (tabitemsActionFunc && loaded) {                  
                tabitemsActionFunc(tabitems[0].title, 'edit')
            }
        }        
        func();
    }, [tabitemsActionFunc, loaded]);

    return (
        <>
        { loaded && 
        <>
            <div>
                <table>
                    <thead>
                        <tr className="table-blank-row4"><td/></tr>
                        <tr>
                            <th><h3>Rechnungs Listen</h3></th>
                            { rdata && rdata.length > 0 && 
                            <th className="td_button">
                                <button className="exportbtn" title="Als Excel exportieren" onClick={exportToExcel}>
                                    <ConfigIcon><AiOutlineFileExcel/></ConfigIcon>&nbsp;Exportieren
                                </button>
                            </th>
                            }
                            {/* { rdata && rdata.length > 0 && 
                            <th className="td_button">
                                <button className="exportbtn" title="Rechnungslauf finalisieren" onClick={rechnungslaufFinalize} >
                                    Rechnungslauf
                                </button>
                            </th>
                            } */}
                            <th className="td_button">
                                <button className="cancelbtn" title="Zurück" onClick={exportAbbrechen}>
                                    <ConfigIcon><FcCancel/></ConfigIcon>&nbsp;Abbrechen
                                </button>
                            </th>                        
                        </tr>
                        <tr className="table-blank-row4"><td/></tr>
                        <tr>
                            <td className="td_label">
                                Rechnungsdatum:                                
                            </td>
                            <td className='td_date'>
                                <FormGroup
                                    type="html-date"
                                    className="form-control" //"td_datepicker"
                                    value={rechnungsDatum}
                                    onChange={setRechnungsDatum}
                                    max={Moment().add(4,'weeks').toDate()}
                                    min={Moment().toDate()}
                                    disabled={(ibanErrorCount > 0 || aspErrorCount > 0 || notPrintedErrorCount > 0)}
                                />                               
                            </td>
                            { rdata && rdata.length > 0 && <> 
                                <th className="td_button">
                                    <button 
                                        className="exportbtn" 
                                        title="Rechnungslauf finalisieren" 
                                        onClick={rechnungslaufFinalize} 
                                        disabled={(ibanErrorCount > 0 || aspErrorCount > 0 || notPrintedErrorCount > 0)}
                                    >
                                        Rechnungslauf
                                    </button>
                                </th>
                                { (ibanErrorCount > 0 || aspErrorCount > 0 || notPrintedErrorCount > 0) && 
                                <>
                                    <th className="td_label">
                                        Fehler
                                    </th>
                                    { ibanErrorCount > 0 && <td className="td_label">IBAN: {ibanErrorCount}</td> }
                                    { aspErrorCount > 0 && <td className="td_label">ASP: {aspErrorCount}</td> }
                                    { notPrintedErrorCount > 0 && <td className="td_label">Nachweis nicht gedruckt: {notPrintedErrorCount}</td> }
                                </>
                                }
                            </>
                            }
                        </tr>
                        <tr>
                            {errorMessage && <th>{errorMessage}</th>}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="table-blank-row4"><td/></tr>
                        <tr>
                            <td className="td_label">Zahlungslauf: {nachweisBestaetigt}</td>
                            <td></td>  
                            <td className="td_label">Offene Posten: {countBestaetigt}</td>
                            <td></td>                                                   
                        </tr>
                        <tr className="table-blank-row4"><td/></tr>
                    </tbody>
                </table>
            </div>
            <div>
                <HorizontalTabs 
                    className={'tabbtn'}
                    tabitems={tabitems} 
                    setAction={tabitemsAction} 
                    callback={tabitemsCallback}
                    getDisabled={calcDisabled} 
                />           
            </div>
        </> }

        { !loaded && 
            <Spinner />
        }                            
        </>        
    )
}

export default RechnungsListePage





        //    console.log(zl['!rows'])
        //    // zl['!rows']
        //    // wb.Sheets["Zahlungslauf"].push(obj)

        // //     const zl = wb.getWorksheet("Zahlungslauf")
        // //     console.log("Worksheet " + zl.name)
        // //     const zltable = zl.getTable("Tabelle6")
        // //     zltable.worksheet = zl
        // //     console.log(zltable.table)
        // //     // zltable.rows = []
        // //     for (let num=0; num<10; num++)
        // //     {
        // //         const obj = 
        // //         {
        // //             'Nachnme':'NN', 
        // //             'Vorname':'VN', 
        // //             'TID':'TID', 
        // //             'Land':'BLAND', 
        // //             'IK':'IK', 
        // //             'MID':'MID', 
        // //             'Von':'01.01.2020', 
        // //             'Bis':'30.09.2020',
        // //             'Jahr_Bis':2020, 
        // //             'Fachgebiet':'Innere', 
        // //             'Prozentual':100, 
        // //             'Auszahlbetrag':1000
        // //         }

        // //         zl.addRow(obj)
        // //     }

        // //     const op = wb.getWorksheet("Offene Posten")
        // //     console.log("Worksheet " + op.name)
        // //     const optable = op.getTable("Tabelle2")
        // //     optable.worksheet = op
        // //     console.log(optable.table)

            
        // //     const buf = await wb.xlsx.writeBuffer()
        // //     console.log(buf)
        // //     const filename = "RechnungsDaten.xlsx"                     

        // //     // FileSaver.saveAs( result.data, filename);             

        // //     FileSaver.saveAs( new Blob( [buf] ), filename);             


        //  // Filter ???
        //     <td className="td_fitwidth">
        //                     <FormGroup
        //                         contentClassName="select_readonly"
        //                         type="select"
        //                         value={status}
        //                         options={statusData}
        //                         readonly="true"
        //                     />                                    
        //                 </td>   