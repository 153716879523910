import React, { useEffect } from 'react';
import PropTypes from 'prop-types'


const ContextMenu = props => {

    useEffect(() => {
        document.addEventListener("click", contextMenuClick)
    }, [])

    const menuClick = (evt) => {
        // console.log(props)
        // console.log(evt.target.attributes)
        const id = evt.target.attributes['id'].value;
        props.eventhandler(id)
    }

    const contextMenuClick = (evt) => {
        var ctxMenu = document.getElementById("context-menu")
        if (ctxMenu) {
            ctxMenu.classList.remove("active")
        }        
    }

    const contextMenuOpen = (evt) => {        
        evt.preventDefault();
        var ctxMenu = document.getElementById("context-menu")
        if (ctxMenu) {
            ctxMenu.style.top = evt.offsetY + "px";
            ctxMenu.style.left = evt.offsetX + "px";
            ctxMenu.classList.add("active");
        }
    }

    return (               
        <div id="context-menu" onContextMenu={contextMenuOpen}>
        { 
            props.menuitems.map( (item) => 
            {
                return (
                    <div                      
                        onClick={menuClick}                         
                        key={item.key}
                        id={item.key}
                        className="context-menu-item"
                    >
                        {item.title}                    
                    </div> 
                )
            })
        }        
        </div>
    )
}


ContextMenu.propTypes = {
    eventhandler : PropTypes.func, 
    menuitems : PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            key: PropTypes.number
        })        
    )
}

export default ContextMenu



// export class ContextMenu extends Component {

//     constructor(props)
//     {
//         super(props);
//         console.log("this.props " + JSON.stringify(this.props)) 
//     }

//     componentDidMount()
//     {       
//         let outtermostDomNodeOfHoc = ReactDOM.findDOMNode(this) ;
//         // console.log(outtermostDomNodeOfHoc.parentNode.parentNode);

        
//         outtermostDomNodeOfHoc.addEventListener("contextmenu", this.contextMenuOpen);
//         outtermostDomNodeOfHoc.addEventListener("click", this.contextMenuClick);
//         document.addEventListener("click", this.contextMenuClick)
//     }

//     contextMenuOpen(event) {
//         // console.log("contextmenu open")
//         event.preventDefault();
//         var ctxMenu = document.getElementById("context-menu")
//         ctxMenu.style.top = event.offsetY + "px";
//         ctxMenu.style.left = event.offsetX + "px";
//         ctxMenu.classList.add("active");
//     }

//     contextMenuClick(event) {
//         document.getElementById("context-menu").classList.remove("active")
//         // console.log("contextMenuClick")
//     }

//     menuClick(event) {
//         console.log(event.target.attributes)
        
//         // if (this.props.onMenuClick) {
//         //     this.props.onMenuClick();
//         // }
//     }

//     render() {
//         // console.log("this.props " + JSON.stringify(this.props))
//         return (
//             <div id="context-menu" onContextMenu={this.contextMenuOpen}>
//                 { 
//                     this.props.items.map( (item) => 
//                     {
//                         return (<div 
//                             className="context-menu-item" 
//                             onClick={this.menuClick} 
//                             handler={this.props.eventhandler} 
//                             key={item.key}>{item.title}</div> 
//                         )
//                     })
//                 }
//                 {/* <div className="context-menu-item" onClick={this.menuClick}>
//                     <i className="fa fa-cut"></i> Cut
//                 </div>    
//                 <div className="context-menu-item" onClick={this.menuClick}>
//                     <i className="fa fa-clone"></i> Copy
//                 </div>    
//                 <div className="context-menu-item" onClick={this.menuClick}>
//                     <i className="fa fa-paste"></i> Paste
//                 </div>    
//                 <div className="context-menu-item" onClick={this.menuClick}>
//                     <i className="fa fa-trash-o"></i> Delete
//                 </div>    
//                 <hr>
//                 </hr>
//                 <div className="context-menu-item" onClick={this.menuClick}>
//                     <i className="fa fa-refresh"></i> Refresh
//                 </div>    
//                 <div className="context-menu-item" onClick={this.menuClick}>
//                     <i className="fa fa-times"></i> Exit
//                 </div>     */}                
//             </div>
//         )
//     }
// }

// // const DropdownMenuHoverable = props => {
// //     return (
// //         <div className="hdropdown">
// //             <button className="hdropbtn">{props.menuname}</button>
// //             <div id={props.menuname} className="hdropdown-content">
// //                 {
// //                     props.menuitems.map( ({ title, link}) => 
// //                     {    
// //                         var hash = props.menuname +  title.hashCodePositive().toString()
// //                         // console.log("hash " + hash)    
// //                         return (<a key={hash} href={link}>{title}</a>)
// //                     })
// //                 }

// //                 {/* 
// //                 <a href="#">Link 1</a>
// //                 <a href="#">Link 2</a>
// //                 <a href="#">Link 3</a>
// //                 */}
// //             </div>
            
// //         </div>
// //     )
// // }


// export default ContextMenu;

