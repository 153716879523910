const config = 
{
    password : 
    {
        minlength : 12,
        maxlength : 16,
        numchars : true,
        extrachars : true
    }
}

module.exports = config;