

export function round(number, digits, tail)
{
    const p = Math.pow(10, digits)
    const res = Math.round(number*p) / p
    if (tail) {
        return res + tail
    }
    return res;
}

export function formatCurrency(number)
{
    if ((number) || Number.isInteger(number)) {
        return number.toLocaleString("de-DE", {style:'currency', currency : 'EUR'});
    }
    return ''
}

// export function checkIban(data)
// {
//     let iban = data.iban
//     console.log(iban)
//     if (iban && iban.length === 22)
//     {     
//         const parts = [
//             iban.slice(0,2),
//             iban.slice(2,4),
//             iban.slice(4,8),
//             iban.slice(8,12),
//             iban.slice(12,16),
//             iban.slice(16,20),
//             iban.slice(20,22)
//         ]

//         if (parts[0] !== "DE") {
//             data.iban = "Muss mit DE anfangen"
//             return false;
//         } 

//         for(let num=1; num<parts.length; num++) {
//             for(let idx=0; idx<parts[num].length; idx++) {
//                 if (!isCharNumber (parts[num].charAt(idx))) {
//                     data.iban = "Invalid Character " + parts[num]
//                     return false
//                 }
//             }
//         }
//         const ps = parts[1];        
//         console.log("PS " + ps)
//         const cps = calculateIban(parts)
//         if (ps === cps) {
//             return true;
//         }
//         data.iban = "Prüfsummenfehler"        

//         console.log("ps " + ps)
//         return false
//     }
//     return false;

//      // const parts = [
//         //     iban.slice(0,4),
//         //     iban.slice(5,9),
//         //     iban.slice(10,14),
//         //     iban.slice(15,19),
//         //     iban.slice(20,24),
//         //     iban.slice(25,27)
//         // ]
// }

// function calculateIban(parts)
// {
//     const ch0 = (parts[0].charCodeAt(0) - 55)
//     const ch1 = (parts[0].charCodeAt(1) - 55)
//     const ldKennung = ch0.toString() + ch1 + "00";
//     const ktoNummer = parts[2] + parts[3] + parts[4] + parts[5];
//     console.log("K   " + ktoNummer)
//     const z = Number.parseInt(ktoNummer)
//     console.log("Z   " + z)
//     console.log(parts)
//     const md1 = z % 97    
//     console.log("MOD1 97     " + md1)
//     let modString = md1.toString()
//     if (modString.length == 1) {
//         modString = "0" + modString
//     }
//     const zweiter = modString + parts[6] + ldKennung;
//     const z2 = Number.parseInt(zweiter)
//     console.log("2nd Part    " + zweiter)
//     const md2 = z2 % 97
//     console.log("MOD2 97     " + md2)
//     const ibanPs = 98 - md2
//     let ibanPsString = ibanPs.toString()
//     if (ibanPsString.length == 1) {
//         ibanPsString = "0" + ibanPsString;
//     }

//     console.log("KontoNummer = " + "DE" + ibanPsString + ktoNummer + parts[6])
//     return ibanPsString;
// }

// function isCharNumber(c) {
//     return c >= '0' && c <= '9';
//   }

