
class X 
{
    static B1  = 0x1        // 1  | Beantragt                    | NULL                                                                                |    NULL |
    static B2  = 0x2        // 2  | RückfrageZuAntrag            | NULL                                                                                |    NULL |
    static B3  = 0x4        // 3  | Bestätigt                    | NULL                                                                                |    NULL |
    static B4  = 0x8        // 4  | ÄnderungAntrag               | NULL                                                                                |    NULL |
    static B5  = 0x10       // 5  | KorrekturBestätigt           | NULL                                                                                |    NULL |
    static B6  = 0x20       // 6  | AbgeschlossenOhneNachweis    | Ist geschlossen worden (Storniert)                                                  |    NULL |
    static B7  = 0x40       // 7  | NachweisEingegangen          | NULL                                                                                |    NULL |
    static B8  = 0x80       // 8  | RückfrageZuNachweis          | NULL                                                                                |    NULL |
    static B9  = 0x100      // 9  | NachweisBestätigt            | Vorgesehen für nächsten Zahlungslauf                                                |    NULL |
    static B10 = 0x200      // 10 | ÄnderungNachweis             | Änderungswunsch ging ein -> entweder Rückfrage oder neue Bestätigung                |    NULL |
    static B11 = 0x400      // 11 | MassnahmeAusTeilnachweis     | Entsteht wenn Teilnachweise erstellt werden für die Restlaufzeit der Massnahme      |    NULL |
    static B12 = 0x800      // 12 | ZahlungAngewiesen            | In SEPA-Liste aufgenommen                                                           |    NULL |
    static B13 = 0x1000     // 13 | ZahlungsRücklauf             | Überweisung nicht erfolgreich                                                       |    NULL |
    static B14 = 0x2000     // 14 | KorrekturNachweis            | NULL                                                                                |    NULL |
    static B15 = 0x4000     // 15 | KorrekturNachweisVerrechnet  | NULL                                                                                |    NULL |
    static B16 = 0x8000     // 16 | MaßnahmeStorniert            | Endgültig zurückgezogen oder Fehleingabe                                            |    NULL |
    static B17 = 0x10000    // 17 | ZahlungAngewiesenGekürzt     | Nach Abrechnung wurde Kürzung vorgenommen                                           |    NULL |
    static B18 = 0x20000    // 18 | VormerkungKürzungNachZahlung | Markierung für Ausgezahlte Maßnahmen für die noch Maßnahmen zur Verrechnung fehlen! |    NULL |
    static B19 = 0x40000    // 19 | RechnungGestellt             | Maßnahme ist in Rechnung an Kassen aufgenommen                                      |    NULL |   
    static B20 = 0x80000    // 20 | reserved                     | reserved                                      |    NULL |   
    static B21 = 0x100000   // 21 | reserved                     | reserved                                      |    NULL |   
    static B22 = 0x200000   // 22 | reserved                     | reserved                                      |    NULL |   
    static B23 = 0x400000   // 23 | reserved                     | reserved                                                                            |    NULL |   
    static B24 = 0x800000   // 24 | RechnungGestelltÄnderung     | wie 19 aber Konto und Verwendungszweck können bearbeitet werden.                    |    NULL |   

    static calcDisabled(flags, massnahmeStatus)
    {
        // console.log("Flags " + flags)
        if (massnahmeStatus > 0)
        {
            const mask = Math.pow(2, massnahmeStatus-1)
            // console.log("Status Mask " + mask)  
            if ((flags & mask) !== 0) {
                return false
            }
        }
        return true              
    }
}

class Y 
{
    static B1Beantragt  = 0x1                 // 1  | Beantragt                    | NULL                                                                                |    NULL |
    static B2RueckfrageZuAntrag  = 0x2        // 2  | RückfrageZuAntrag            | NULL                                                                                |    NULL |
    static B3Bestaetigt  = 0x3                // 3  | Bestätigt                    | NULL                                                                                |    NULL |
    static B4AenderungAntrag = 0x4            // 4  | ÄnderungAntrag               | NULL                                                                                |    NULL |
    static B5KorrekturBestaetigt = 0x5        // 5  | KorrekturBestätigt           | NULL                                                                                |    NULL |
    static B6AbgeschlossenOhneNachweis = 0x6  // 6  | AbgeschlossenOhneNachweis    | Ist geschlossen worden (Storniert)                                                  |    NULL |
    static B7NachweisEingegangen = 0x7        // 7  | NachweisEingegangen          | NULL                                                                                |    NULL |
    static B8RueckfrageZuNachweis = 0x8       // 8  | RückfrageZuNachweis          | NULL                                                                                |    NULL |
    static B9NachweisBestaetigt = 0x9         // 9  | NachweisBestätigt            | Vorgesehen für nächsten Zahlungslauf                                                |    NULL |
    static B10AenderungNachweis = 0xA         // 10 | ÄnderungNachweis             | Änderungswunsch ging ein -> entweder Rückfrage oder neue Bestätigung                |    NULL |
    static B11MassnahmeAusTeilnachweis = 0xB  // 11 | MassnahmeAusTeilnachweis     | Entsteht wenn Teilnachweise erstellt werden für die Restlaufzeit der Massnahme      |    NULL |
    static B12ZahlungAngewiesen = 0xC         // 12 | ZahlungAngewiesen            | In SEPA-Liste aufgenommen                                                           |    NULL |
    static B13ZahlungsRuecklauf = 0xD         // 13 | ZahlungsRücklauf             | Überweisung nicht erfolgreich                                                       |    NULL |
    static B14KorrekturNachweis = 0xE         // 14 | KorrekturNachweis            | NULL                                                                                |    NULL |
    static B15KorrekturNachweisVerrechnet = 0xF    // 15 | KorrekturNachweisVerrechnet  | NULL                                                                                |    NULL |
    static B16MassnahmeStorniert = 0x10            // 16 | MaßnahmeStorniert            | Endgültig zurückgezogen oder Fehleingabe                                            |    NULL |
    static B17ZahlungAngewiesenGekuerzt = 0x11     // 17 | ZahlungAngewiesenGekürzt     | Nach Abrechnung wurde Kürzung vorgenommen                                           |    NULL |
    static B18VormerkungKuerzungNachZahlung = 0x12 // 18 | VormerkungKürzungNachZahlung | Markierung für Ausgezahlte Maßnahmen für die noch Maßnahmen zur Verrechnung fehlen! |    NULL |
    static B19RechnungGestellt = 0x13              // 19 | RechnungGestellt             | Maßnahme ist in Rechnung an Kassen aufgenommen                                      |    NULL |   
    static B20 = 0x14                              // 20 | reserved                     | reserved                                      |    NULL |   
    static B21 = 0x15                              // 21 | reserved                     | reserved                                      |    NULL |   
    static B22 = 0x16                              // 22 | reserved                     | reserved                                      |    NULL |   
    static B23 = 0x17                              // 23 | reserved                     | reserved                                                                            |    NULL |   
    static B24RechnungGestelltAenderung = 0x18      // 24 | RechnungGestelltÄnderung     | wie 19 aber Konto und Verwendungszweck können bearbeitet werden.     

}

export { X, Y }
