import React, { useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import BeschaeftigungListPage from './BeschaeftigungListPage'

const BeschaeftigungList = (props) => {
    // console.log("RENDER List Teilnehmer TID: " + props.tid)
   

    const [einrichtungenList, setEinrichtungenList] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
  
    useEffect( () => {
        async function asyncFetchEinrichtungenForTeilnehmerId()
        {   
            const defaultError = [{
                ik : 0,
                iban: "",
                iban_isok: true,
                inaktiv: true,
            }]
           
            try {                                
                const response = await fetch('/data/beschaeftigungen/einrichtung/' + props.tid)
                if (response.ok) {
                    const result = await response.json();    
                    if (Array.isArray(result))
                    {
                        // console.log(result)
                        setEinrichtungenList(result)
                        setIsLoaded(true)
                        return;
                    }
                    defaultError[0].iban = "E:" + result.name
                    throw new Error(result.name)                                    
                }
                else
                {
                    defaultError[0].iban = "E:" + response.status
                    throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                setEinrichtungenList(defaultError)
                console.log(err)
            }
        }
        if (props.tid) {
            // console.log("Load Beschaeftigung " + props.tid + " " + isLoaded)
            asyncFetchEinrichtungenForTeilnehmerId()        
        }
    }, [props.tid, isLoaded])

    return (
        <BeschaeftigungListPage isLoaded={isLoaded} liste={einrichtungenList} tid={props.tid} teilnehmer={props.teilnehmer}/>
           )

}

BeschaeftigungList.propTypes = {
    tid : PropTypes.number,
    teilnehmer : PropTypes.any
}

export default BeschaeftigungList;
