
import React, { useState, useEffect } from 'react'; 
import { useIntl } from 'react-intl';
import { AiOutlineCheckCircle  } from 'react-icons/ai';

import ConfigIcon from '../../util/configicon';
import translate from '../../i18n/translate';  
import rtranslate from '../../i18n/rtranslate';  
import './login-page.scss';


const LoginPageView = ({isFormSubmitting, message, onFormSubmit}) => {
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [newpassword1, setNewPassword1] = useState(null);
    const [newpassword2, setNewPassword2] = useState(null);
    const [showchangepass, setShowChangePass] = useState(false)

    const intl = useIntl();

    const _onFormSubmit = (e) => {        
        const type = e.nativeEvent.submitter.id        
        e.preventDefault();
        
        if (isFormSubmitting){
            return;
        }  

        onFormSubmit({type, username, password, newpassword1, newpassword2, showchangepass });     
    }

    //// Neues Passwort anfordern 
    // const _newPwd = () => {        
    //     const elem = document.getElementById("pwd-input")
    //     if (elem) {
    //         elem.required = false
    //     }
    // }

    const _login = () => {       
        const elem = document.getElementById("pwd-input")
        if (elem) {
            elem.required = true
        }        
    }

    const _logincpwd = () => {        
        setShowChangePass(!showchangepass)
    }

    const showPasswortBadge = () => {        
        const passwordhtml = document.getElementById('pwd-inputn1')
        const strengthBadge = document.getElementById('StrengthDisp')
        if ( (passwordhtml) && (strengthBadge))   
        {   
            if(passwordhtml.value.length !== 0){
                strengthBadge.style.display = 'block'
            } else{
                strengthBadge.style.display = 'none'
            }
        }
    }

    // timeout before a callback is called
    // let timeout;

    // The strong and weak password Regex pattern checker
    let strongPassword = new RegExp( '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{12,})')
    let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))|' +
                                    '((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{11,}))')

    useEffect(() => {
       
        const passwordhtml = document.getElementById('pwd-inputn1')
        const strengthBadge = document.getElementById('StrengthDisp')
            
        if ( (passwordhtml) && (strengthBadge)) 
        {            
            passwordhtml.addEventListener('input', () =>
            {        
                strengthBadge.style.display = 'block'
                // clearTimeout(timeout);

                // timeout = 
                setTimeout( () => StrengthChecker(passwordhtml.value), 500);

                showPasswortBadge()
            });   
            StrengthChecker(passwordhtml.value) 
            showPasswortBadge()    
        }
    }, [showchangepass])
    
    function StrengthChecker(PasswordParameter){
        // We then change the badge's color and text based on the password strength
        const strengthBadge = document.getElementById('StrengthDisp')

        if(strongPassword.test(PasswordParameter)) {
            strengthBadge.style.backgroundColor = "green"
            strengthBadge.textContent = 'Stark'
        } else if(mediumPassword.test(PasswordParameter)){
            strengthBadge.style.backgroundColor = 'blue'
            strengthBadge.textContent = 'Mittel'
        } else{
            strengthBadge.style.backgroundColor = 'red'
            strengthBadge.textContent = 'Schwach'
        }
    }
    
    return (
        <div className="login-page text-center pt-5">  
            <form className="form-login" onSubmit={_onFormSubmit}>                 
                <h1 className="h3 mb-4 font-weight-normal sign-in-label">{ translate('loginPage.loginText') }</h1>
                <div className="input-group">
                    <label 
                        className="label form-control"
                        htmlFor="userName">{ translate('loginPage.userNameLabel') }
                    </label> 
                    <input 
                        id="username"
                        name="username"
                        type="text"  
                        value={username || ''}
                        onChange={(e) => setUsername(e.target.value) }
                        className="username form-control" 
                        placeholder={ rtranslate(intl, 'loginPage_username') }
                        required 
                        autoFocus 
                        autoComplete='off'
                    />
                </div>                            
                <div className="input-group">
                    <label 
                        className="label form-control"
                        htmlFor="password">{ 
                            (showchangepass ? 
                                translate('loginPage.oldpasswordLabel'):
                                translate('loginPage.passwordLabel')
                            )
                        }
                    </label>
                    <input 
                        id="pwd-input"
                        name="password"
                        type="password"  
                        value={password || ''}
                        onChange={(e) => setPassword(e.target.value) }
                        className="password form-control"                         
                        placeholder={ rtranslate(intl, 'loginPage.password') }
                        required 
                    /> 
                </div>

                {showchangepass && <>
                <div className="input-group">
                    <span id="StrengthDisp" className="form-control displayBadge">Weak</span>
                </div>
                <div className="input-group">
                    <label 
                        className="label form-control"
                        htmlFor="newpassword1">{ translate('loginPage.newpasswordlabel1')}
                    </label>
                    <input 
                        id="pwd-inputn1"
                        name="newpassword1"
                        type="password"  
                        value={newpassword1 || ''}
                        onChange={(e) => setNewPassword1(e.target.value) }
                        className="password form-control"                         
                        placeholder={ rtranslate(intl, 'loginPage.password') }
                        required 
                    /> 
                </div>
                
                <div className="input-group">
                    <label 
                        className="label form-control"
                        htmlFor="password">{ translate('loginPage.newpasswordlabel2')}
                    </label>
                    <input 
                        id="pwd-inputn2"
                        name="newpassword2"
                        type="password"  
                        value={newpassword2 || ''}
                        onChange={(e) => setNewPassword2(e.target.value) }
                        className="password form-control"                         
                        placeholder={ rtranslate(intl, 'loginPage.password') }
                        required 
                    /> 
                </div>
                </>}
                

                {
                    message &&
                    <div className="my-2 text-danger">
                        { translate(message) }
                    </div>
                }
                <h3>&nbsp;</h3>
                <div className="input-group">
                    <button id="login" className="exportbtn" type="submit" onClick={_login} disabled={isFormSubmitting}>
                        <ConfigIcon><AiOutlineCheckCircle/></ConfigIcon>&nbsp;Anmelden                    
                    </button>
                    <span style={{marginLeft:10}}></span>
                    <button className="exportbtn" type="button" onClick={_logincpwd} disabled={isFormSubmitting}>
                        <ConfigIcon><AiOutlineCheckCircle/></ConfigIcon>&nbsp;Passwort &auml;ndern
                    </button>
                    {/* Neues Passwort anfordern */}
                    {/* <span style={{marginLeft:10}}></span>
                    <button id="newpwd" className="exportbtn" type="submit" onClick={_newPwd} disabled={isFormSubmitting}>
                        <ConfigIcon><AiOutlineCheckCircle/></ConfigIcon>&nbsp;Neues Passwort
                    </button> */}
                </div>               
            </form>    
        </div>
    );
}

export default LoginPageView; 