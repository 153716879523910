import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import LoginPageView from './LoginPageView';
import { authenticationService, FetchService } from '../../services/';

const LoginPage = () => {
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [message, setMessage] = useState(null); 

    // useEffect(() => {        
    //     document.title = `Login / Kondor`;  

    //     return (() => {
    //         document.title = `Kondor`;  
    //     });
    // }, []);

    const onFormSubmit = async ({type, username, password, newpassword1, newpassword2, showchangepass }) => {
        try
        {
            // console.log(type)            
            setIsFormSubmitting(true);
            if (showchangepass)
            {
                // console.log("changepass")
                if (newpassword1 && newpassword2 && newpassword1.length>0 && newpassword2.length>0) 
                {
                    // console.log("password change")
                    if (newpassword1.length !== newpassword2.length || newpassword1 !== newpassword2) 
                    {
                        setIsFormSubmitting(false)
                        setMessage("loginPage_messages_passwords_differ")
                        return;
                    }
                    const vresult = await FetchService.validatePassword(newpassword1)
                    // console.log(vresult)
                    if (vresult.status !== 200) 
                    {
                        setIsFormSubmitting(false)
                        setMessage("loginPage_messages_" + vresult.status)
                        return;
                    } 
                    else if(vresult.data.length===0)
                    {
                        setIsFormSubmitting(false)
                        setMessage("loginPage_messages_error")
                        return;
                    }
                    else if(!vresult.data.startsWith("0;"))                
                    {
                        setIsFormSubmitting(false)
                        setMessage("loginPage_messages_password_rules")
                        return;
                    }
                }
                else
                {                
                    setIsFormSubmitting(false)
                    setMessage("loginPage_messages_error")
                    return;
                }
            }
            else 
            { // kein passwort-wechsel
                newpassword2 = null
                newpassword1 = null
            }
            const loginResult = await authenticationService.login( username, password, newpassword1, newpassword2);
            
            if (loginResult.isSuccess === false) {            
                setIsFormSubmitting (false);
                setMessage (loginResult.result.message);               
            }  
            else
            {
            //    setIsFormSubmitting(false);
            //    setMessage (loginResult.result.message);                   
            //    console.log("LOGGED IN " + JSON.stringify(authenticationService.currentUser));    
                redirectPage();                       
            }   

        }
        catch(err)
        {
            console.log("CATCH " + err);
        }
    }

    const redirectPage = () => {
        window.location.replace("/")
    }

    if (authenticationService.loggedIn)
    {
        console.log("redirect " + JSON.stringify(authenticationService.currentUser));         
        return (<Redirect to='/'/>)
    }
    else
    {
        // console.log("login");
        return( 
            <div>
                <LoginPageView
                    isFormSubmitting = {isFormSubmitting}
                    message = {message}
                    onFormSubmit = {onFormSubmit}
                >
                </LoginPageView>
            </div>
        );
    }

    // render() {
    //     console.log(this.message);
    //     return (
    //         <div>
    //             <LoginPageView
    //                 isFormSubmitting = {isFormSubmitting}
    //                 message = {message}
    //                 onFormSubmit = {onFormSubmit}
    //             >
    //             </LoginPageView>
    //         </div>
    //     )
    // }
}

export default LoginPage;

