import { IconContext } from "react-icons";

export default function ConfigIcon({ children }) {

    // <IconContext.Provider value={{ color: "#333", size:"1.0em" }}>

    return (
        <>
            <IconContext.Provider value={{ size:"1.0em" }}>
                {children}
            </IconContext.Provider>
        </>
    );
}

export function ConfigGreenIcon( {children} ) {
    return (
        <>        
            <IconContext.Provider value={{color:'green', size:'20px' }}>
                {children}
            </IconContext.Provider>
        </>
    )
}