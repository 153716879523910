
class Strings
{
    static padLeft(st, c, count) {
        let result = "" + st
        while (result.length < count) {
            result = "" + c + result
        }        
        return result;
    }

    static hashCode(st) 
    {
        var hash = 0;
        for (var i = 0; i < st.length; i++) {
            var character = st.charCodeAt(i);
            hash = ((hash<<5)-hash)+character;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }

    static hashCodePositive(st)
    {
        var hash = 0;
        for (var i = 0; i < st.length; i++) {
            var character = st.charCodeAt(i);
            hash = ((hash<<5)-hash)+character;
            hash = hash & hash; // Convert to 32bit integer
        }
        return Math.abs(hash);
    }
}

module.exports = Strings