import React, { useState, useEffect } from 'react'

const TicketDocuments = (props) => 
{
    const [list, setlist] = useState(null)

    useEffect(() => 
    {
        async function loadComments()
        {
            // console.log("Kommentare Selected TicketID " + props.data)
            if (props.data)
            {
                try 
                {                                
                    const response = await fetch('/data/admin/redmine/issues/' + props.data)
                    if (response.ok) {
                        const result = await response.json();                        
                        const lst = []
                        result.issue.attachments.forEach(element => {
                        //     if (!element.notes && element.details && element.details.length > 0) {
                            lst.push(element)
                        // }                            
                        });                        
                        setlist(lst)
                        console.log(lst)
                        return;
                    }
                    else {
                        console.log(response.status + " " + response.statusText)
                    }
                }
                catch (err) {
                    console.log("" + err)
                }
            }
            setlist(null)
        }
        loadComments()
    }, [props.data])

    // "attachments": [
    //     {
    //       "id": 1277,
    //       "filename": "DKG_Digitale_Dokumente.txt",
    //       "filesize": 2297,
    //       "content_type": "text/plain",
    //       "description": "Fragen?",
    //       "content_url": "https://redmine.kontacts-svn.de/attachments/download/1277/DKG_Digitale_Dokumente.txt",
    //       "author": {
    //         "id": 40,
    //         "name": "Andreas Penzold"
    //       },
    //       "created_on": "2022-04-14T06:07:13Z"
    //     }
    //   ],

    return (
        <div style= {{display:'none'}}>
        {
            list && list.length > 0 && <div>
                <table className='sm_table'>
                    <tbody>
                        {list.map ( ({id, created_on, filename, filesize, 
                            content_type, description, author, content_url }) => {
                            return (<tr key={id}>
                                <td>
                                    {created_on}
                                </td>
                                <td>
                                    {filename}
                                </td>
                                <td>
                                    {filesize}
                                </td>
                                <td>
                                    {content_type}
                                </td>
                                <td>
                                    {description}
                                </td>
                                <td>
                                    {author.name}
                                </td>
                                <td>
                                    <a href={content_url}>Download</a>
                                </td>
                            </tr>)
                        })
                    }
                    </tbody>
                </table>
                {/* {property: 'attr', name: 'description', old_value: '', new_value: 'Das ist die Beschreibung der Aufgabe'}                */}
            </div>
        }
        {  
            (!list || list.length === 0) && <h3>keine Dokumente</h3>
        }
        </div>
        
    )
}

export default TicketDocuments;