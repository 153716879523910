import React from 'react'
import KontrollListenPage from './KontrollListenPage'

const KontrollListenRechnungGestellt = () => {
    return (
        <div>
            <KontrollListenPage mstatus="19"/>            
        </div>
    )
}

export default KontrollListenRechnungGestellt
