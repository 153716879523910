import React, { useState } from 'react' // , useEffect
import PropTypes from 'prop-types'

import FormGroup from '../components/shared/form-group'
import Tooltip from '../components/shared/tooltip/Tooltip'
// import { FormGroup as FG } from 'guicommon' 

const Antrag = props => 
{

    const [namedereinrichtung, setnamedereinrichtung] = useState(null)
    const [strassedereinrichtung, setstrassedereinrichtung] = useState(null)
    const [hausnummerdereinrichtung, sethausnummerdereinrichtung] = useState(null)
    const [plzdereinrichtung, setplzdereinrichtung] = useState(null)
    const [ortdereinrichtung, setortdereinrichtung] = useState(null)
    const [ikdereinrichtung, setikdereinrichtung] = useState(null)
    const [standortdereinrichtung, setstandortdereinrichtung] = useState(null)
    const [standortikdereinrichtung, setstandortikdereinrichtung] = useState(null)
    const [postanschriftdereinrichtung, setpostanschriftdereinrichtung] = useState(null)
    // const [bundesland, setbundesland] = useState(null)
    const [para108, setpara108] = useState(null)
    const [wbverbund, setwbverbund] = useState(null)

    const [aspanrede, setaspanrede] = useState(null)
    const [aspname, setaspname] = useState(null)
    const [asptelefon, setasptelefon] = useState(null)
    const [aspmail, setaspmail] = useState(null)

    const textchanged = (evt) => {
        console.log(evt)
    }

    // const filechanged = (evt) => {
    //     console.log(evt)
    //     console.log(evt.target)
    //     console.log(evt.target.files.length)
    //     console.log(evt.target.files[0])            
    // }
   
  return (     
      <div>        
        <div className="sm_table_div">       
            <table className='sm_table_60'>
                <tbody>
                    <tr>
                        <td><h3>Vereinbarung zur Förderung der Weiterbildung gemäß § 75a SGB V</h3></td>
                    </tr>
                    <tr className='table-blank-row'></tr>
                    <tr>
                        <td>
        Die u. g. Einrichtung meldet der Deutschen Krankenhausgesellschaft (DKG) als zentraler Registrierstelle, dass zur Förderung der 
        Weiterbildung in der Allgemeinmedizin eine Stelle besetzt wird.
                        </td>
                    </tr>
                    <tr className='table-blank-row'></tr>
                    <tr>
                        <td>
        Diese Meldung bezieht sich auf die Vereinbarung zur Förderung der Weiterbildung gemäß § 75a SGB V zwischen der Deutschen
        Krankenhausgesellschaft (DKG), der Kassenärztlichen Bundesvereinigung (KBV) und dem Spitzenverband Bund der Krankenkassen
        (GKV-Spitzenverband) im Einvernehmen mit dem Verband der privaten Krankenversicherung und im Benehmen mit der 
        Bundesärztekammer (BÄK) in Kraft getreten am 01.07.2016.
                        </td>
                    </tr>
                    <tr className='table-blank-row'></tr>
                </tbody>
            </table>
        </div>
        
        <div className="sm_table_div">       
        <table className='sm_table_70'>
            <tbody>
                <tr className='table-blank-row'></tr>
                <tr>
                    <th colSpan={3}>Angaben zur Einrichtung</th>
                </tr>
                <tr>
                    <td className='td_label_right'>
                        Name der Einrichtung*
                    </td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            value={namedereinrichtung}
                            onChange={setnamedereinrichtung}
                        />                                  
                    </td>
                    <td>
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>
                        Straße*
                    </td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            value={strassedereinrichtung}
                            onChange={setstrassedereinrichtung}
                        />                  
                    </td>
                    <td>
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>
                       Hausnummer*
                    </td>
                    <td>  
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            value={hausnummerdereinrichtung}
                            onChange={sethausnummerdereinrichtung}
                        />                  
                    </td>
                    <td>
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>
                        Postleitzahl*
                    </td>
                    <td>  
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            value={plzdereinrichtung}
                            onChange={setplzdereinrichtung}
                        />                  
                    </td>
                    <td>
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>
                        Ort*
                    </td>
                    <td>  
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            value={ortdereinrichtung}
                            onChange={setortdereinrichtung}
                        />                  
                    </td>
                    <td>
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>
                        Institutskennzeichen*
                    </td>
                    <td> 
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            value={ikdereinrichtung}
                            onChange={setikdereinrichtung}
                        />                   
                    </td>
                    <td>
                        <Tooltip tttitle='Hilfe' tttext='Das Institutionskennzeichen dient der Abrechnung mit den Krankenkassen. 
                            Es handelt sich hierbei um eine neunstellige Zahlenfolge. Bitte erfragen Sie das Institutionskennzeichen bei der Finanzabteilung des Krankenhauses. 
                            Bitte beachten Sie, dass die Regist'>
                        </Tooltip>
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>
                        Standort (wenn abweichend)
                    </td>
                    <td> 
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            value={standortdereinrichtung}
                            onChange={setstandortdereinrichtung}
                        />                   
                    </td>
                    <td>
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>
                    Institutionskennzeichen Standort (wenn abweichend)
                    </td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            value={standortikdereinrichtung}
                            onChange={setstandortikdereinrichtung}
                        />                    
                    </td>
                    <td>
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>
                    Postanschrift
                    </td>
                    <td> 
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            value={postanschriftdereinrichtung}
                            onChange={setpostanschriftdereinrichtung}
                        />                 
                    </td>
                    <td>
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>
                        Bundesland*
                    </td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            value={namedereinrichtung}
                            onChange={setnamedereinrichtung}
                        />                   
                    </td>
                    <td>
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>
                        KV-Bezirk*
                    </td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            value={namedereinrichtung}
                            onChange={setnamedereinrichtung}
                        />                   
                    </td>
                    <td>
                    </td>
                </tr>
                <tr className='table-blank-row'></tr>
                <tr>
                    <td className='td_label_right'>
                        Bei der Einrichtung handelt es sich um...*
                    </td>
                    <td>                 
                        <FormGroup
                            type="radio"                                                    
                            className={'form-control'}
                            label=' ...eine Einrichtung nach § 108 SGB V'
                            onChange={setpara108}
                            value={1}
                            checked={para108==='1'}
                            name="para108"
                        />           
                    </td>
                    <td>
                        <Tooltip tttitle='Hilfe' tttext='Einrichtungen nach § 108 SGB V sind:&lt;br/&gt;(1) Krankenhäuser, die nach den landesrechtlichen Vorschriften als Hochschulklinik anerkannt sind,&lt;br/&gt;(2) Krankenhäuser, die in den Krankenhausplan eines Landes aufgenommen sind (Plankrankenhäuser)&lt;br/&gt;(3) Krankenhäuser, die einen Versorgungsvertrag mit den Landesverbänden der Krankenkassen und den Verbänden der Ersatzkassen abgeschlossen haben' />
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>                    
                    <FormGroup
                            type="radio"                                                    
                            className={'form-control'}
                            label=' ...eine Einrichtung nach § 111 SGB V'
                            onChange={setpara108}
                            value={0}
                            checked={para108==='0'}
                            name="para108"
                        />                               
                    </td>
                    <td>
                        <Tooltip tttitle='Hilfe' tttext='Einrichtungen nach § 111 SGB V sind Vorsorge- und Rehabilitationseinrichtungen. Einrichtungen nach § 111a SGB V sind nicht Bestandteil der Vereinbarung!'/>
                    </td>
                </tr>
                <tr className='table-blank-row'></tr>
                <tr>
                    <td className='td_label_right'>
                    Nimmt die Einrichtung an einem*
                    </td>
                    <td>
                        <FormGroup
                            type="radio"                                                    
                            className={'form-control'}
                            label=' Ja'
                            onChange={setwbverbund}
                            value={1}
                            checked={wbverbund==='1'}
                            name="wbv"
                        />                                                          
                    </td>
                    <td>
                    <Tooltip tttitle='Hilfe' tttext='Zusammenschluss von Klinik(en) und/oder niedergelassenen Fachärzten mit dem Ziel verschiedene Abschnitte der allgemeinmedizinischen Weiterbildung aus einer Hand anzubieten.'/>
                    </td>
                </tr>                
                <tr>
                    <td className='td_label_right'>
                    Weiterbildungsverbund teil?*
                    </td>
                    <td>     
                        <FormGroup
                            type="radio"                                                    
                            className={'form-control'}
                            label=' Nein'
                            onChange={setwbverbund}
                            value={0}
                            checked={wbverbund==='0'}
                            name="wbv"
                        />                                       
                    </td>
                    <td>
                    </td>
                </tr>
                <tr className='table-blank-row'></tr>
                

                <tr>
                    <th colSpan={3}>Ansprechpartner in der Verwaltung der Einrichtung für Rückfragen</th>
                </tr>
                
                <tr>
                    <td className='td_label_right'>
                        Anrede
                    </td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            value={aspanrede}
                            onChange={setaspanrede}
                        />                     
                    </td>
                    <td>
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>
                    Name*
                    </td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            value={aspname}
                            onChange={setaspname}
                        />                     
                    </td>
                    <td>
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>
                    Telefonnummer*
                    </td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            value={asptelefon}
                            onChange={setasptelefon}
                        />                     
                    </td>
                    <td>
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>
                        E-Mail-Adresse*
                    </td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            value={aspmail}
                            onChange={setaspmail}
                        />                     
                    </td>
                    <td>
                    </td>
                </tr>
                <tr className='table-blank-row'></tr>
                <tr><th colSpan={3}>Angaben zum Bewerber</th></tr>
                <tr className='table-blank-row'></tr>
                <tr>
                    <td className='td_label_right'>Titel</td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            onChange={textchanged}
                        />
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>Name*</td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            onChange={textchanged}
                        />
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>Vorname*</td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            onChange={textchanged}
                        />
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>Geburtsdatum*</td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            onChange={textchanged}
                        />
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>Geburtsname</td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            onChange={textchanged}
                        />
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>Geschlecht</td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            onChange={textchanged}
                        />
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>Arztnummer</td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            onChange={textchanged}
                        />
                    </td>
                    <td>
                        <Tooltip tttitle='Hilfe' tttext='Die Arzt-Nr. wird von der zuständigen KV vergeben und ist nur dann vorhanden, 
                        wenn der weiterzubildende Arzt bereits einen Weiterbildungsabschnitt im ambulanten Bereich absolviert hat.'/>
                    </td>
                </tr>
                <tr>
                    <td className='td_label_right'>Datum der Appobation*</td>
                    <td>
                        <FormGroup
                            className={'form-control'}
                            type="text"
                            onChange={textchanged}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    </div> 
  )
}

Antrag.propTypes = {}

export default Antrag