import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const EditVerrechnung = (props) => 
{
    let vid = new URL(window.location.href).searchParams.get("vid");  
    
    const [errortext, seterrortext] = useState(null)
    const [verrechnung, setverrechnung] = useState(null)

    useEffect(() => {
        async function asyncLoadVerrechnung()
        {
            if (vid) 
            {
              
                try {
                    const response = await fetch('/data/verrechnungen/' + vid)
                    if (response.ok) {
                        const result = await response.json();                            
                        setverrechnung(result)
                    }
                    else
                    {
                        seterrortext(response.status + " " + response.statusText)
                    }
                }               
                catch (err) {
                    console.log(err)
                    seterrortext(JSON.stringify(err))
                }
            }
            else {
                seterrortext("Keine Verrechnung gefunden")
            }

        }
        asyncLoadVerrechnung()
    }, [vid]);

    const deleteVerrechnung = ( async () => {
        console.log("delete")
        try
        {
            const response = await fetch('/data/verrechnung/delete/' + vid)
            if (response.ok) {
                const txt = await response.text()
                seterrortext(txt)
            } else {
                seterrortext(response.status + " " + response.statusText)
            }        
        }
        catch (err)
        {
            seterrortext(err)
        }

    })
    
    return (
        <div>
            {errortext}
            <table>
                <tbody>
                    {
                    verrechnung && <>
                        {
                            Object.entries(verrechnung).map((item) => 
                            {
                                return (
                                <tr>
                                    <td>
                                        {""+item[0]}:
                                    </td>
                                    <td>
                                        {""+item[1]}
                                    </td>                                        
                                </tr> )
                            })
                        } 
                        <tr>
                            <td>
                                <button
                                    onClick={deleteVerrechnung}
                                >
                                    Löschen
                                </button>
                            </td>
                            <td>
                            </td>
                        </tr>                   
                    </>
                    }
                </tbody>
            </table>           
        </div>);
};

EditVerrechnung.propTypes = {};

export default EditVerrechnung;
