import React from 'react'
import PropTypes from 'prop-types'
import { BsArrowUpShort, BsArrowDownShort } from 'react-icons/bs';
import { CgArrowsExchangeV } from 'react-icons/cg';

const SortableTableHeader = props => 
{
  return (
    <th 
      className={props.className}
      onClick={props.onClick}>{props.title} {(props.lastSort === props.title ? (props.sortOrder ? <BsArrowUpShort/> : <BsArrowDownShort/>) : <CgArrowsExchangeV/>)}      
    </th>
  )
}

SortableTableHeader.propTypes = {
  onClick : PropTypes.func,
  title : PropTypes.string,
  lastSort : PropTypes.string,
  sortOrder : PropTypes.bool,
  className : PropTypes.string
}

export default SortableTableHeader