import RechnungsListePage from './RechnungsListePage'

const RechnungsListe = () => 
{
    return (
        <div>
            <RechnungsListePage />                 
        </div>
    )
}

export default RechnungsListe
