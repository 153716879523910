export function getFuseOptions(threshold, minMatchCharLength, keys)
{
    // fuseOptions,
    // To know more about fuse params, visit https://fusejs.io/
    // default values
    const result = {
       shouldSort: true,
       threshold: 0.6,
       location: 0,
       distance: 200,
       maxPatternLength: 32,
       minMatchCharLength: 1,
       keys: [
         "name",
       ]
    }

    if (threshold) {
        result.threshold = threshold
    }
    if (minMatchCharLength) {
        result.minMatchCharLength = minMatchCharLength
    }
    if (keys) {
        if (Array.isArray(keys)) {
            result.keys = keys
        }
        else {
            result.keys = [keys]
        }
    }
    return result
}