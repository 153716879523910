import React, { useState, useEffect} from 'react'
import { AiOutlineEdit } from 'react-icons/ai';
import { HiOutlineDocumentAdd } from 'react-icons/hi';

import ConfigIcon from '../../util/configicon';
import FormGroup from '../../components/shared/form-group'
import { selectForEinwilligungsUmfangCombo } from '../../util/combo-utils'
// import { toShortDateString } from '../../util/date-utils.js'
import { authenticationService } from '../../services';
import StorageService from '../../services/StorageService';

function BeschaeftigungListPage(props) {   

    const [einwilligungsList, setEinwilligungsList] = useState(null)
    const [canEdit] = useState(authenticationService.currentUser.category > 0)
    
    useEffect( () => {
        async function asyncFetchEinwilligungsList()
        {   
            setEinwilligungsList(await selectForEinwilligungsUmfangCombo())
        }
        if (!einwilligungsList) {
            asyncFetchEinwilligungsList()                    
        }
    }, [einwilligungsList])

    const neueMassnahme = (evt) => {
        const bid = evt.currentTarget.getAttribute('beschaeftigungsid');
        const eid = evt.currentTarget.getAttribute('einrichtungsid');
        // console.log(props)
        // console.log("Neue Maßnahme BID: " + bid + " EID: " + eid + " TID " + props.tid + props.teilnehmer)
        // const ids = 
        // {
        //     bid : bid,
        //     eid : eid,
        //     tid : props.tid,
        //     teilnehmer : props.teilnehmer, 
        // }
        // localStorage.setItem(StorageService.TeilnehmerEinrichtungsData, JSON.stringify(ids))
        window.location.href = "/Teilnehmer/Overview?tid="+props.tid + "&eid=" + eid
        // window.location.href="/MassnahmenProTeilnehmer"
    }

    
    const beschaeftigungBearbeiten = (evt) => {               
        const bid = evt.currentTarget.getAttribute('beschaeftigungsid');                
        // console.log(props.tid)
        // console.log(bid)
        // console.log(props.liste[0].Beschaeftigungen)
        window.location.href="/Beschaeftigung/Edit?tid=" + props.tid + "&bid=" + bid
    }

    const beschaeftigungNeu = (evt) => {
        window.location.href="/Beschaeftigung/New?tid=" + props.tid
    }    

    return (
        <div className="sm_table_div"> 
            <table className="sm_table_detail">  
            {/* className="sm_table_90" >   */}
                <thead>   
                    <tr style={{textAlign:'left'}}>
                        <th colSpan="4" className="th_fitwidth">Besch&auml;ftigungen</th>
                        <th>
                            {props.tid && 
                                <button 
                                    className="exportbtn" 
                                    title="Neue Beschäftigung" 
                                    onClick={beschaeftigungNeu}
                                    disabled={!canEdit} 
                                >
                                    <ConfigIcon><HiOutlineDocumentAdd/></ConfigIcon>&nbsp;Neu
                                </button>
                            }
                        </th>
                    </tr>     
                    <tr className="table_blank_row"></tr>             
                    <tr style={{textAlign:'left'}}>                        
                        <th className="th_fitwidth">Einrichtung</th>
                            {/* style={{'border':'1px solid blue', }} */}
                        <th className="th_fitwidth">Evaluation</th> 
                        <th className="th_fitwidth">Einwilligungsumfang</th>
                        <th className="th_fitwidth">Etikett</th>
                        {/* <th className="th_fitwidth">Beschäftigungsbeginn</th> */}
                        <th className="th_fitwidth">Aktion &nbsp;&nbsp;                   
                        </th>
                    </tr>
                    <tr className="table_blank_row"></tr>             
                </thead>
                <tbody>                    
                    {
                        // Beschaeftigungen.einwilligungEvaluation, Beschaeftigungen.einwilligungsUmfang, 
                        // Beschaeftigungen.etikettBenoetigt

                        props.isLoaded === true && props.liste && props.liste.length > 0 && 
                        props.liste.map( ({ id, kurzbezeichnung, Beschaeftigungen, 
                             }) => { //, beginnBeschaeftigung}) => {                                        
                            return (
                                <tr key={id}>
                                    <td className="td_fitwidth">{kurzbezeichnung}</td>
                                    <td className="td_fitwidth">
                                        {Beschaeftigungen.einwilligungEvaluation ? "Ja" : "Nein"}                                                                                
                                    </td>
                                    <td className='td_fitwidth'>
                                        {einwilligungsList ? einwilligungsList[Beschaeftigungen.einwilligungsUmfang].value : ""}
                                    </td>
                                    {/* <td className="td_input">
                                        <FormGroup                                            
                                            type="select"
                                            name="einwiligung"
                                            options={einwilligungsList}
                                            value={Beschaeftigungen.einwilligungsUmfang}                            
                                            readonly
                                        />                                         
                                    </td> */}
                                    <td className="td_fitwidth">
                                        {Beschaeftigungen.etikettBenoetigt ? "Ja" : "Nein" }                                      
                                    </td>
                                    {/* <td className="td_fitwidth">{toShortDateString(beginnBeschaeftigung)}</td> */}
                                    <td className='td_button'>
                                        <button 
                                            className="exportbtn"
                                            title="Bearbeiten der Beschäftigung" 
                                            einrichtungsid={id} 
                                            beschaeftigungsid={Beschaeftigungen.bid} 
                                            onClick={beschaeftigungBearbeiten}
                                            disabled={!canEdit} 
                                        >
                                            <ConfigIcon><AiOutlineEdit/></ConfigIcon>&nbsp;Bearbeiten
                                            {/* <img src="edit.png" alt="edit" /> */}
                                        </button>                                                                       
                                    </td>   
                                    <td className='td_button'>
                                        <button 
                                            className="exportbtn"  
                                            title="Ma&szlig;nahmen&uuml;bersicht dieser Einrichtung"                                       
                                            einrichtungsid={id} 
                                            beschaeftigungsid={Beschaeftigungen.bid} 
                                            onClick={neueMassnahme}
                                            disabled={!canEdit}
                                        >
                                            <ConfigIcon><HiOutlineDocumentAdd/></ConfigIcon>&nbsp;Ma&szlig;nahmen &Uuml;bersicht
                                        </button>
                                    </td>                                 
                                </tr>                                
                            )
                        })
                    }    
                    {
                        props.isLoaded === false &&
                        <tr><td>{props.isLoaded}</td><td></td><td></td><td></td><td></td><td></td></tr>
                    }                
                </tbody>
            </table>            
        </div>
    )
}

export default BeschaeftigungListPage


