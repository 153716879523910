
import { formatAnsprechpartner, formatIBAN } from '../util/formate';


export async function selectForStatusCombo()
{
    const defaultError = [{
        key : 0,
        value: "",
    }]
    try {
        const response = await fetch('/data/massnahmenstatus/select')
        if (response.ok) {
            const result = await response.json();    
            if (Array.isArray(result))
            {
                // setMassnahmenStatus(result)
                return result
            }
            defaultError[0].value = "E:" + result.name
            throw new Error(result.name)
        }
        else
        {
            defaultError[0].value = "E:" + response.status                                        
            throw new Error(response.status + " " + response.statusText)
        }
    }
    catch (err) {        
        console.log(err)
        return defaultError // setMassnahmenStatus(defaultError)
    }
}

export async function selectForEinrichtungsartCombo()
{
    const defaultError = [{
        key : 0,
        value: "",
    }]
    try {
        const response = await fetch('/data/einrichtungsarten/select')
        if (response.ok) {
            const result = await response.json();    
            if (Array.isArray(result))
            {
                return result;
            }
            defaultError[0].value = "E:" + result.name
            throw new Error(result.name)                                    
        }
        else
        {
            defaultError[0].value = "E:" + response.status
            throw new Error(response.status + " " + response.statusText)
        }
    }
    catch (err) {        
        console.log(err)
        return defaultError;
    }
}

export async function selectForAspCombo(einrichtungsId)
{
    const defaultError = [{
        key : 0,
        value: "",
    }]
    try
    {        
        const response = await fetch('/data/ansprechpartner/select/' + einrichtungsId)
        if (response.ok) {
            const result = await response.json();    
            if (Array.isArray(result))
            {
                // console.log("ansprechpartner " + JSON.stringify(result))
                result.forEach(element => {
                    const val = formatAnsprechpartner(element, "atn")
                    // console.log(val)
                    element.value = val                              
                });
                return result;
            }
            defaultError[0].value = "E:" + result.name
            throw new Error(result.name)                                    
        }
        else
        {
            defaultError[0].value = "E:" + response.status
            throw new Error(response.status + " " + response.statusText) 
        }
    }
    catch (err)
    {
        console.log(err)
        return defaultError
    }
}

export async function selectForFachgebietComboAll()
{
    return _selectForFachgebietCombo('/data/fachgebiete/selectall/')    
}

export async function selectForFachgebietCombo()
{
    return _selectForFachgebietCombo('/data/fachgebiete/selectall/')
}

async function _selectForFachgebietCombo(path)
{
    const defaultError = [{
        key : 0,
        value: "",
    }]
    try
    {        
        const response = await fetch(path)
        if (response.ok) {
            const result = await response.json();    
            if (Array.isArray(result))
            {
                return result;
            }
            defaultError[0].value = "E:" + result.name
            throw new Error(result.name)                                    
        }
        else
        {
            defaultError[0].value = "E:" + response.status
            throw new Error(response.status + " " + response.statusText) 
        }
    }
    catch (err)
    {
        console.log(err)
        return defaultError
    }
}

export async function selectForKontenCombo(einrichtungsId)
{
    const defaultError = [{
        key : 0,
        value: "",
    }]
    try {
        const response = await fetch('/data/konten/select/' + einrichtungsId)
        if (response.ok) {
            const result = await response.json();    
            if (Array.isArray(result))
            {
                result.forEach( (item) => (item.value = formatIBAN(item.value)))                              
                return result;
            }
            defaultError[0].value = "E:" + result.name
            throw new Error(result.name)                                    
        }
        else
        {
            defaultError[0].value = "E:" + response.status
            throw new Error(response.status + " " + response.statusText)
        }
    }
    catch (err) {
        // setEinrichtungsArtData(defaultError)
        console.log(err)
        return defaultError;
    }
}

// export async function selectForTeilnehmerCombo(einrichtungsId)
// {
//     const defaultError = [{
//         key : 0,
//         value: "",
//     }]
//     try {
//         const response = await fetch('/data/teilnehmer/datalist/' + einrichtungsId)
//         if (response.ok) {
//             const result = await response.json();    
//             if (Array.isArray(result))
//             {
//                 // setEinrichtungsArtData(result)
//                 // console.log("EinrichtungsArtData " + einrichtungsArtData)
//                 return result;
//             }
//             defaultError[0].value = "E:" + result.name
//             throw new Error(result.name)                                    
//         }
//         else
//         {
//             defaultError[0].value = "E:" + response.status
//             throw new Error(response.status + " " + response.statusText)
//         }
//     }
//     catch (err) {
//         // setEinrichtungsArtData(defaultError)
//         console.log(err)
//         return defaultError;
//     }
// }


export async function selectForGeschlechtCombo()
{
    const defaultError = [{
        key : 0,
        value: "",
    }]
    try {
        const response = await fetch('/data/geschlecht/select/')
        if (response.ok) {
            const result = await response.json();    
            if (Array.isArray(result))
            {
                // setEinrichtungsArtData(result)
                // console.log("EinrichtungsArtData " + einrichtungsArtData)
                return result;
            }
            defaultError[0].value = "E:" + result.name
            throw new Error(result.name)                                    
        }
        else
        {
            defaultError[0].value = "E:" + response.status
            throw new Error(response.status + " " + response.statusText)
        }
    }
    catch (err) {
        // setEinrichtungsArtData(defaultError)
        console.log(err)
        return defaultError;
    }
}

export async function selectForEinwilligungsUmfangCombo()
{

    const defaultError = [{
        key : null,
        Beschreibung: "Error",                
    }]

    try {                                
        const response = await fetch('/data/einwilligungsumfaenge/select')
        if (response.ok) {
            const result = await response.json();    
            if (Array.isArray(result))
            {
                // console.log(result)
                // setEinwilligungsList(result)                        
                return result;
            }
            defaultError[0].Beschreibung = "E:" + result.name
            throw new Error(result.name)                                    
        }
        else
        {
            defaultError[0].Beschreibung = "E:" + response.status
            throw new Error(response.status + " " + response.statusText)
        }
    }
    catch (err) {        
        console.log(err)
        // setEinwilligungsList(defaultError)
        return defaultError
    }
}
