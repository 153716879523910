import React, { useState } from 'react'
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { HiOutlineDocumentAdd } from 'react-icons/hi';


import ConfigIcon from '../../util/configicon';
import { formatIBAN } from "../../util/formate";
import { authenticationService } from '../../services';


function KontenListPage(props) {   

    const [canEdit] = useState(authenticationService.currentUser.category > 0)
    const [selectedKontoIndex, setSelectedKontoIndex] = useState(0)
    
    const kontoBearbeiten = (evt) => {        
        let kontoid = evt.currentTarget.getAttribute('kontoid');
        if (!kontoid) 
        {
            kontoid = selectedKontoIndex
        }
        if (kontoid)
        {
            // console.log("Bearbeite Konto '" + kontoid + "' zur Einrichtung '" + props.eid + "'");
            window.location.href="/Konto/Edit?eid=" + props.eid + "&kontoid=" + kontoid
        }
    }

    const kontoNeu = (evt) => {
        // console.log(evt.target)
        // console.log("Neues Konto zur Einrichtung '" + props.eid + "'");
        window.location.href="/Konto/New?eid=" + props.eid
    }

    const kontoDelete = (evt) => {
        if (props.eid) {            
            const kontoid = Number.parseInt(evt.currentTarget.getAttribute('kontoid'));
            if (props.callback && !Number.isNaN(kontoid)) {
                props.callback('delete', kontoid)
            }            
        }
    }

    const kontoRowClick = ((evt) => {    
        const idattribute = evt.target.parentElement.attributes['id']
        if (idattribute) {
            const val = idattribute.value
            setSelectedKontoIndex(Number.parseInt(val))
        }
    })

    return (
        <div className="sm_table_detail_div">
            <table className="sm_table_detail" > 
                <thead>
                    <tr style={{textAlign:'left'}}>
                        <th colSpan={1} className="th_fitwidth">Kontonummern</th>
                        <th colSpan={2}>
                            {props.eid && <>
                                    <button className="exportbtn" title="Neues Konto" onClick={kontoNeu} disabled={!canEdit}>
                                        <ConfigIcon><HiOutlineDocumentAdd/></ConfigIcon>
                                        &nbsp;Neu
                                    </button>&nbsp;
                                    <button className="exportbtn" title="Bearbeiten" onClick={kontoBearbeiten} disabled={!canEdit || selectedKontoIndex === 0}>
                                        <ConfigIcon><AiOutlineEdit/></ConfigIcon>
                                        &nbsp;Bearbeiten                                           
                                    </button>   
                                </>   
                            }
                        </th>
                        <th></th>
                    </tr>
                    <tr style={{textAlign:'left'}}>
                        <th className="th_fitwidth">IK</th>
                        <th className="th_fitwidth">IBAN</th>
                        <th className="th_fitwidth">Gültig</th>
                        <th className="th_fitwidth">Aktiv</th>
                        { authenticationService.isAdmin && <th className="th_fitwidth">Aktion&nbsp;&nbsp;</th> }
                    </tr>
                </thead>
                <tbody>                    
                    {
                        // !data || 
                        props.isLoaded === true && props.liste && props.liste.length > 0 && 
                        props.liste.map( ({ id, ik, iban, iban_isok, inaktiv}) => {                                        
                            return (
                                <tr id={id} key={id} onClick={kontoRowClick} style={ (id===selectedKontoIndex) ? {backgroundColor:'#DDD'} : {} }>
                                    <td className="td_fitwidth td_border">{ik}</td>
                                    
                                    <td className={(iban_isok!==0 ? "td_fitwidth" : "td_fitwidth td_fitwidtherror") + " td_border"}>{formatIBAN(iban)}</td>
                                    <td className="td_fitwidth td_border">{iban_isok!==0 ? `ja` : `nein`}</td>
                                    <td className="td_fitwidth td_border">{inaktiv!==0 ? `nein` : `ja`}</td>
                                    {/* <td>
                                        <button className="exportbtn" title="Bearbeiten" kontoid={id} onClick={kontoBearbeiten} disabled={!canEdit}>
                                            <ConfigIcon><AiOutlineEdit/></ConfigIcon>
                                            &nbsp;Bearbeiten                                           
                                        </button>                                                                                                  
                                    </td> */}
                                    { authenticationService.isAdmin && 
                                    <td className='td_button'>                                       
                                        <button className="exportbtn adminbtn" title="Delete" kontoid={id} onClick={kontoDelete}>
                                            <ConfigIcon><AiOutlineDelete/></ConfigIcon>
                                            &nbsp;Löschen                             
                                        </button>                                                                            
                                    </td>
                                    }
                                </tr>
                                )
                        })
                    }    
                    {
                        props.isLoaded === false &&
                        <tr><td></td><td></td><td></td><td></td><td></td></tr>
                    }                
                </tbody>
            </table>            
        </div>
    )
}

export default KontenListPage


