import React, { useEffect, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai';

import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { getReactSearchAutocompleteStyle } from '../../util/style';
import { formatTeilnehmer } from '../../util/formate'
import ConfigIcon from '../../util/configicon';
import FetchService from '../../services/FetchService';
import MassnahmenProTeilnehmerListe from './MassnahmenProTeilnehmerListe'
// import { StorageService } from '../../services';
// import { toValidJsonObject } from '../../util/json2array';
// import { getFuseOptions } from '../../util/search';

const MassnahmenProTeilnehmer = (props) => {

    const storageIdKey = "massnahmenProTeilnehmerId";
    let storageIdValue = localStorage.getItem(storageIdKey);
    const storageDataKey = "massnahmenProTeilnehmerSearchData";
    let storageDataValue = localStorage.getItem(storageDataKey);

    // const storageParamKey = toValidJsonObject(localStorage.getItem(StorageService.TeilnehmerEinrichtungsData));
    // if (storageParamKey) {
    //     storageIdValue = storageParamKey.tid        
    //     storageDataValue = storageParamKey.teilnehmer                
    // }

    const [teilnehmerId, setTeilnehmerId] = useState(storageIdValue)
    const [teilnehmerData, setTeilnehmerData] = useState(storageDataValue ? storageDataValue : "")
    const [teilnehmerLoaded, setTeilnehmerLoaded] = useState(false)
    const [einrichtungenId, setEinrichtungenId] = useState(null)

    const [tlist, setTlist] = useState( [{id:0,name:""}]);    
    useEffect(() => {
        async function loadTlist()
        {
            const res = await FetchService.loadTeilnehmerDatalist(null)       
            if (Array.isArray(res))
            {                   
                let lst = []
                res.forEach(function(item) 
                {   
                    const val = { id:item.id, name: formatTeilnehmer(item) } 
                    lst.push(val)
                });      
                setTlist(lst)                   
            }
        }
        loadTlist()
    }, [])

    useEffect(() => {
        async function loadParams()
        {
            if (storageDataKey && storageDataValue) {                
                sucheStarten()
            }
            // if (storageParamKey) {
            //     console.log("MassnahmenProTeilnehmer starte suche from")
            //     setEinrichtungenId(storageParamKey.eid)                
            //     localStorage.setItem(StorageService.TeilnehmerEinrichtungsData, null);
            //     sucheStarten()
            // }
        }
        loadParams()       
    }, [])

    const formatResult = (item) => { return item.name; } 

    const handleOnSelectTid = async (item) => {  // the item selected
        // console.log("handleOnSelectTid " + JSON.stringify(item))
        localStorage.setItem(storageIdKey, item.id); 
        localStorage.setItem(storageDataKey, item.name); 
        // console.log(props)
        setTeilnehmerId(item.id)
        setTeilnehmerData(item.name)
        setEinrichtungenId(null)
        setTeilnehmerLoaded(false)
    }

    const handleOnClearTid = () => { 
        setTeilnehmerId(null);        
        setTeilnehmerData(""); 
        setTeilnehmerLoaded(false)
        // StorageService.updateMptData(null)
        // setSortOrder(false)            
        // setLastSort(null)
    }

    const sucheStarten = () => {
        if (teilnehmerLoaded) 
            setTeilnehmerLoaded(false)
        setTeilnehmerLoaded(true)
    }

    // const reloadData = () => {
    //     console.log("Reloaddata")
    //     const tid = teilnehmerId
    //     setTeilnehmerId(null)
    //     setTeilnehmerId(tid)
    // }

    return (
        <>
            <div className="sp_table_div">
            <table className="sm_table_60">
                <thead>         
                    <tr>
                        <th colSpan="8"><h3>Ma&szlig;nahmen pro Teilnehmer</h3></th><th></th><th></th><th></th>                        
                    </tr>           
                </thead>
                <tbody>
                    <tr className="table-blank-row"></tr>
                    <tr>  
                        {/* <td className="td_label">Alle Massnahmen einer Einrichtung:</td> */}
                        <td className="td_input">
                            <div id="div_reactsearchautocomplete_eid" className="rsac_eid">
                                <ReactSearchAutocomplete                                                                             
                                    className="rsac"     
                                    items={tlist}                                
                                // fuseOptions={getFuseOptions(0.4, 3)}
                                // onSearch={handleOnSearch}
                                // onHover={handleOnHover}
                                    onSelect={handleOnSelectTid}
                                // onFocus={handleOnFocus}
                                    onClear={handleOnClearTid}
                                    autoFocus
                                    formatResult={formatResult}
                                    inputSearchString={teilnehmerData} // "" // {einrichtungsData}
                                    showIcon={false}
                                    placeholder="Teilnehmername oder -ID eingeben"
                                    styling={getReactSearchAutocompleteStyle()}
                                    maxResults={20}
                                />
                            </div>
                        </td>
                        {/* { einrichtungsId && <td className="td_label_center"><a href={`/Einrichtung/Edit?eid=${einrichtungsId}`}>{einrichtungsId}</a></td> } */}
                        <td>
                            <button className="exportbtn" onClick={sucheStarten}>
                                <ConfigIcon><AiOutlineSearch/></ConfigIcon>&nbsp;Suche starten                                
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>            
            { teilnehmerLoaded &&          
                <MassnahmenProTeilnehmerListe tid={teilnehmerId} eid={einrichtungenId}></MassnahmenProTeilnehmerListe>
            }
        </>
    )
}

export default MassnahmenProTeilnehmer
