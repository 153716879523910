import React, {useState, useEffect } from 'react'
import FileSaver from 'file-saver'

import SortableTableHeader from '../../components/shared/tables/SortableTableHeader'
import Spinner from '../../components/shared/spinner/Spinner'
import ColumnSorter from '../../util/ColumnSorter'
import FormGroup from '../../components/shared/form-group'


const EditVorlagen = () => {

    const [list, setlist] = useState(null)
    const [selectedvorlageid, setselectedvorlageid] = useState(0)
    // const [selectedvorlage, setselectedvorlage] = useState(null)
    const [selectedfilename, setSelectedfilename] = useState(null)
    const [selectedgenfilename, setSelectedgenfilename] = useState(null)
    const [selectedtype, setSelectedtype] = useState(null)
    const [selectedgroup, setSelectedgroup] = useState(null)
    const [selectedfile, setSelectedfile] = useState(null)

    const [loadstate, setLoadstate ] = useState(1)
    const [columnsSorter, setColumnSorter] = useState(null)    
    const [sortOrder , setSortOrder] = useState(false)
    const [lastSort , setLastSort] = useState(null)
    const [message, setMessage] = useState(null)
    const [reload, setreload] = useState(true)

    const _setlist = (lst) => {
        setlist(lst)
    //  setlistlength( (lst ? lst.length : 0))
    } 

    useEffect(() => {
        async function load()
        {
            try 
            {
                _setlist(null)
                // const data = {
                //     nachname : optionnachname,
                //     massnahmen : optionmassnahmen,
                //     duplicatemail : optionduplicatemail
                // }
                setLoadstate(2)
                // setselectedvorlageid(0)
                // setSelectedgroup(null)
                const response = await fetch(
                    '/data/admin/vorlagen/last',
                    {
                        headers: {                    
                            'Content-Type': 'application/json'
                        },
                        method: 'GET',
                        // body: JSON.stringify(data),
                    }
                );
                if (response.ok) {
                    const result = await response.json();                        
                    if (Array.isArray(result))
                    {      
                        // console.log(result[0])                  
                        _setlist(result)                                                 
                        setColumnSorter(new ColumnSorter(result, 
                            [
                                [ 'ID' , 'id'],
                                [ 'Vorlagen Dateiname' , 'filename'],
                                [ 'Ziel Dateiname', 'generated_filename'],
                                [ 'Typ', 'Mimetype.mimetype'],
                                [ 'Download' , 'X']
                            ]
                        ))      
                        setSortOrder(false)                    
                        setLastSort(null)                        
                        setLoadstate(0)                        
                        return;
                    }
                    // console.log("E:" + result.name)
                    throw new Error(result.name)                                    
                }
                else
                {
                    // console.log("E:" + response.status)
                    throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                setMessage("" + err)
                // console.log(err)
            }
            setLoadstate(1)
        }
        load()      
    }, [reload])

    const columnclick = (evt) => {
        if (columnsSorter) {
            const res = columnsSorter.sortColumn(evt)
            if (res) {                
                setlist(columnsSorter.rdata)
                setSortOrder(columnsSorter.sortOrder)
                setLastSort(columnsSorter.lastSort)
            }
        }
    }

    const onDownload = ( async (e) => {
        e.preventDefault()
        const href = e.currentTarget.getAttribute('href')
        if (href && href.length > 1) {
            const docid = Number.parseInt( href.substring(1) )            
            if (!Number.isNaN(docid))
            {
                const res = await fetch ("data/admin/vorlagen/download/" + docid)                
                if (res.status === 200) {             
                    const json = await res.json()
                    // console.log(json.buffer.data)
                    const ar = new Uint8Array(json.buffer.data)                                                
                    const blob = new Blob([ar] ,{ type:"application/octet-stream" });                                   
                    FileSaver.saveAs( blob, json.filename );                    
                }
                else {
                    alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
                }                
            }     
        }
    })

    useEffect(() => 
    {             
        if (list && list.length>0) {
            const vorlage = list.find( (item) => { return item.id === selectedvorlageid} )
            if (vorlage)
            {
                setSelectedfilename(vorlage.filename)
                setSelectedgenfilename(vorlage.generated_filename)
                setSelectedtype(vorlage.Mimetype.mimetype)
                setSelectedgroup(vorlage.gruppe)                       
                // console.log(vorlage)
            }
        } 
        else {            
            setSelectedgroup(0)
        }
    }, [selectedvorlageid])    

    const vorlageClick = ((evt) => {    
        const idattribute = evt.target.parentElement.attributes['id']
        if (idattribute) {
            const val = Number.parseInt(idattribute.value)
            if (val !== selectedvorlageid) {
                setselectedvorlageid(val)
            }
        }
    })

    const onUpload = ( (evt) => {
        // console.log(evt.target.files)
        const file = evt.target.files[0]
        if (file)
        {
            setSelectedfile(file)
            setSelectedfilename(file.name)
            setSelectedtype(file.type)
        }
    })

    const onChangeGeneratedFilename = ( (evt) => {
        // console.log(evt)
        setSelectedgenfilename(evt)
    })

    const onChangeType = ( (evt) => {
        // console.log(evt)
        setSelectedtype(evt)
    })

    const btnNeuClick = (evt) => {        
        evt.preventDefault()
        console.log("New Vorlage")
        setselectedvorlageid(0)
        setSelectedtype(null)
        setSelectedfilename(null)
    }

    const btnDeleteClick = async (evt) => {
        evt.preventDefault()                
        if (selectedvorlageid)
        {
            console.log("Delete Vorlage")            
            try {
                const url = '/data/admin/vorlagen/delete/' + selectedvorlageid            
                const response = await fetch(url, { method: 'DELETE' });
                const res = await response.text();
                if (response.ok && response.status === 200) {                                    
                    if (res.startsWith("OK"))
                    {
                        setselectedvorlageid(Number.parseInt(res.substring(3)))
                        setreload(!reload)
                    }
                    return;
                }
                console.log(""+response.status + " " + response.statusText);
            }
            catch(err) {
                console.log(""+err );
            }
        }
    }
    
    const btnSaveClick = async (evt) => {
        evt.preventDefault()
        if (!selectedfilename)
        {
            alert("Bitte eine Datei auswählen!")
            return
        }        
        if (selectedvorlageid)
        {
            console.log("Save Record")
            let data = {}
            data.filename = selectedfilename
            data.generated_filename = selectedgenfilename
            data.mimetype_text = selectedtype
            data.gruppe = selectedgroup
            data.id = selectedvorlageid
            console.log(data)
            try {
                const url = '/data/admin/vorlagen/update'            
                const response = await fetch(url, {
                    headers: {                    
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify(data),
                });
                const res = await response.text();
                if (response.ok && response.status === 200) {                                    
                    if (res.startsWith("OK"))
                    {
                        setreload(!reload)
                    }
                    return;
                }
                console.log(""+response.status + " " + response.statusText);
            }
            catch(err) {
                console.log(""+err );
            }
        }
        else
        {
            //console.log("Save New")
            let form = document.getElementById('uploadform')            
            //console.log(form)
            const formData = new FormData(form)
            //console.log(formData)           
            try {
                const url = '/data/admin/vorlagen/insert'            
                const response = await fetch(url, {                    
                    method: 'PUT',
                    body: formData,
                });
                const res = await response.text();
                if (response.ok && response.status === 200) {  
                    if (res.startsWith("OK"))
                    {
                        setselectedvorlageid(Number.parseInt(res.substring(3)))
                        setreload(!reload)
                    }                                 
                    return;
                }
                console.log(""+response.status + " " + response.statusText);
            }
            catch(err) {
                console.log(""+err );
            }
        }
    }

    const btnTestClick = async(evt) => {
        
        evt.preventDefault()
        console.log("Save TEST")
        let form = document.getElementById('testform')
        // let testupload = document.getElementById('testuploadX')
        // console.log(testupload)
        // console.log(testupload.files)
        // console.log(form)
        const formData = new FormData(form)      
        
        
        try {
            const url = '/data/admin/vorlagen/insert'            
            const response = await fetch(url, {                
                method: 'PUT',
                body: formData,
            });
            const res = await response.text();
            if (response.ok && response.status === 200) {  
                if (res.startsWith("OK"))
                {
                    res.setreload(!reload)
                }                                 
                return;
            }
            console.log(""+response.status + " " + response.statusText);
        }
        catch(err) {
            console.log(""+err );
        }    
    }


  return (
    <div>
        {loadstate === 0 && <div className='table-fixed-header' style={{height:'800px'}}> 
        <table className='sm_table'>
            <thead>
                <tr>                    
                    <SortableTableHeader className={'td_label'} onClick={columnclick} title='ID' lastSort={lastSort} sortOrder={sortOrder} />
                    <SortableTableHeader className={'td_label'} onClick={columnclick} title='Vorlagen Dateiname' lastSort={lastSort} sortOrder={sortOrder} />
                    <SortableTableHeader className={'td_label'} onClick={columnclick} title='Ziel Dateiname' lastSort={lastSort} sortOrder={sortOrder} />
                    <SortableTableHeader className={'td_label'} onClick={columnclick} title='Typ' lastSort={lastSort} sortOrder={sortOrder} />                    
                    <SortableTableHeader className={'td_label'} title='Download' />                    
                </tr>
            </thead>
            <tbody>
                { list && list.length>0 && list.map( ({id, filename, generated_filename, Mimetype }) => {
                    return (
                        <tr key={id} id={id} onClick={vorlageClick} style={ (id===selectedvorlageid) ? {backgroundColor:'#DDD'} : {}}>{/* className='liste'  */}
                            <td className='td_fitwidth'>
                                {/* <Navlink link={`Ansprechpartner/Edit?eid=${einrichtungen_id}&aspId=${id}`} title={""+id} /> */}
                                {/* <a href={`Ansprechpartner/Edit?eid=${einrichtungen_id}&aspId=${id}`}>{id}</a> */}
                                {id}
                            </td>
                            <td className='td_fitwidth'>
                                {filename}
                            </td>
                            <td className='td_fitwidth'>
                                {generated_filename}
                            </td>
                            <td className='td_fitwidth'>
                                {Mimetype.mimetype}
                            </td>
                            <td className='td_fitwidth'>
                                {id ? <a onClick={onDownload} href={`#${id}`}>Datei</a> : ''}                                
                            </td>                            
                        </tr>
                    )
                })
            }   
            </tbody>
        </table>
        { selectedgroup !== 0 && <form id='uploadform' onSubmit={btnSaveClick} method='POST' encType="multipart/form-data">
            <div className='sm_table_div'>
                <table className='sm_table_60'>
                <colgroup>
                    <col style={{"width":"40%"}} />
                    <col style={{"width":"60%"}} />            
                </colgroup>
                <tbody>
                    <tr className='table-blank-row'></tr>                
                    <tr>
                        <td className='td_label'>
                            Vorlagen Dateiname
                        </td>
                        <td className='td_label'>
                            <FormGroup 
                                type='upload' 
                                name='upload' 
                                accept='.doc,.xls,.docx,.xlsx,.dot,.xlt,.dotx,.xltx' 
                                onChange={onUpload} 
                                value={selectedfilename}>                            
                            </FormGroup>
                        </td>
                    </tr>
                    <tr>
                        <td className='td_label'>
                            Ziel Dateiname
                        </td>
                        <td className='td_label'>
                            <FormGroup 
                                type='text' 
                                name='generated_filename' 
                                onChange={onChangeGeneratedFilename} 
                                value={selectedgenfilename}>
                                
                                </FormGroup>
                        </td>
                    </tr>
                    <tr>
                        <td className='td_label'>
                            Typ
                        </td>
                        <td className='td_label'>
                            <FormGroup 
                                type='text' 
                                name='mimetype_text' 
                                onChange={onChangeType} 
                                readonly 
                                value={selectedtype}>                            
                            </FormGroup>
                        </td>
                    </tr>
                    <tr>
                        <td>Gruppe</td><td>{selectedgroup}<FormGroup type='hidden' name='gruppe' value={selectedgroup}></FormGroup></td>
                    </tr>
                    <tr>
                        <td>ID</td><td>{selectedvorlageid}<FormGroup type='hidden' name='id' value={selectedvorlageid}></FormGroup></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td><button className='exportbtn' onClick={btnNeuClick}>Neu</button></td>
                                        <td><button className='exportbtn'>Speichern</button></td> 
                                        { selectedvorlageid % 100 !== 0 && <td>
                                            <button className='exportbtn' onClick={btnDeleteClick}>Löschen</button>
                                        </td>     
                                        }
                                        {/* onClick={btnSaveClick} */}
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </form>
        }        
        </div>
        }
        { loadstate===1 && <>{message}</>}           
        { loadstate===2 && <Spinner /> }
    </div>
  )
}

export default EditVorlagen


{/* <form id='testform' onSubmit={btnTestClick} method='POST' encType="multipart/form-data">
            <input type='file' id='testuploadX' name='testuploadN'></input>
            <input type='hidden' name='id' value='1702'></input>
            <input type='hidden' name='gruppe' value='8'></input>
            <input type='submit'></input>
        </form> */}