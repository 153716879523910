
export async function validateIban(iban) {
    const result = {
        msg : "OK"
    }

    try {
        const response = await fetch("/data/validation/iban",
        {
            headers:{
                'Content-Type':'application/json'
            },
            method:'POST',
            body: "{ \"iban\":\"" + iban + "\"}"
        })
        if (response.ok) {            
            const res = await response.text()            
            result.msg = res
        }
        else
        {
            console.log("response fail")
            result.msg = response.status + " " + response.statusText
        }
    }
    catch (err) {
        console.log(err)
        result.msg = "Error"
    }
    return result.msg
}