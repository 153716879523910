import React, { useEffect, useState } from 'react'

import FetchService from '../../services/FetchService';
import FormGroup from '../../components/shared/form-group'
import { formatAnsprechpartner, formatTeilnehmer } from '../../util/formate';
import { toShortDateTimeString } from '../../util/date-utils' // toShortDateString,
import FileSaver from 'file-saver';
// import { toValidJsonObject  } from '../../util/json2array';

const Printjobs = () => {

    const filterKey = "print_filter_key"


    const [joblist, setJoblist] = useState(null)
    const [filterList, setFilterList] = useState(null)
    const [filterValue, setFilterValue] = useState(1)
    const [checkAll, setCheckAll] = useState(false)

    const _setFilterValue = ((val, what) => {
        // console.log("_setFilterValue " + val + "  " + typeof(val) + " " + what)
        setFilterValue(parseInt(val))
    })

    useEffect(() => {
        async function loadJoblist()
        {
            const res = await FetchService.loadJoblist()       
            if (Array.isArray(res))
            {   
                let lst = [] 
                if (res.length > 0)
                {
                    // console.log(res[0])
                    // console.log(res[0].keys + " + " + typeof(res[0].keys))  
                    // res[0].keys.split(",").forEach( (elem) => { 
                    //     console.log(elem)
                    // })
                    res.forEach(function(item) 
                    {   
                        let einr = null;
                        let asp = null;
                        let tn = null;
                        // let jt = null;
                        let mid = null, eid = null, tid = null, aid = null, jt = null, docid = null;
                        if (item.DocumentsDaten) {
                            einr = item.DocumentsDaten.Einrichtungen
                            asp = item.DocumentsDaten.Ansprechpartner
                            tn = item.DocumentsDaten.Teilnehmer
                            jt = item.DocumentsDaten.created
                            mid =  item.DocumentsDaten.mid
                            eid =  item.DocumentsDaten.eid
                            aid =  item.DocumentsDaten.aid
                            tid =  item.DocumentsDaten.tid 
                            docid = item.DocumentsDaten.documents_id
                        } 
                        else
                        {
                            einr = item.Einrichtungen
                            asp = item.Ansprechpartner
                            tn = item.Teilnehmer
                            jt = item.jobtime
                            mid =  item.mid
                            eid =  item.eid
                            aid =  item.aid
                            tid =  item.tid                            
                        }
                        const val = { 
                            check : false,
                            index : lst.length,
                            id : item.id, 
                            userid : item.userid, 
                            jobtime : jt,
                            mid: mid,
                            eid: eid,
                            aid: aid,
                            tid: tid,
                            keys: item.keys,
                            vorlage : item.vorlage,
                            what : item.what,
                            jobstate : item.jobstate,
                            jobstatetext : item.PrintJobState.bezeichnung,
                            einrichtung : (einr ? einr.kurzbezeichnung : ''),
                            ansprechpartner : (asp ? formatAnsprechpartner(asp, "anv") : ''),
                            teilnehmer : (tn ? formatTeilnehmer(tn, "nv") : ''),
                            docid : docid,
                        } 
                        lst.push(val)
                    });                      
                }
                setJoblist(lst)  
            }
            else 
            {
                console.log(res)
            }
        }        
        const _filterValue = localStorage.getItem(filterKey)
        if (_filterValue) {            
            _setFilterValue(_filterValue, "storage")  
        }
        loadJoblist()        
    }, [])

    useEffect(() => {
        async function F()
        {            
            if (joblist && joblist.length > 0) {                
                const ff = []
                joblist.forEach( (item) => {
                    if (checkfilter(item.jobstate)) {
                        ff.push(item)
                    }
                })
                if (ff.length > 0) {
                    setFilterList(ff)
                    // _setFilterValue(filterValue, "")
                    return;
                }
            }
            setFilterList(null)
        }        
        F()
    }, [filterValue, joblist]) 

    const checkfilter = ((jobstate) => {              
        return ((jobstate & filterValue) !==0 ) 
    })    

    const _checkSingle = (evt) => {
        const indexattribute = evt.target.attributes['index']
        if (indexattribute) {
            const idx = Number.parseInt(indexattribute.value)                    
            joblist[idx].check = evt.target.checked              
        }        
    }

    const _checkAll = (evt) => {        
        let checked = (evt.target ? evt.target.checked : evt)
        setCheckAll(checked)
        const boxes = document.getElementsByClassName("mcheck")        
        for (let i=0; i<boxes.length; i++) {
            if (boxes.item(i).checked !== checked) {                
                boxes.item(i).click() 
            }            
        }
    }

    const buttonPrinted = (evt) => {        
        doStatusChange("Keine Druckaufträge ausgewählt!", '/data/printjobs/updateprinted')
    }

    const buttonDeleted = (evt) => {        
        doStatusChange("Keine Druckaufträge ausgewählt!", '/data/printjobs/updatedeleted')
    }

    const buttonNeu = (evt) => {        
        doStatusChange("Keine Druckaufträge ausgewählt!", '/data/printjobs/updatenew')
    }

    const buttonDestroy = (evt) => {
        doStatusChange("Keine Druckaufträge ausgewählt!", '/data/printjobs/destroydeleted')
    }

    const doStatusChange = async (msg, url) => 
    {    
        const ids = []
        joblist.forEach( (element) => {           
            if (element.check === true) {                
                ids.push(element.id)
            }            
        })

        if (ids.length === 0) {
            alert(msg) 
        } 
        else {                        
            let msg = null
            try
            {                
                const response = await fetch(url,
                {
                    method: 'POST',
                    headers: {                    
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(ids)
                })
                if (!response.ok) {
                    msg = response.status + " " + response.statusText
                }
                else 
                {                       
                    document.location.reload(false)                    
                }
            }
            catch (err) {
                msg = err
            }
            if (msg) {
                alert(msg)
            }        
        }
    }

    const _onradiochanged = (value) => {        
        _checkAll(false)        
        localStorage.setItem(filterKey, Number.parseInt(value))
        switch (value)
        {
            case "1":                
                _setFilterValue(1, "radionchanged")                
                break;
            case "2":
                _setFilterValue(2, "radionchanged")                
                break;
            case "4":
                _setFilterValue(4, "radionchanged")                          
                break;
            default:
                _setFilterValue(1, "radionchanged")              
                break;
        }        
    }   

    const onDownload = ( async (e) => {
        e.preventDefault()
        const href = e.currentTarget.getAttribute('href')
        if (href && href.length > 1) {
            const docid = Number.parseInt( href.substring(1) )            
            if (!Number.isNaN(docid))
            {
                const res = await fetch ("/data/documents/download/" + docid)                
                if (res.status === 200) {             
                    const json = await res.json()
                    // console.log(json)
                    const ar = new Uint8Array(json.Document.filedata.data)                            
                    // console.log (ar)
                    const blob = new Blob([ar] ,{ type:"application/octet-stream" });
                    // console.log(blob)                    
                    FileSaver.saveAs( blob, json.filename );                    
                }
                else {
                    alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
                }                
            }            
        }
    })

    return (
        <div>
            { joblist && joblist.length > 0 && <>
            <div className="sm_table_div">
                <table>
                    <thead>
                        <tr><th className="td_center" colSpan="11"><h3>Druckauftr&auml;ge</h3></th></tr>
                        <tr className="table-blank-row"></tr>
                        <tr>
                            <th className="td_label">Filter:</th>
                            <td className="td_label">Neu</td>
                            <td>
                                <FormGroup 
                                    type="radio"
                                    name="r1"
                                    value="1"
                                    checked={(filterValue & 1) !== 0}
                                    onChange={_onradiochanged}
                                />
                            </td>
                            <td style={{width:10}}></td>
                            <td className="td_label">Gedruckt</td>
                            <td>
                                <FormGroup 
                                    type="radio"
                                    name="r2"
                                    value="2"
                                    checked={(filterValue & 2) !== 0}
                                    onChange={_onradiochanged}
                                />
                            </td>
                            <td style={{width:10}}></td>
                            <td className="td_label">Gel&ouml;scht</td>
                            <td>
                                <FormGroup 
                                    type="radio"
                                    name="r4"
                                    value="4"
                                    checked={(filterValue & 4) !== 0}
                                    onChange={_onradiochanged}
                                />
                            </td>
                            <td style={{width:10}}></td>
                            <th className="td_label">Status &auml;ndern zu:</th>
                            <td className="td_button">
                                <button 
                                    className="exportbtn" 
                                    title="Alle markierte neue Elemente in den Status Gedruckt setzen"
                                    onClick={buttonPrinted}
                                    disabled={filterValue !== 1}
                                >
                                    &nbsp;Gedruckt&nbsp;
                                </button>
                            </td>
                            <td className="td_button">
                                <button 
                                    className="exportbtn" 
                                    title="Alle markierte neue Elemente in den Status Gelöscht setzen"
                                    onClick={buttonDeleted}
                                    disabled={filterValue !== 1}
                                >
                                    &nbsp;Gel&ouml;scht&nbsp;
                                </button>
                            </td>
                            <td className="td_button">
                                <button 
                                    className="exportbtn" 
                                    title="Alle markierte gelöschten Elemente in den Status Neu setzen"
                                    onClick={buttonNeu}
                                    disabled={filterValue !== 4}
                                >
                                    &nbsp;Neu&nbsp;
                                </button>
                            </td>
                            <td className="td_button">
                                <button 
                                    className="exportbtn" 
                                    title="Alle markierte gelöschten Elemente endgültig löschen"
                                    onClick={buttonDestroy}
                                    disabled={filterValue !== 4}
                                >
                                    &nbsp;Endg&uuml;ltig L&ouml;schen&nbsp;
                                </button>
                            </td>
                        </tr>
                        <tr></tr>
                        <tr className="table-blank-row"></tr>
                        <tr className="table-blank-row"></tr>
                    </thead>
                </table>
            </div>
            <div className="sm_table_div">
                { filterList && filterList.length > 0 && <table className="sm_table">
                    <thead>
                        <tr>
                            <th className='td_check'><input type="checkbox" value={checkAll} onClick={_checkAll}></input></th>
                            <th className='td_label'>Status</th>
                            <th className='td_label'>Datum</th>
                            <th className='td_label'>MID</th>
                            <th className='td_label'>Vorgang</th>
                            <th className='td_label'>Einrichtung</th>
                            <th className='td_label'>Ansprechpartner</th>
                            <th className='td_label'>Teilnehmer</th>
                            <th className='td_label'>Download</th>
                        </tr>
                    </thead>
                    <tbody>{ joblist.map( ({check, index, id, userid, jobstate, jobstatetext, mid, eid, aid, tid, keys, jobtime, 
                                            what, ansprechpartner, einrichtung, teilnehmer, docid }) =>
                            {
                                if(checkfilter(jobstate))
                                return (<tr className="liste" id={id} key={id}>
                                        <td className='td_check'> 
                                            <input className="mcheck" type="checkbox" value={check} onClick={_checkSingle} index={index}></input>
                                        </td>
                                        <td className="td_label">{jobstatetext}</td>
                                        <td className="td_label">{toShortDateTimeString(jobtime)}</td>
                                        <td className="td_label">
                                        {
                                            keys ? keys.split(",").map( (elem) => {
                                                return (<a key={elem} href={`/Massnahme/Edit?mid=${elem}`}>{elem} </a>)
                                            }) : mid ? <a href={`/Massnahme/Edit?mid=${mid}`}>{mid}</a> : ''
                                        }
                                        </td>
                                        {/* <td>{vorlage}</td> */}
                                        <td className="td_label">{what}</td>
                                        <td className="td_label">{einrichtung} {eid ? <a href={`/Einrichtung/Edit?eid=${eid}`}>({eid})</a> : ''}</td>
                                        <td className="td_label">{ansprechpartner} {aid && eid ? <a href={`/Ansprechpartner/Edit?eid=${eid}&aspId=${aid}`}>({aid})</a>:''}</td>
                                        <td className="td_label">{teilnehmer} {tid ? <a href={`/Teilnehmer/Edit?tid=${tid}`}>({tid})</a> : ''}</td>
                                        <td className="td_label">
                                            {docid ? <a onClick={onDownload}
                                                href={`#${docid}`}>Datei
                                            </a> : ''}
                                        </td>
                                    </tr>
                                )
                                return ''
                            }
                        )}</tbody>
                </table> 
                }
            </div> {/* {`/data/documents/download/${docid}`} */}
            </>}

            { (!joblist || joblist.length === 0) && <h3>Keine Druckaufträge vorhanden</h3> }
            {/* <div>
                <input type="radio" name="s2" value="1" checked={(filterValue & 1) !=0} onChange={_onradiochecked}></input>
                <input type="radio" name="s2" value="2" checked={(filterValue & 2) !=0} onChange={_onradiochecked}></input>
                <input type="radio" name="s2" value="3" checked={(filterValue & 4) !=0} onChange={_onradiochecked}></input>
            </div>          */}
        </div>
    )
}

export default Printjobs
