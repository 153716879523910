import React, { useState, useEffect } from 'react'
import { AiOutlineSave } from 'react-icons/ai';
import FileSaver from 'file-saver';

import { toShortDateString } from '../../util/date-utils'
import FormGroup from '../../components/shared/form-group'
import ConfigIcon from '../../util/configicon';
import { FetchService, authenticationService } from '../../services';
import { X } from './X';

import { PO } from './PO';
import NonModal from '../../components/shared/nonmodal/NonModal';
import Modal from '../../components/shared/modal/Modal';

const po = new PO("MassnahmeAufteilen")


const MassnahmeAufteilen = (props) => {

    const [showprintdialog, setshowprintdialog] = useState(false)
    const [isprintdialogactive, setprintdialogactive] = useState(false)
    const nonmodalid = 'NonModal_MassnahmeAufteilen';
    po.initComponent(this, setshowprintdialog)

    function showPrintDialog()
    {  
        // console.log("showprintdialog " + po.printmessage)      
        if (po && po.printid && po.printmessage) 
        {
            const element = document.getElementById(nonmodalid)
            if (element) {
                element.style.display = 'block';                                    
            }        
        }
    }

    function documentprint_callback(cmd, param) { po.documentprint_callback(cmd, param)}

    useEffect(() => {
        async function func()
        { 
            if (showprintdialog) 
            { 
                showPrintDialog() 
                setprintdialogactive(true);
            }
            else
            {               
                        
                if (isprintdialogactive) 
                {   // UPDATE nach schließen des Dialogs
                    console.log("Update Massnahme?")
                    setprintdialogactive(false);
                    props.callback('Massnahme teilen', 'reload') 
                }
            }
        }
        // console.log("useeffect showprintdialog " + showprintdialog)    
        func()
    }, [showprintdialog]); 



    const [canEdit] = useState(authenticationService.currentUser.category > 0)
    const [canSave, setCanSave] = useState(false)

    const [mid, setMid] = useState(null)
    // const [kid, setKid] = useState(null)

    // const [status, setStatus] = useState(null)

    const [mvon, setMvon] = useState(null)
    const [mbis, setMbis] = useState(null)
    const [mvonString, setMvonString] = useState(null)
    const [mbisString, setMbisString] = useState(null)

    const [tvon, setTvon] = useState(null)
    const [tbis, setTbis] = useState(null)

    
    
    useEffect(() => {
        async function asyncFetchMassnahmeAufteilenForSelect()
        {   
            const pd = JSON.parse(props.data)            
            const m = pd[0].mid           

            if (m) {
                setMid(m)                
                try
                {
                    const mresponse = await fetch('/data/massnahmen/' + m)
                    if (mresponse.ok) {
                        const mresult = await mresponse.json();                                                 
                        // setStatus(mresult.status)                             
                        setMvon(Date.parse(mresult.f_von))
                        setMbis(Date.parse(mresult.f_bis))                            
                        setMvonString( toShortDateString(mresult.f_von))
                        setMbisString( toShortDateString(mresult.f_bis))                           
                        _initTeilungsDate(mresult.f_von, mresult.f_bis)
                        _setTeilungsId(1)
                    }
                }
                catch (err) {
                    console.log(err)
                }
            }
        }
        // console.log("asyncFetchMassnahmeAufteilenForSelect")
        asyncFetchMassnahmeAufteilenForSelect()
    },[props.data])

    const savedata = async () => {
        const data = 
        {
            id        : mid,
            startDate : null,
            endDate   : null, // 2020-04-28T00:00:00.000Z"
        }
        if (teilungsId === 1) 
        {
            console.log("Teilung bei Datum")   
            data.startDate = tvon
        } 
        else if (teilungsId === 2) 
        {
            console.log("Teilung Pausierung")
            if (tvon > tbis) {
                alert("Das Startdatum der Unterbrechung ist größer als das Enddatum!")
                return;
            }
            data.startDate = tvon
            data.endDate = tbis
        }
        else 
        {
            return; // nix todo, keine Teilungsart
        }
        const sdata = JSON.stringify(data)
        console.log(sdata)
        const response = await fetch('/data/massnahmen/update/teilung', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: sdata,
        })
        if (response.ok) {
            const txt = await response.text()            
            if (txt !== "FAIL")
            {
                console.log(txt)
                if (window.confirm("Massnahme geteilt (" + txt.substring(3) + "). Wollen sie eine Bestätigung drucken?"))
                {                        
                    const res = await FetchService.generateTeilnahmebestaetigung(mid)            
                    if (res.status === 200 && res.data) 
                    {
                        const filename = 'Teilnahmebestaetigung_' + mid + '.docx'
                        let pd = po.createPrintData(filename, res.data.printid , mid)
                        pd = null;
                        FileSaver.saveAs( res.data.document, filename );                        
                    }
                    else 
                    {
                        alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
                        return;
                    }                
                }
                //UPDATE
                // props.callback('Massnahme teilen', 'reload')                 
            }
            else
            {
                alert("Teilung fehlgeschlagen")
            }
        }
        else 
        {
            alert("Fehler: " + response.status + " " + response.statusText)
        }
    }
    
    const teilungsSelectData = 
    [        
        {key:1, value:'Teilnachweis - bei Datum trennen'},
        {key:2, value:'Unterbrechung - Zeitraum ausschließen'}
    ]

    function _initTeilungsDate(f_von, f_bis)
    {
        let tdate_von = new Date(f_von)
        let tdate_bis = new Date(f_bis) 
        let currentYear = Math.min(new Date().getFullYear(), tdate_bis.getFullYear()) 
        // let ds = new Date(tdate_bis.getFullYear()-1, 11, 31)
        let ds = new Date(currentYear-1, 11, 31)
        if (ds > tdate_von) {            
            setTvon(ds)
            setTbis(new Date(tdate_bis.getFullYear(), 0, 1))
            return;
        }
        ds = new Date(currentYear, 5, 30)
        if ((ds > tdate_von) && (ds < tdate_bis)) {
            setTvon(ds)
            setTbis(new Date(tdate_bis.getFullYear(), 6, 1))
            return;
        }
        setTvon(tdate_von)
        setTbis(tdate_bis)
    }

    const [teilungsId, setTeilungsId] = useState(0)

    const _setTeilungsId = ((val) => {
        const v = parseInt(val)
        if (Number.isInteger(v)) 
        {  
            setTeilungsId(v)
            setCanSave(true)
        }
        else 
        {
            setTeilungsId(0)
            setCanSave(false)
        }
    })

    return (
        <div>            
            <table>
                <thead>
                    <tr><th colSpan="4" className="td_label_center">Ma&szlig;nahme teilen</th></tr>
                    <tr className="table-blank-row4"></tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='td_label' width="150">Zeitraum:</td>
                        <td className='td_label' width="200">{mvonString}</td>
                        <td width="20">&nbsp;</td>
                        <td className='td_label' width="200">{mbisString}</td>
                        <td width="20">&nbsp;</td>
                        <td rowSpan="2">
                            <button 
                                className="exportbtn" 
                                onClick={savedata}
                                disabled={!canSave || !canEdit || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                            >
                                <ConfigIcon><AiOutlineSave/></ConfigIcon>&nbsp;Speichern
                            </button>
                        </td>                        
                    </tr>
                    <tr>
                        <td className='td_label'>Teilungsgrund:</td>
                        <td className="td_select" colSpan="3">
                            <FormGroup                                                        
                                type="select"                                
                                name="teilungsselect"
                                options={teilungsSelectData}
                                value={teilungsId}
                                onChange={_setTeilungsId} 
                                disabled={!canEdit || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}                             
                            />                               
                        </td> 
                    </tr>
                    {teilungsId === 1 && <>
                    <tr>
                        <td className='td_label'>Teilung:</td>
                        <td>
                            <FormGroup
                                type="html-date"
                                className="form-control" //"td_datepicker"
                                value={tvon}
                                onChange={setTvon}
                                min={mvon} // {Moment().add(-100, 'years').toDate()}
                                max={mbis} // {Moment().add(-16,'years').toDate()}
                                disabled={!canSave || !canEdit || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                            />    
                            {/* <DatePicker
                                popperClassName="react-datepicker-popper"
                                className="form-control" //"td_datepicker"
                                selected={tvon}
                                onChange={setTvon} 
                                dateFormat={"dd.MM.yyyy"}
                                minDate={mvon} // {Moment().add(-100, 'years').toDate()}
                                maxDate={mbis} // {Moment().add(-16,'years').toDate()}
                                showFullMonthYearPicker={true}
                                disabled={!canSave || !canEdit || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                            /> */}
                        </td>                        
                    </tr>
                    </>
                    }

                    {teilungsId === 2 && <>
                    <tr>
                        <td>Unterbrechung:</td>
                        <td>
                            <FormGroup
                                type="html-date"
                                className="form-control" //"td_datepicker"
                                value={tvon}
                                onChange={setTvon}
                                min={mvon} // {Moment().add(-100, 'years').toDate()}
                                max={mbis} // {Moment().add(-16,'years').toDate()}
                                disabled={!canSave || !canEdit || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                            />
                            {/* <DatePicker
                                popperClassName="react-datepicker-popper"
                                className="form-control" //"td_datepicker"
                                selected={tvon}
                                onChange={setTvon} 
                                dateFormat={"dd.MM.yyyy"}
                                minDate={mvon} // {Moment().add(-100, 'years').toDate()}
                                maxDate={mbis} // {Moment().add(-16,'years').toDate()}
                                showFullMonthYearPicker={true}
                                disabled={!canSave || !canEdit || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                            /> */}
                        </td>
                        <td>&nbsp;-&nbsp;</td>
                        <td>
                            <FormGroup
                                type="html-date"
                                className="form-control" //"td_datepicker"
                                value={tbis}
                                onChange={setTbis}
                                min={mvon} // {Moment().add(-100, 'years').toDate()}
                                max={mbis} // {Moment().add(-16,'years').toDate()}
                                disabled={!canSave || !canEdit || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                            />
                            {/* <DatePicker
                                popperClassName="react-datepicker-popper"
                                className="form-control" //"td_datepicker"
                                selected={tbis}
                                onChange={setTbis} 
                                dateFormat={"dd.MM.yyyy"}                                
                                minDate={mvon} // {Moment().add(-100, 'years').toDate()}
                                maxDate={mbis} // {Moment().add(-16,'years').toDate()}
                                showFullMonthYearPicker={true}
                                disabled={!canSave || !canEdit || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                            /> */}
                        </td>
                    </tr>                    
                    </>
                    }
                </tbody>
            </table>
            <div>
                <NonModal 
                    id={nonmodalid} 
                    type='yesno' 
                    title='Druckbestätigung' 
                    message={po.printmessage} 
                    callback={documentprint_callback} 
                    data={po.printid}
                />
            </div>
        </div>
    )
}

export default MassnahmeAufteilen
