import React from 'react'
import KontrollListenPage from './KontrollListenPage'

const KontrollListenNachweisBestaetigt = () => {
    return (
        <div>
            <KontrollListenPage mstatus="9"/>
        </div>
    )
}

export default KontrollListenNachweisBestaetigt
