
class ValueClass
{
    constructor() {        
    }

    // static INV_DATE = "Invalid Date"
}

const Values = new ValueClass();

module.exports = Values;