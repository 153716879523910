import React from 'react'
import PropTypes from 'prop-types'

const Navlink = props => 
{
  return (
    <a href={props.link}>{props.title}</a>
  )
}

Navlink.propTypes = {
  // onClick : PropTypes.func,
  title : PropTypes.string,
  link  : PropTypes.string,
  // sortOrder : PropTypes.number
}

export default Navlink