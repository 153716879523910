class DocxResultMapper
{
    get document() {        
        return this.#document;
    }    

    get vorlage() {
        return this.#vorlage
    }

    get printid() {
        return this.#printid
    }

    get data() {
        return this.#data
    }

    #data = null
    #document = null
    #printid = 0
    #vorlage = null

    constructor (blob) {       
        this.#data = blob
    }

    async init()
    {
        if (!this.#document) 
        {              
            if (this.#data.type === 'application/json') 
            {                
                const txt = await this.#data.text()
                const json = JSON.parse(txt)
                this.#vorlage = json.vorlage
                this.#printid = json.printid

                const ar = new Uint8Array(json.document.data)            
                this.#document = new Blob([ar]) ; 
            } 
            else 
            {
                this.#document = this.#data
            }
        }
    }

    



}

export default DocxResultMapper;