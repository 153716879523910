
import React, { useState, useEffect } from 'react';
import FileSaver from 'file-saver';
import { AiOutlineFileExcel, AiOutlineFileText } from 'react-icons/ai';
// import { ReactSearchAutocomplete } from 'react-search-autocomplete'


import ConfigIcon from '../../util/configicon';
import FetchService from '../../services/FetchService';
import FormGroup from '../../components/shared/form-group';
// import { getReactSearchAutocompleteStyle } from '../../util/style';
// import { formatEinrichtung } from '../../util/formate';
// import { toShortDateString } from '../../util/date-utils';


const Berichte = () => {
    const [yearValues, setYearValues] = useState(null);

    const [yearValue, setYearValue] = useState(new Date().getFullYear())

    useEffect(() => {
        const y = new Date().getFullYear()
        const v = [];
        for (let num=0; num<3; num++) {
            v.push({
                key : y-num,
                value: y-num
            })
        }
        setYearValues(v)
    }, [])

    const abrechnungsUebersichtCsv = async() => {
        if (yearValue) {        
            const res = await FetchService.generateAbrechnungsUebersicht(yearValue, 1)            
            if (res.status === 200 && res.data) {                
                const ds = new Date().toISOString().slice(0,10)
                const filename = ds + "-Abrechnungsübersicht-" + yearValue + ".csv"                     
                FileSaver.saveAs( res.data, filename);             
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
    }

    const p31ExportUebersichtCsv = async() => {
        if (yearValue) {
            const res = await FetchService.generateP31Report(yearValue, 1)            
            if (res.status === 200 && res.data) {                
                const ds = new Date().toISOString().slice(0,10)
                const filename = ds + "-Meldung-p3a1-" + yearValue + ".csv"                     
                FileSaver.saveAs( res.data, filename);             
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
    }

    const abrechnungsUebersichtXls = async() => {
        if (yearValue) {
            const res = await FetchService.generateAbrechnungsUebersicht(yearValue, 2)
            if (res.status === 200 && res.data) {                
                const ds = new Date().toISOString().slice(0,10)
                const filename = ds + "-Abrechnungsübersicht-" + yearValue + ".xlsx"                     
                FileSaver.saveAs( res.data, filename);             
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
    }

    const p31ExportUebersichtXls = async() => {
        if (yearValue) {
            const res = await FetchService.generateP31Report(yearValue, 2)            
            if (res.status === 200 && res.data) {                
                const ds = new Date().toISOString().slice(0,10)
                const filename = ds + "-Meldung-p3a1-" + yearValue + ".xlsx"                     
                FileSaver.saveAs( res.data, filename);             
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
    }

    const p33ExportUebersichtXlsWorker = async(yv) => {
        if (yv) {
            const res = await FetchService.generateP33Report(yv, 2)            
            if (res.status === 200 && res.data) {                
                const ds = new Date().toISOString().slice(0,10)
                const filename = ds + "-Evaluation" + yv + "-" + (yv+1) +  ".xlsx"                      
                FileSaver.saveAs( res.data, filename);             
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
    }

    const p33ExportUebersichtXls1501 = () => {
        if (yearValue) {
            p33ExportUebersichtXlsWorker(yearValue-1)
        }
    }

    const p33ExportUebersichtXls1507 = () => {
        if (yearValue) {
            p33ExportUebersichtXlsWorker(yearValue)
        }        
    }
 
    return (
        <>
            <h3>Berichte</h3>
            <div className="flex-container"
             style={
                {
                    // backgroundImage:"url(../sheet3.png)",
                    // backgroundRepeat:"no-repeat",
                    // backgroundColor: "rgba(255,255,255,0.7)",
                    // backgroundBlendMode: "lighten"                                        
                }}            
            >
                <div className="reportitem" >
                    <table className="reportitemtable">
                        <tbody>
                            <tr>
                                <td colSpan="2"><b>Berichte nach § 3 Abs. 1 Nr. 1 zum 15.1.</b></td>
                            </tr>
                            <tr>
                                <td className="td_label">Berichtsjahr:</td>
                                <td className="td_select">
                                    <FormGroup
                                        type="select"
                                        value={yearValue} 
                                        onChange={setYearValue} 
                                        options={yearValues}                                                
                                    />  
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <button className="exportbtn" onClick={p31ExportUebersichtCsv}>
                                        <ConfigIcon><AiOutlineFileText/></ConfigIcon>&nbsp;Export Meldung nach § 3 Absatz 1 CSV
                                    </button>                                
                                </td>                                            
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <button className="exportbtn" onClick={p31ExportUebersichtXls}>
                                        <ConfigIcon><AiOutlineFileExcel/></ConfigIcon>&nbsp;Export Meldung nach § 3 Absatz 1 Excel
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='reportitem'>
                    <table className="reportitemtable">
                        <tbody>
                            <tr>
                                <td colSpan="2"><b>Berichte nach § 6 Abs. 4 zum 15.1.</b></td>
                            </tr>
                            <tr>
                                <td className="td_label">Berichtsjahr:</td>
                                <td className="td_select">
                                    <FormGroup
                                        type="select"
                                        value={yearValue} 
                                        onChange={setYearValue} 
                                        options={yearValues}                                                
                                    />  
                                </td>
                            </tr>                           
                            <tr>
                                <td colSpan="2">
                                    <button className="exportbtn" onClick={p33ExportUebersichtXls1501}>
                                        <ConfigIcon><AiOutlineFileExcel/></ConfigIcon>&nbsp;Export Meldung nach § 6 Abs. 4 Excel
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='reportitem'>
                    <table className="reportitemtable">
                        <tbody>
                            <tr>
                                <td colSpan="2"><b>Berichte nach § 6 Abs. 4 zum 15.7.</b></td>
                            </tr>
                            <tr>
                                <td className="td_label">Berichtsjahr:</td>
                                <td className="td_select">
                                    <FormGroup
                                        type="select"
                                        value={yearValue} 
                                        onChange={setYearValue} 
                                        options={yearValues}                                                
                                    />  
                                </td>
                            </tr>                           
                            <tr>
                                <td colSpan="2">
                                    <button className="exportbtn" onClick={p33ExportUebersichtXls1507}>
                                        <ConfigIcon><AiOutlineFileExcel/></ConfigIcon>&nbsp;Export Meldung nach § 6 Abs. 4 Excel
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="reportitem">
                    <table className="reportitemtable">
                        <tbody>
                            <tr>
                                <td colSpan="2">
                                    <b>Berichte nach § 3 Abs. 1 Nr. 2 zum 15.10.</b>
                                </td>
                            </tr>
                            <tr>
                                <td className="td_label">Berichtsjahr:</td>
                                <td className="td_select">
                                    <FormGroup
                                        type="select"
                                        value={yearValue} 
                                        onChange={setYearValue} 
                                        options={yearValues}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <button className="exportbtn" onClick={abrechnungsUebersichtCsv}>
                                        <ConfigIcon><AiOutlineFileText/></ConfigIcon>&nbsp;Export Zahlungslauf CSV
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <button className="exportbtn" onClick={abrechnungsUebersichtXls}>
                                        <ConfigIcon><AiOutlineFileExcel/></ConfigIcon>&nbsp;Export Zahlungslauf Excel
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
               
            </div>

            {/* <div className="sm_table_div"> 
                <table className="sm_table_report">
                    <tbody style={
                                {
                                    backgroundImage:"url(../sheetAll.png)",
                                    backgroundRepeat:"no-repeat",
                                    backgroundColor: "rgba(255,255,255,0.8)",
                                    backgroundBlendMode: "lighten"                                        
                                }}>
                        {
                        // <tr>
                        //     <td>
                        //         Berichte nach § 6 Abs. 4 jeweils zum 15.7. und 15.1.<br/>
                        //         <button>Export (2020/2021)</button>
                        //         {/* 2021-08-19-Evaluation2020-2021.xlsx 
                        //     </td>
                        //     <td>
                        //         Berichte nach § 6 Abs. 4 jeweils zum 15.7. und 15.1.<br/>
                        //         <button>Export (2021/2022)</button>
                        //         {/* 2021-08-19-Evaluation2021-2022.xlsx 
                        //     </td>
                        // </tr> 
                        }
                        <tr>
                            <td width="33%" height="33%">
                                <div className="reportItem" style={{margin:10}}>
                                    {
                                        // backgroundSize:"250px 180px", 
                                        // width: "250px", 
                                        // height: "180px"
                                        // opacity:"0.5"
                                    }                        
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan="2"><b>Berichte nach § 3 Abs. 1 Nr. 1 zum 15.1.</b></td>
                                        </tr>
                                        <tr>
                                            <td className="td_label">Berichtsjahr:</td>
                                            <td className="td_select"><FormGroup
                                                type="select"
                                                value={yearValue} 
                                                onChange={setYearValue} 
                                                options={yearValues}                                                
                                            />  
                                            </td>
                                        </tr>   
                                        <tr>
                                            <td colSpan="2">
                                                <button className="exportbtn" onClick={p31ExportUebersichtCsv}>
                                                    <ConfigIcon><AiOutlineFileText/></ConfigIcon>&nbsp;Export Meldung nach § 3 Absatz 1 CSV
                                                </button>                                
                                            </td>                                            
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <button className="exportbtn" onClick={p31ExportUebersichtXls}>
                                                    <ConfigIcon><AiOutlineFileExcel/></ConfigIcon>&nbsp;Export Meldung nach § 3 Absatz 1 XLS
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </td>
                            <td width="33%" height="300"
                            // style={
                            //     {
                            //         backgroundImage:"url(../sheet3.png)",
                            //         backgroundRepeat:"no-repeat",
                            //         backgroundColor: "rgba(255,255,255,0.7)",
                            //         backgroundBlendMode: "lighten"                                        
                            //     }}
                            >
                                <div className="reportItem">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan="2">
                                                <b>Berichte nach § 3 Abs. 1 Nr. 2 zum 15.10.</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="td_label">Berichtsjahr</td>
                                            <td className="td_select">
                                                <FormGroup
                                                    type="select"
                                                    value={yearValue} 
                                                    onChange={setYearValue} 
                                                    options={yearValues}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <button className="exportbtn" onClick={abrechnungsUebersichtCsv}>
                                                    <ConfigIcon><AiOutlineFileText/></ConfigIcon>&nbsp;Export Zahlungslauf CSV
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <button className="exportbtn" onClick={abrechnungsUebersichtXls}>
                                                    <ConfigIcon><AiOutlineFileExcel/></ConfigIcon>&nbsp;Export Zahlungslauf XLS
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </td>
                        </tr>
                        <tr>
                        </tr>
                    </tbody>
                </table>
            </div> 
            */}
        </>
    )
}

export default Berichte
