import React from 'react'; // , { useEffect }
import './modal.css';


function Modal(props) 
{   
    const closeModal = (() => {        
        document.getElementById(props.id).style.display = 'none';
        childaction('show', false)
    })

    function callbackFromParent(cmd, param) {     
        childaction(cmd, param)
    }

    props.callback('actionfunc', callbackFromParent)

    let childaction = null;
    function callbackfromchild(cmd, param) { 
        if (cmd === 'actionfunc') {
            childaction = param
        } else if (cmd === 'close') {
            closeModal()
        } else if (cmd === 'save') {
            closeModal()
            props.callback('save', param)        
        } else {
            console.log("unknown cmd '" + cmd + " param " + param)
        }       
    }

    return (
        <div id={props.id} className='modal'>
            <div className='modal-content' style={{overflowX:'auto'}}>
                <table className='sm_table'>
                    <tbody>
                        <tr>
                            <td>
                                <p className='modal-title'>{props.title}</p>
                            </td>
                            <td>
                                <span className='close' onClick={closeModal}>&times;</span>                
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                {(props.component) && <>
                                    {props.component(
                                        {
                                            mid : props.mid,
                                            callback : callbackfromchild,
                                        })
                                    }
                                    </>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Modal;
