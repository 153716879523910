class TABS 
{
    static TAB_AUSZAHLUNG   = 'Auszahlung'    
    static TAB_KUERZUNG     = 'Kürzung'
    static TAB_VERRECHNUNG  = 'Verrechnung'
    static TAB_KUERZUNG_NZ  = 'Kürzung nach Zahlung'
    static TAB_TEILEN       = 'Massnahme teilen'
    static TAB_DOKUMENTE    = 'Dokumente'


    static idToTabname(id) {
        switch (id) {
            case 1:
                return this.TAB_KUERZUNG;
            case 2:
                return this.TAB_VERRECHNUNG;
            case 3:
                return this.TAB_KUERZUNG_NZ;
            case 4:
                return this.TAB_TEILEN;
            case 5:
                return this.TAB_DOKUMENTE;
            default:
                return this.TAB_AUSZAHLUNG;
        }
    }
}
export { TABS }

