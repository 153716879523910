
import React from 'react'

import NonModal from '../../components/shared/nonmodal/NonModal';

const TestModal = () => {

    const nonmodalid = 'testmodal';
    const printmessage = "Die Felder Status und Nachweis bearbeitet werden geändert!\nWollen sie die Änderungen speichern?"

    function cmd_callback(cmd, param)
    {
        console.log('callback from modal cmd:' + cmd + " param:" + param)  


    }

    const cmdTestModal = () => {
        console.log("TestModal Button Hit")
        document.getElementById(nonmodalid).style.display = 'block';                                    
        // modalcallback('show', true);
    }

  return (
    <>
        <div>TestModal
        <button onClick={cmdTestModal}>Test Modal</button>
        <NonModal id={nonmodalid} type='yesno' title='Bestätigung der Änderung' 
            message={printmessage} callback={cmd_callback} data={12345}></NonModal>
        </div>
    </>
  )
}

export default TestModal