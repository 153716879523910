import React from 'react';
import DatePicker from 'react-datepicker';

const ReactDateControl = ({ name, value, className, contentClassName, onChange, disabled, min, max }) => {   
    // console.log(value + " " + name + " " + options.length) 

    return (
        <DatePicker                         
            name={name}
            className={className || 'form-date-input'}    
            popperClassName={contentClassName}                         
            selected={value}                     
            onChange={(e) => onChange(e)}
            dateFormat={"dd.MM.yyyy"}
            disabled={disabled || false }             
            showYearDropdown={true}
            maxDate={max}
            minDate={min}
        />
    )
}

export default ReactDateControl;

/* 
    {/* <DatePicker
        popperClassName="react-datepicker-popper"
        className="td_datepicker"
        selected={fVon}
        onChange={setfVon} 
        dateFormat={"dd.MM.yyyy"}                                                                        
        // maxDate={Moment().add(-16,'years').toDate()}
        // minDate={Moment().add(-100, 'years').toDate()}
        // showFullMonthYearPicker={true}
        // showMonthYearPicker={true}
        // filterDate={ date => date.getDay() != 6 && date.getDay() != 0 }
        // placeholderText="Beginn auswählen"
        showYearDropdown={false}
        locale={de}     
        // isClearable={canEdit}       
        // clearButtonClassName="react-datepicker-clearbutton"
        disabled= {!canEdit || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11)}
    /> 
*/