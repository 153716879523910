import React, { useState } from 'react';
// import { uploadValidationTestFile } from '../../../../services';
import Translater from '../../../../components/shared/translater'; 
// import './file-upload.scss';



const FileUpload = ({ onChange, value, accept, name, className }) => {
    const [file, setFile] = useState(null);   
    // const [isUploading, setIsUploading] = useState(false); 

    // const onFormSubmit = async (e) => {
    //    e.preventDefault();
    
    //    if (file !== null){
    //        setIsUploading(true);
    //        await uploadValidationTestFile(file);             
    //        setIsUploading(false);
    //        setFile(null);
    //        onFormSubmited();
    //    }        
    // }

    const onFileChange = (e) => {
        const file = e.target.files[0]; 
        if (file)  {
            setFile(file); 
        }
        onChange(e)        
    }
     
    return (
        // <div className="file-upload">            
        <>
            {
              <input name={name+'_text'} className={className || 'form-control'} type='text' value={value} readOnly={true} hidden={!value}></input>
            }
            { <input type="file" 
                name={name}
                className={className || 'form-control'} // className="custom-file-input"                                         
                accept={accept || "*/*"} 
                onChange={(e) => onFileChange(e) } 
                hidden={!!value}
            />
            }
        </>
        // </div>
    );
    //                  <div className="input-group-append">
    //                    <button className="btn btn-primary" 
    //                        onClick={onFormSubmit} 
    //                        disabled={!(file && file.name) || isUploading}>
    //                        {
    //                            isUploading && 
    //                            <>
    //                                <i className="fas fa-spinner fa-pulse mr-2"></i>
    //                                <Translater text="validationTestsPage.uploading"/>
    //                            </>
    //                        }
    //                        {
    //                            !isUploading && 
    //                            <Translater text="validationTestsPage.upload"/>                             
    //                        }
    //                    </button>
    //                </div>
}

export default FileUpload;