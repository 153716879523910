import React, { useState, useEffect } from 'react'


import FileSaver from 'file-saver';
import { AiOutlineFileWord } from 'react-icons/ai';
import { authenticationService } from '../../services';
import { toShortDateString } from '../../util/date-utils.js'
import { formatCurrency } from '../../util/number-utils.js'
import ConfigIcon from '../../util/configicon';
import BescheinigungErstellen from './BescheinigungErstellen';
import { PO } from './PO';
import NonModal from '../../components/shared/nonmodal/NonModal';

const po = new PO("KuerzungNachZahlung")

const KuerzungNachZahlung = (props) => {

    const [showprintdialog, setshowprintdialog] = useState(false)
    const nonmodalid = 'NonModal_KNZBescheinigung';
    po.initComponent(this, setshowprintdialog)

    function showPrintDialog()
    {  
        // console.log("showprintdialog " + po.printmessage)      
        if (po && po.printid && po.printmessage) 
        {
            const element = document.getElementById(nonmodalid)
            if (element) {
                element.style.display = 'block';                                    
            }        
        }
    }

    function documentprint_callback(cmd, param) { po.documentprint_callback(cmd, param)}

    useEffect(() => {
        async function func()
        { 
            if (showprintdialog) { showPrintDialog() }
        }
        // console.log("useeffect showprintdialog " + showprintdialog)    
        func()
    }, [showprintdialog]);    


    const [canEdit] = useState(authenticationService.currentUser.category > 0)    
    // const [mid, setMid] = useState(null)
    // const [kid, setKid] = useState(null)

    const [korr_mid_verrechnung , setkorr_mid_verrechnung] = useState(null)
    const [korr_datum, setkorr_datum] = useState(null)
    const [korr_von, setkorr_von] = useState(null)
    const [korr_bis, setkorr_bis] = useState(null)
    const [korr_prozentual, setkorr_prozentual] = useState(null)
    // const [korr_fachid, setkorr_fachid] = useState(null)
    const [korr_fachgebiet, setkorr_fachgebiet] = useState(null)
    const [korr_Betrag, setkorr_Betrag] = useState(null)
    const [korr_differenz, setkorr_differenz] = useState(null)
    const [korr_ub1_von, setkorr_ub1_von] = useState(null)
    const [korr_ub1_bis, setkorr_ub1_bis] = useState(null)
    const [korr_ub1_proz, setkorr_ub1_proz] = useState(null)
    const [korr_ub2_von, setkorr_ub2_von] = useState(null)
    const [korr_ub2_bis, setkorr_ub2_bis] = useState(null)
    const [korr_ub2_proz, setkorr_ub2_proz] = useState(null)
    const [korr_ub3_von, setkorr_ub3_von] = useState(null)
    const [korr_ub3_bis, setkorr_ub3_bis] = useState(null)
    const [korr_ub3_proz, setkorr_ub3_proz] = useState(null)
    const [vrlist, setvrlist] = useState(null)
   
    useEffect(() => {
        async function asyncFetchKuerzungNachZahlungForSelect()
        {          
            // console.log(props)
            const pd = JSON.parse(props.data)
            const m = pd[0].mid
            const k = pd[1].kid

            if (m) {
                // setMid(m)
                if (k) {
                    // setKid(k)                
                    try {                    
                        const response = await fetch('/data/kuerzungennachzahlung/' + k, { method: 'GET' })
                        if (response.ok) {
                            // console.log("response ok")
                            const res = await response.json();                         
                            let result = res;
                            if (Array.isArray(res)) {
                                console.log("Kürzung ist Array!!!")
                                result = res[0].KuerzungenNachZahlung
                            } 
                            // console.log("KÜRZUNG NACH ZAHLUNG: " + JSON.stringify(result))
                            setkorr_mid_verrechnung (result.korr_mid_verrechnung)
                            setkorr_datum (result.korr_datum)
                            setkorr_von (result.korr_von)
                            setkorr_bis (result.korr_bis)
                            setkorr_prozentual (result.korr_prozentual)
                            // setkorr_fachid(result.korr_fachid)
                            setkorr_Betrag(result.korr_betrag)
                            setkorr_differenz(result.korr_differenz)
                            setkorr_ub1_von(result.korr_ub1_von)
                            setkorr_ub1_bis(result.korr_ub1_bis)
                            setkorr_ub1_proz(result.korr_ub1_proz)
                            setkorr_ub2_von(result.korr_ub2_von)
                            setkorr_ub2_bis(result.korr_ub2_bis)
                            setkorr_ub2_proz(result.korr_ub2_proz)
                            setkorr_ub3_von(result.korr_ub3_von)
                            setkorr_ub3_bis(result.korr_ub3_bis)
                            setkorr_ub3_proz(result.korr_ub3_proz)
                            if (result.Fachgebiete) {
                                setkorr_fachgebiet(result.Fachgebiete.bezeichnung)
                            }
                        }
                        else {
                            console.log("kürzung NZ response not ok")                            
                        }
                    }
                    catch (err) {
                        console.log(err)
                    }
                }
            }
        }
        asyncFetchKuerzungNachZahlungForSelect()
    },[props.data])

    useEffect(() => {
        async function asyncLoadZuverechnendeMassnahmen()
        {
            // console.log("korr_mid_verrechnung " + korr_mid_verrechnung)
            if (korr_mid_verrechnung) {
               const response = await fetch('data/verrechnungen/zuverrechnen/' + korr_mid_verrechnung, { method: 'GET' })
               if (response.ok)
               {
                    const res = await response.json();                    
                    if (Array.isArray(res)) {
                        setvrlist(res)
                    }
               }
            }
        }        
        asyncLoadZuverechnendeMassnahmen();      
    }, [korr_mid_verrechnung]);

    const cmdBescheinigungForClick = ( async (evt) => {
        const mid = Number.parseInt(evt.currentTarget.getAttribute('mid'))
        // console.log(mid)
        const mn = vrlist.find( (item) => { return item.id === mid})
        // console.log(vrlist)
        if (mn) {            
            const res = await BescheinigungErstellen(mid, new Date(Date.parse(mn.f_bis)))
            if (res) {
                if (res.status === 200 && res.data) {                         
                    const pd = po.createPrintData('Nachweis' + mid + '.docx', res.data.printid, mid)                    
                    FileSaver.saveAs( res.data.document, pd.filename);
                }
                else {
                    alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
                }
            }            
        }        
    })

    return (
        <div className='arrange-horizontally'>
            {/* <h3>KuerzungNachZahlung {props}</h3>   */}
            <table>
                <thead>                    
                </thead>
                <tbody>               
                    <tr>
                        <td className='td_label'>Datum:</td><td className='td_label'>{toShortDateString(korr_datum)}</td>
                        <td className='td_blank4'></td>
                        <td className='td_label'>Prozentual:</td><td className='td_label'>{korr_prozentual} %</td>
                        <td className='td_blank4'></td>
                        
                        <td className='td_label'>Unterbrechung 1</td>
                        <td className='td_label'>Von:</td><td className='td_label'>{toShortDateString(korr_ub1_von)}</td>
                        <td className='td_label'>Bis:</td><td className='td_label'>{toShortDateString(korr_ub1_bis)}</td>
                        <td className='td_label'></td><td className='td_label'>{korr_ub1_proz} %</td>
                    </tr>
                    <tr>
                        <td className='td_label'>Von:</td><td className='td_label'>{toShortDateString(korr_von)}</td>
                        <td className='td_blank4'></td>
                        <td className='td_label'>Bis:</td><td className='td_label'>{toShortDateString(korr_bis)}</td>
                        <td className='td_blank4'></td>
                        <td className='td_label'>Unterbrechung 2</td>
                        <td className='td_label'>Von:</td><td className='td_label'>{toShortDateString(korr_ub2_von)}</td>
                        <td className='td_label'>Bis:</td><td className='td_label'>{toShortDateString(korr_ub2_bis)}</td>
                        <td className='td_label'></td><td className='td_label'>{korr_ub2_proz} %</td>
                    </tr>
                    <tr>
                        <td className='td_label'>Fachgebiet:</td><td className='td_label'>{korr_fachgebiet}</td>
                        <td className='td_blank4'></td>
                        <td className='td_label'>MID:</td>
                            <td className='td_label'>
                            { korr_mid_verrechnung && korr_mid_verrechnung.split(",").map( (item) => {
                                return <span key={item}><a href={`/Massnahme/Edit?mid=${item.trim()}&tabpage=2`}>{item}</a>&nbsp;</span>
                            })
                            }</td>
                        <td className='td_blank4'></td>
                        <td className='td_label'>Unterbrechung 3</td>
                        <td className='td_label'>Von:</td><td className='td_label'>{toShortDateString(korr_ub3_von)}</td>
                        <td className='td_label'>Bis</td><td className='td_label'>{korr_ub3_bis}</td>
                        <td className='td_label'></td><td className='td_label'>{korr_ub3_proz} %</td>
                    </tr>
                    <tr>                        
                        <td className='td_label'>Betrag:</td><td className='td_label'>{formatCurrency(korr_Betrag, 2, '€')}</td>
                        <td className='td_blank4'></td>
                        <td className='td_label'>Differenz:</td><td className='td_label'>{formatCurrency(korr_differenz, 2, '€')}</td>                        
                    </tr>
                </tbody>
            </table>
            { vrlist && vrlist.length > 0 &&
            <table>
                <thead>
                    <tr>
                        <th colSpan={2}>Zu verrechnende Massnahmen</th>
                    </tr>
                </thead>
                <tbody>
                     { vrlist.map ( 
                        (item) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>
                                        <button className="exportbtn warnbtn"
                                            mid={item.id}
                                            onClick={cmdBescheinigungForClick}
                                            disabled={!canEdit} // {!canEdit || calcDisabled(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                                        >
                                        <ConfigIcon><AiOutlineFileWord/></ConfigIcon>&nbsp;&nbsp;Bescheinigung erstellen
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }                    
                </tbody>
            </table>            
            }
            <div>
                <NonModal id={nonmodalid} type='yesno' title='Druckbestätigung' message={po.printmessage} callback={documentprint_callback} data={po.printid}></NonModal>
            </div>
        </div>
    )
}

export default KuerzungNachZahlung
