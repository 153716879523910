import './nonmodal.css'

function NonModal(props) 
{
    const closeModal = (() => {        
        document.getElementById(props.id).style.display = 'none';
        props.callback('close', null)
        // childaction('show', false)
    })

    const btnOkClick = ( () => {
        // console.log("OK Click")
        props.callback('ok', props.data)
        closeModal()
    })

    const btnYesClick = ( () => {
        // console.log("Yes Click")
        props.callback('yes', props.data)
        closeModal()
    })

    const btnNoClick = ( () => {
        // console.log("No Click")
        props.callback('no', props.data)
        closeModal()
    })

    const btnCancelClick = ( () => {
        // console.log("Cancel Click")
        props.callback('cancel', props.data )
        closeModal()
    })

    // window.onclick = function(event) 
    // {
    //     let modal = document.getElementById(props.id);
    //     if (event.target == modal) {
    //         modal.style.display = "none";
    //     }
    // }
    
    return (
        <div id={props.id} className='nonmodal'>
            <div className='nonmodal-content'>
                <div className='nonmodal-header'>                    
                    <table className='sm_table'><tbody>
                        <tr>
                            <td><h3>{props.title}</h3></td><td align='right'><span className='close' onClick={closeModal}>&times;</span></td>
                        </tr>
                    </tbody></table>
                </div>
                <div className='nonmodal-body'>
                    {props.message && <>
                        {props.message.split('\n').map( (item, index) => {
                            return <div key={index}>{item}<br/></div>
                        })
                        }
                    </>}                        
                    {!props.message && <h3>Error: message attribute not set</h3> }
                </div>
                <div className='nonmodal-footer'>
                    {
                        props.type &&
                        <table className='sm_table'>
                            <tbody>
                                <tr>
                                    { (props.type ==='ok' || props.type === 'okcancel') &&                         
                                    <td className='td_button' align={props.type === 'ok' ? 'center' : 'right'}>
                                    <button className='exportbtn'
                                        onClick={btnOkClick}
                                    >
                                        Ok
                                    </button>   
                                    </td>
                                    }
                                    
                                    { (props.type ==='yesno' || props.type === 'yesnocancel') &&                         
                                    <td className='td_button' align='right'>
                                        <button className='exportbtn'
                                            onClick={btnYesClick}
                                        >
                                            Ja
                                        </button>                                    
                                    </td>
                                    }
                                    { (props.type ==='yesno' || props.type === 'yesnocancel') && 
                                    <td className={props.type === 'yesno' ? 'td_button' : 'td_fitwidth'}>
                                        <button className='exportbtn'
                                            onClick={btnNoClick}
                                        >
                                            Nein
                                        </button>
                                    </td>
                                    }
                                    { (props.type ==='okcancel' || props.type === 'yesnocancel') && 
                                    <td className='td_button'>
                                        <button className='exportbtn'
                                            onClick={btnCancelClick}
                                        >
                                            Abbrechen
                                        </button>
                                    </td>
                                    }

                                </tr>
                            </tbody>
                        </table>                        
                    }
                    { !props.type && <h3>Error: type attribute not set</h3> }
                </div>
            </div>
        </div>
    )
}

export default NonModal;