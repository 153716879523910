import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './accordion.css'



export class Accordion extends Component 
{
    // get buttonid() {
    //     return "accordionbutton" + this.id
    // }

    constructor(props)
    {
        super(props)                
        const id = Math.ceil(10000 * Math.random())
        this.state = { buttonid :  "accordionbutton" + id }
        // this.id = Math.ceil(10000 * Math.random())
        this.componentDidMount()
    }

    componentDidMount()
    {
        // console.log("componentDidMount  " + JSON.stringify(this.props))        
        const elem = document.getElementById(this.state.buttonid)   
        if (elem)
        {     
            const next = elem.nextElementSibling        
            if ((next) && (this.props.display !== 'undefined'))
            {
                this.setpaneldisplay(elem, next, this.props.display)
            }
        }
    }    

    setpaneldisplay(button, panel, display)
    {
        // console.log("this.setpaneldisplay")
        if (display)
        {                       
            panel.style.display = 'block'
            if (button) {
                button.classList.add("active")
            } 
            if (this.props.notify) { this.props.notify('expanded', true) }
        }
        else
        {    
            panel.style.display = 'none'
            if (this.props.notify) { this.props.notify('expanded', false) }
        }
    }
    
    // componentDidUpdate(prevProps, prevState, snapshot)
    // {
    //     // console.log("componentDidUpdate " + prevState + " " + JSON.stringify(prevProps) + " " + snapshot)
    //     // const elem = document.getElementById(this.#buttonid)
    //     // console.log(elem)
    //     // console.log(elem.nextElementSibling)
    // }

    // componentWillUnmount()
    // {
    //     console.log("componentWillUnmount")
    // }
    
    buttonClick = ((evt) => {
        
        /* Toggle between adding and removing the "active" class, to highlight the button that controls the panel */
        evt.target.classList.toggle("active");
        /* Toggle between hiding and showing the active panel */
        var panel = evt.target.nextElementSibling;
        this.setpaneldisplay(null, panel, (panel.style.display === "none"))        
    })

    render() {        
        return (<div>
            <button
                id={this.state.buttonid} 
                className="accordion" 
                onClick={this.buttonClick}>{this.props.title ? this.props.title : "provide a 'title' attribute"}                
            </button>
            {                
                this.props.getpanel && this.props.getpanel( {data : this.props.data } )                
            } 
            {
                !this.props.getpanel && <div className="accordionpanel">
                    <p>
                        Provide a Function 'getpanel' return the inline Panel.<br></br>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore 
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea 
                        commodo consequat. 
                    </p>
                </div>
            }
        </div>)
    }
}

export default Accordion

// const Accordion = props => {

//     const buttonClick = ((evt) => {
//         console.log(evt.target)
//         /* Toggle between adding and removing the "active" class,
//            to highlight the button that controls the panel */

//         evt.target.classList.toggle("active");

//         /* Toggle between hiding and showing the active panel */
//         var panel = evt.target.nextElementSibling;
//         if (panel.style.display === "none") 
//         {
//             panel.style.display = "block";            
//         } 
//         else
//         {
//             panel.style.display = "none";            
//         }
//     })
    
//     return (
//         <div>        
//             <button 
//                 className="accordion" 
//                 onClick={buttonClick}>{props.title ? props.title : "provide a 'title' attribute"}                
//             </button>
//             {                
//                 props.getpanel && props.getpanel( {data : props.data } )                
//             } 
//             {
//                 !props.getpanel && <div className="accordionpanel">
//                     <p>
//                         Provide a Function 'getpanel' return the inline Panel.<br></br>
//                         Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore 
//                         magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea 
//                         commodo consequat. 
//                     </p>
//                 </div>
//             }           
//         </div>
//     )
// }

// Accordion.propTypes = {

// }

// export default Accordion
