import React from 'react';

const SelectControl = ({ name, value, className, options = [], disabled, readonly, onChange, empty = true }) => {   
    // console.log(value + " " + name + " " + options + "  " + empty) 

    return (
        <select  
            className={className || 'form-control'} 
            name={name}            
            value={ value != null ? value : ''}
            onChange={(e) => onChange ? onChange(e.target.value):null}            
            disabled={disabled || false} 
            readOnly={readonly || false} 
            >
            { (empty ? true : (options ? options.length === 0 : false)) &&
                <option key={null} value=""></option>
            }
            {
                options && options.map(option => {  
                    return <option key={option.key} value={option.key}>{`${option.value}`}</option>
                })
            }
        </select>
    );
}

export default SelectControl;