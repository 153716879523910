
import React, { useState, useEffect } from 'react'

import { selectForGeschlechtCombo } from '../../util/combo-utils.js'
import Spinner from '../../components/shared/spinner/Spinner';
import ConfigIcon from '../../util/configicon';
import FormGroup from '../../components/shared/form-group';

import { toShortDateString } from '../../util/date-utils';
import { formatEinrichtung, formatTeilnehmer } from '../../util/formate.js';

const DoublettenSuche = () =>
{   
    // const resultColumns = [
    //     { title:'Teilnehmer',  field:'Teilnehmer',   cellStyle: { width : '10%', whiteSpace: 'nowrap'}, headerStyle: { width : '10%' }, width:'10%', minWidth:'10%',maxWidth:'10%' },
    //     { title:'Einrichtung', field:'Einrichtung',  cellStyle: { width : '50%', whiteSpace: 'nowrap'}, headerStyle: { width : '50%' }, width:'50%', minWidth:'50%',maxWidth:'50%' },
    //     { title:'MID',         field:'MID',          width:'10%' },        
    //     { title:'Von',         field:'Von',          width:'5%', type: "date" , render: rd => toShortDateString(rd.Von) },
    //     { title:'Bis',         field:'Bis',          width:'5%', type: "date" , render: rd => toShortDateString(rd.Bis) },
    //     { title:'Status',      field:'Status',       width:'5%'  },
    //     { title:'Fachgebiet',  field:'Fachgebiet',   width:'5%'  },
    //     { title:'Monate',      field:'Monate',       width:'5%'  },
    //     { title:'Auszahlung',  field:'Auszahlung',   width:'5%'  },
    // ];
    // const resultColumnsMUI = [
    //     { label:'Teilnehmer',  name:'Teilnehmer',   width:'10%', minWidth:'10%',maxWidth:'10%' },
    //     { label:'Einrichtung', name:'Einrichtung',  width:'50%', minWidth:'50%',maxWidth:'50%' },
    //     { label:'MID',         name:'MID',          width:'10%' },        
    //     { label:'Von',         name:'Von',          width:'5%'  },
    //     { label:'Bis',         name:'Bis',          width:'5%'  },
    //     { label:'Status',      name:'Status',       width:'5%'  },
    //     { label:'Fachgebiet',  name:'Fachgebiet',   width:'5%'  },
    //     { label:'Monate',      name:'Monate',       width:'5%'  },
    //     { label:'Auszahlung',  name:'Auszahlung',   width:'5%'  },
    // ];
    
    const [loadedState, setLoadState] = useState(2)
    const [message, setMessage] = useState(null)
    const [duplicateList, setDuplicateList] = useState( [{}])
    const [selectedDuplicate, setSelectedDuplicate] = useState(null)
    const [possibleDuplicates, setPossibleDuplicates] = useState(null)
    const [possibleDuplicate, setPossibleDuplicate] = useState(null)
    const [selectedDuplicateIndex, setSelectedDuplicateIndex] = useState(-1)    
    const [possibleDuplicateIndex, setPossibleDuplicateIndex] = useState(-1)
    const [geschlechter, setGeschlechter] = useState(null)

    useEffect( () =>
    {
        async function loadGeschlecht()
        {
            const result = await selectForGeschlechtCombo()
            setGeschlechter(result)            
        }
        loadGeschlecht()
    }, [])
    
    useEffect(() => 
    {
        async function loadDoubletten()
        {
            try {                
                const response = await fetch('/data/doubletten')
                if (response.ok) {
                    const result = await response.json();                      
                    if (Array.isArray(result))
                    {                        
                        if (result.length>0)
                        {                        
                            // console.log(result[0])
                            setDuplicateList(result)
                            setSelectedDuplicateIndex(result[0].id)                            
                        }
                        else
                        {
                            // Message not found
                        }
                        setLoadState(0)
                        return;
                    }
                    throw new Error("")                                    
                }
                else
                {                    
                    throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                setMessage(err)
                console.log(err)
            }
        }
        loadDoubletten();
    }, [])

    useEffect(() => {
      async function loadDetails()
      {        
        if (!selectedDuplicateIndex)
        {
            return;
        }
        if (selectedDuplicateIndex <= 0)
        {
            return;            
        }
        const sd = duplicateList.find( (item) => item.id === selectedDuplicateIndex)
        if (!sd) 
        {
            // console.log(err)
            // setMessage(msg);
            return;
        }                
        try
        {
            const response = await fetch('/data/doubletten/' + selectedDuplicateIndex)
            if (response.ok) {
                const result = await response.json();    
                if (Array.isArray(result))
                {
                    if (result.length>0)
                    {   
                        console.log(result)
                        setSelectedDuplicate(result);                        
                    }
                    else
                    {
                        const msg = "Keine Beschäftigung für TID" + selectedDuplicateIndex + " gefunden"
                        console.log(msg);                        
                        setMessage(msg);
                        return;                        
                    }
                }
            }
            else
            {                    
                throw new Error(response.status + " " + response.statusText)
            }
        }
        catch (err) 
        {
            console.log(err)
            setMessage(err)            
            return;
        }
        try
        {            
            const data = 
            {
                id : sd.id,
                vorname : sd.vorname,
                nachname  : sd.nachname,
                geburtsname : sd.geburtsname
            }
            const response = await fetch('/data/doubletten/find', 
            {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(data)
            });            
            if (response.ok) 
            {
                const result = await response.json();  
                if (Array.isArray(result) && result.length > 0)
                {      
                    // console.log(result)             
                    setPossibleDuplicates(result);
                    setPossibleDuplicateIndex(result[0].id);
                    setPossibleDuplicate(result[0]);
                }
                else 
                {
                    console.log("no possible duplicate found")
                    setPossibleDuplicates(null)
                    setPossibleDuplicateIndex(-1)
                    setPossibleDuplicate(null)
                }
            }
            else 
            {
                const msg = response.status + " " + response.statusText
                console.log(msg)
                setMessage(msg)
            }
        }
        catch (err)
        {
            console.log(err)
            setMessage(err)            
            return;
        }        
      }
      loadDetails();
    }, [selectedDuplicateIndex])
    

    const duplicatesClick = ((evt) => 
    {    
        // select Duplicate from Duplicate LIST
        const idattribute = evt.target.parentElement.attributes['id']       
        if (idattribute) {
            const val = idattribute.value
            console.log("selected duplicate id = " + val)
            setSelectedDuplicateIndex(Number.parseInt(val))
        }
    })

    const selectedDuplicateClick = ((evt) => {
        const idattribute = evt.target.parentElement.attributes['id']
        if (idattribute) {
            const val = idattribute.value        
            // console.log("selected duplicate id = " + val);
        }
    })

    const possibleDuplicateClick = ((evt) => {
        const idattribute = evt.target.parentElement.attributes['id']
        if (idattribute) {
            const val = parseInt(idattribute.value)        
            setPossibleDuplicateIndex(val)            
            const duplo = possibleDuplicates.find( (dup) => dup.id === val)
            setPossibleDuplicate(duplo)
        }
    })  
    
    const mergeSelected = ((evt) => {
        merge(possibleDuplicate.id, selectedDuplicate[0].id)
    })

    const mergePossible = ((evt) => {
        merge(selectedDuplicate[0].id, possibleDuplicate.id)
    })

    async function merge(quellId, zielId) {
        if(window.confirm("Übertrage Daten des Teilnehmers mit der ID:" + quellId + " in den Teilnehmer mit der ID:" + zielId + 
            ". Anschließend wird der Teilnehmer mit der ID:" + quellId + " gelöscht"))
        {           
            try
            {
                const data = 
                {
                    quelle : quellId,
                    ziel : zielId
                }
                const response = await fetch('/data/doubletten/merge', 
                {
                    headers: { 'Content-Type': 'application/json' },
                    method: 'POST',
                    body: JSON.stringify(data)
                });            
                if (response.ok) 
                {
                    const result = await response.text();  
                    alert(result)
                    document.location.reload(false)
                }
                else
                {
                    const msg = response.status + " " + response.statusText
                    alert(msg)
                    console.log(msg)
                }
            }
            catch (err)
            {
                console.log(err)
            }
        }
    }

    function lookupGeschlecht(id)
    {
        if (geschlechter)
        {
            const g = geschlechter.find( (item) => { return item.key === id })
            if (g) {
                return g.value
            }
        }        
        return id;
    }

    return (
        <div>
            {
            loadedState === 0 &&
                <div className="sm_table_div"> 
                    <table className='sm_table' style={{margin:'10px'}} border='1'>
                        <tbody>                            
                            <tr>                                
                                <td width='50%'>
                                    <h4>1. Duplikate</h4>
                                    <div className='table-fixed-header' style={{height:'400px'}}>                                    
                                        <table className='sm_table'>
                                            <thead>                                            
                                                <tr>
                                                    <th className='td_label' align={'left'}>Id</th>
                                                    <th className='td_label' align={'left'}>Nachname</th>
                                                    <th className='td_label' align={'left'}>Vorname</th>
                                                    <th className='td_label' align={'left'}>Geburtsdatum</th>
                                                    <th className='td_label' align={'left'}>Geburtsname</th>
                                                </tr>
                                            </thead>
                                            <tbody>                                               
                                                { 
                                                duplicateList.map( ({ id, titel, vorname, nachname, geburtsname, geburtsdatum}) => 
                                                {
                                                    return <tr key={id} id={id} onClick={duplicatesClick} 
                                                        style={ (id===selectedDuplicateIndex) ? {backgroundColor:'#DDD'} : {} }>
                                                        <td className='td_label td_border'>{id}</td>
                                                        <td className='td_label td_border'>{nachname}</td>
                                                        <td className='td_label td_border'>{vorname}</td>
                                                        <td className='td_label td_border'>{toShortDateString(geburtsdatum)}</td>
                                                        <td className='td_label td_border'>{geburtsname}</td>
                                                    </tr>
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </td>                                
                                <td valign='top' width='50%'>
                                    <h4>4. Zusammenführen</h4>
                                    <table className='sm_table'>
                                        <tbody>
                                            <tr></tr>
                                            <tr className='table-blank-row'>
                                                <td width='33%'/>
                                                <td width='33%'/>
                                                <td width='33%'/>
                                            </tr>
                                            <tr>
                                                <th className='duplicatecompare td_border td_label'>ID</th>
                                                { selectedDuplicate ? <td className='td_label td_border'>{selectedDuplicate[0].id}</td> : <td></td> }
                                                { possibleDuplicate ? <td className='td_label td_border'>{possibleDuplicate.id}</td> :  <td></td> }                                                
                                            </tr>
                                            <tr>
                                                <th className='duplicatecompare td_border td_label'>Nachname</th>
                                                { selectedDuplicate ? <td className='td_label td_border'>{selectedDuplicate[0].nachname}</td> : <td></td> }
                                                { possibleDuplicate ? <td className='td_label td_border'>{possibleDuplicate.nachname}</td> :  <td></td> }
                                            </tr>
                                            <tr>
                                                <th className='duplicatecompare td_border td_label'>Geburtsname</th>
                                                { selectedDuplicate ? <td className='td_label td_border'>{selectedDuplicate[0].geburtsname}</td> : <td></td> }
                                                { possibleDuplicate ? <td className='td_label td_border'>{possibleDuplicate.geburtsname}</td> :  <td></td> }
                                            </tr>
                                            <tr>
                                                <th className='duplicatecompare td_border td_label'>Geburtsdatum</th>
                                                { selectedDuplicate ? <td className='td_label td_border'>{toShortDateString(selectedDuplicate[0].geburtsdatum)}</td> : <td></td> }
                                                { possibleDuplicate ? <td className='td_label td_border'>{toShortDateString(possibleDuplicate.geburtsdatum)}</td> :  <td></td> }
                                            </tr>
                                            <tr>
                                                <th className='duplicatecompare td_border td_label'>Vorname</th>
                                                { selectedDuplicate ? <td className='td_label td_border'>{selectedDuplicate[0].vorname}</td> : <td></td> }
                                                { possibleDuplicate ? <td className='td_label td_border'>{possibleDuplicate.vorname}</td> :  <td></td> }
                                            </tr>
                                            <tr>
                                                <th className='duplicatecompare td_border td_label'>Geschlecht</th>
                                                { selectedDuplicate ? <td className='td_label td_border'>{
                                                    lookupGeschlecht(selectedDuplicate[0].geschlecht)}</td> : <td></td> }
                                                { possibleDuplicate ? <td className='td_label td_border'>{
                                                    lookupGeschlecht(possibleDuplicate.geschlecht)}</td> :  <td></td> }
                                            </tr>
                                            <tr>
                                                <th className='duplicatecompare td_border td_label'>Arztnummer</th>
                                                { selectedDuplicate ? <td className='td_label td_border'>{selectedDuplicate[0].arztnr}</td> : <td></td> }
                                                { possibleDuplicate ? <td className='td_label td_border'>{possibleDuplicate.arztnr}</td> :  <td></td> }
                                            </tr>
                                            <tr className='table-blank-row'></tr>
                                            <tr>
                                                <th></th>
                                                { selectedDuplicate && possibleDuplicate ? 
                                                    <td>
                                                        <button className='exportbtn' onClick={mergeSelected}>Auswählen</button>
                                                    </td> : <td></td> 
                                                }
                                                { selectedDuplicate && possibleDuplicate ? 
                                                    <td>
                                                        <button className='exportbtn' onClick={mergePossible}>Auswählen</button>
                                                    </td> : <td></td> }
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>                               
                            </tr>

                            <tr>
                            { selectedDuplicate &&                               
                                <td valign='top' height={200}>
                                    <h4>2. Gewähltes Duplikat</h4>
                                    <div className='table-fixed-header' style={{height:'200px'}}>     
                                    <table className='sm_table'>
                                        <thead>
                                            <tr>
                                                <th className='td_label' align='left' width={'8%'}>Id</th>
                                                <th className='td_label' align='left' width={'20%'}>Nachname</th>
                                                <th className='td_label' align='left' width={'20%'}>Vorname</th>
                                                <th className='td_label' align='left' width={'12%'}>Geburtsdatum</th>
                                                <th className='td_label' align='left' width={'20%'}>Geburtsname</th>
                                                <th className='td_label' align='left' width={'15%'}>Arztnummer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedDuplicate.map( ({id, nachname, vorname, geburtsdatum, geburtsname, arztnr,
                                                Beschaeftigungens}) => {
                                                    return <React.Fragment key={'F'+id}>
                                                        <tr key={id} id={id} onClick={selectedDuplicateClick} 
                                                            style={ (id===selectedDuplicateIndex) ? {backgroundColor:'#DDD'} : {} }>
                                                            <td className='td_border td_label'>{id}</td>
                                                            <td className='td_border td_label'>{nachname}</td>
                                                            <td className='td_border td_label'>{vorname}</td>
                                                            <td className='td_border td_label'>{toShortDateString(geburtsdatum)}</td>
                                                            <td className='td_border td_label'>{geburtsname}</td>
                                                            <td className='td_border td_label'>{arztnr}</td>
                                                        </tr>
                                                        {Beschaeftigungens && Beschaeftigungens.map( ({id, Einrichtungen}) => {
                                                            return <tr key={id} id={id}>
                                                                <td></td>
                                                                <th>Einrichtung</th>
                                                                <td colSpan={3}>
                                                                    {formatEinrichtung(Einrichtungen) }
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </React.Fragment>
                                            })}
                                        </tbody>
                                    </table>                                    
                                    </div>                                    
                                </td>
                                }                               
                                { possibleDuplicates &&                               
                                <td colSpan={2} valign='top' height={200}>
                                    <h4>3. Mögliche Duplikate</h4>
                                    <table className='sm_table'>
                                        <thead>
                                            <tr>
                                                <th className='td_label' align='left' width={'8%'}>Id</th>
                                                <th className='td_label' align='left' width={'20%'}>Nachname</th>
                                                <th className='td_label' align='left' width={'20%'}>Vorname</th>
                                                <th className='td_label' align='left' width={'12%'}>Geburtsdatum</th>
                                                <th className='td_label' align='left' width={'20%'}>Geburtsname</th>
                                                <th className='td_label' align='left' width={'15%'}>Arztnummer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {possibleDuplicates.map( ({id, nachname, vorname, geburtsdatum, geburtsname, arztnr,
                                                Beschaeftigungens}) => {
                                                    return <React.Fragment key={'P' + id}>
                                                        <tr key={id} id={id} onClick={possibleDuplicateClick} 
                                                            style={ (id===possibleDuplicateIndex) ? {backgroundColor:'#DDD'} : {} }>
                                                            <td className='td_border td_label'>{id}</td>
                                                            <td className='td_border td_label'>{nachname}</td>
                                                            <td className='td_border td_label'>{vorname}</td>
                                                            <td className='td_border td_label'>{toShortDateString(geburtsdatum)}</td>
                                                            <td className='td_border td_label'>{geburtsname}</td>
                                                            <td className='td_border td_label'>{arztnr}</td>
                                                        </tr>                                                       
                                                        {Beschaeftigungens && Beschaeftigungens.map( ({id, Einrichtungen}) => {
                                                            return <tr key={id} id={id}>
                                                                <td></td>
                                                                <th>Einrichtung</th>
                                                                <td colSpan={3}>
                                                                    {formatEinrichtung(Einrichtungen) }
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </React.Fragment>
                                            })}
                                        </tbody>
                                    </table>                                    
                                </td>
                                }
                            </tr>                                         
                        </tbody>
                    </table>                    
                </div>
            }
            { loadedState===1 && <>{message}</>}           
            { loadedState===2 && <Spinner /> }
        </div>
    )
}

export default DoublettenSuche; 