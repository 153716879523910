import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import FormGroup from '../../components/shared/form-group';
import Styles from '../../util/Styles';
import Dates from '../../util/Dates';

import { selectForFachgebietCombo } from '../../util/combo-utils';
import { toShortDateString } from '../../util/date-utils';
import { formatCurrency, round } from '../../util/number-utils';
// import { VERSION } from 'luxon';
import { FetchService } from '../../services';
import { formatEinrichtung, formatTeilnehmer } from '../../util/formate';
import Values from '../../util/Values';

const KuerzungNachZahlungDialog = (props) => {

    function callbackFromParent(cmd, param) {        
        if (cmd === 'show') {
            // console.log(cmd + " " + param)
            setvisible(param)
        } 
    }

    props.callback('actionfunc', callbackFromParent)

    const canceldata = (()=> {
        props.callback('close', null)
    })    

    const td_label = 'td_label'
    const td_blue_label = 'td_label'
    const td_head = 'td_label'

    const [visible, setvisible] = useState(false)
    
    const [betragausgezahlt, setbetragausgezahlt] = useState(0)
    const [neuerbetrag, setneuerbetrag] = useState(0)
    const [differenzbetrag, setdifferenzbetrag] = useState(0)
    const [offenerbetrag, setoffenerbetrag] = useState(0)

    const [fachid, setFachid] = useState(null)

    const [mstart, setmstart] = useState('Invalid Date')
    const [mend, setmend] = useState('Invalid Date')     
    const [mprozentual, setmprozentual] = useState(100)


    const [korr_id, setkorr_id] = useState(null)
    // const [korr_mid, setkorr_mid] = useState(null)
    const [korr_mid_verrechnung, setkorr_midverrechung] = useState(null)
    const [korr_datum, setkorr_datum] = useState(null)
    const [start, setstart] = useState('Invalid Date')
    const [end, setend] = useState('Invalid Date')     
    const [prozentual, setprozentual] = useState(100)
    const [ub1von, setub1von] = useState('Invalid Date')    
    const [ub2von, setub2von] = useState('Invalid Date')
    const [ub3von, setub3von] = useState('Invalid Date')
    const [ub1bis, setub1bis] = useState('Invalid Date')
    const [ub2bis, setub2bis] = useState('Invalid Date')
    const [ub3bis, setub3bis] = useState('Invalid Date')
    const [ub1proz, setub1proz] = useState(0)
    const [ub2proz, setub2proz] = useState(0)
    const [ub3proz, setub3proz] = useState(0)
    const [korr_temp, setkorr_temp] = useState(null)
    
    const [teilnehmer, setteilnehmer] = useState(null)
    const [einrichtung, seteinrichtung] = useState(null)

    const [berechnungsliste, setBerechnungsliste] = useState(null)

    const [massnahmen, setmassnahmen] = useState(null)
    const [errortext, seterrortext] = useState(null)
    const [initcomplete, setinitcomplete] = useState(3)


    const [fachgebietSelectData, setFachgebietSelectData] = useState([{key:0, value:""}])
    useEffect( () => {
        async function asyncFetchFachgebietDataForSelect()
        {            
            setFachgebietSelectData(await selectForFachgebietCombo())            
        }
        if (visible) {
            asyncFetchFachgebietDataForSelect()
        }
    },[visible])

    useEffect( ()=> {
        async function asyncFetchMassnahme() {
            if (props.mid)
            {
                try {                    
                    const response = await fetch('/data/massnahmen/' + props.mid, { method: 'GET' })
                    if (response.ok) 
                    {                        
                        const res = await response.json();                         
                        // console.log("MASSNAHME: " + JSON.stringify(res))  
                        if (!res.kuerzungennachzahlung_id) 
                        {
                            setstart(res.f_von)
                            setend(res.f_bis)
                            setprozentual(res.prozentual)
                            setFachid(res.fachId) 
                            setinitcomplete(2)
                        } 
                        else 
                        {
                            setinitcomplete(1)
                        }                        
                        setmstart(res.f_von)
                        setmend(res.f_bis)
                        setmprozentual(res.prozentual)
                        setFachid(res.fachId) 
                        setneuerbetrag(res.betragAusgezahlt)                        
                        setbetragausgezahlt(res.betragAusgezahlt)  
                        seteinrichtung(res.Einrichtungen)
                        setteilnehmer(res.Teilnehmer)
                    }
                    else
                    {
                        seterrortext(response.status + " " + response.statusText)
                    }
                }
                catch (err) {
                    seterrortext(err)
                }
            } 
            else 
            {
                seterrortext("Keine Massnahme ID angegeben")
            }
        }

        async function asyncFetchKuerzungNachZahlung()
        {
            if (props.mid)
            {
                try {                    
                    const response = await fetch('/data/kuerzungennachzahlung/mid/' + props.mid, { method: 'GET' })
                    if (response.ok) 
                    {                        
                        const res = await response.json();   
                        if (Array.isArray(res) && res.length === 1)
                        {
                            // console.log("KUERZUNG NZ: " + JSON.stringify(res[0].KuerzungenNachZahlung))
                            const knz = res[0].KuerzungenNachZahlung;                            
                            setkorr_id(knz.id)
                            // setkorr_mid(knz.massnahmen_id)
                            setkorr_midverrechung(knz.korr_mid_verrechnung)
                            setkorr_datum(knz.korr_datum)
                            setub1von(knz.korr_ub1_von ?? 'Invalid Date')
                            setub1bis(knz.korr_ub1_bis ?? 'Invalid Date')
                            setub1proz(knz.korr_ub1_proz ?? 0)                            
                            setub2von(knz.korr_ub2_von ?? 'Invalid Date')
                            setub2bis(knz.korr_ub2_bis ?? 'Invalid Date')
                            setub2proz(knz.korr_ub2_proz ?? 0)
                            setub3von(knz.korr_ub3_von ?? 'Invalid Date')
                            setub3bis(knz.korr_ub3_bis ?? 'Invalid Date')
                            setub3proz(knz.korr_ub3_proz ?? 0)

                            setstart(knz.korr_von)              // : "2018-05-31T00:00:00.000Z"
                            setFachid(knz.korr_fachid)
                            setprozentual(knz.korr_prozentual)
                            setend(knz.korr_bis)                // : "2018-02-13T00:00:00.000Z"
                            setkorr_temp(knz.korr_temp)
                            
                            setinitcomplete( initcomplete -1)                                                       
                        }
                    }
                    else
                    {
                        seterrortext(response.status + " " + response.statusText)
                    }
                }
                catch (err) {
                    seterrortext(err)
                }
            }
        }

        async function asyncFetchMassnahmenZurVerrechnung()
        {
            if (props.mid)
            {
                try 
                {                    
                    const response = await fetch('data/kuerzungennachzahlung/massnahmen/find/' + props.mid, { method: 'GET' })
                    if (response.ok) 
                    {                        
                        const res = await response.json();  
                        // console.log(res)                                               
                        setmassnahmen(res)  
                        setinitcomplete( initcomplete -1)                        
                    }
                    else
                    {
                        seterrortext(response.status + " " + response.statusText)
                    }
                }
                catch (err)
                {
                    seterrortext(err) 
                }
            }            
        }

        async function init()
        {
            await asyncFetchMassnahme()
            await asyncFetchKuerzungNachZahlung()
            await asyncFetchMassnahmenZurVerrechnung()            
            // console.log("initcomplete " + initcomplete)
            setinitcomplete(0)       
        }
        if (visible) {        
            init()
        }
    },[visible])

    useEffect(() => {
        async function asyncSetKorrektur()
        {
            if (massnahmen && massnahmen.length > 0 && korr_temp)
            {
                // console.log(massnahmen)
                // console.log("korr " + korr_temp)
                const korr = JSON.parse(korr_temp)
                korr.forEach( (k) => {                    
                    const mm = massnahmen.find( (item) => (item.id === k.mid && !item.verrechnungen_id) )   
                    if (mm) {               
                        // console.log(mm.verrechnungen_id)
                        mm.betragBestaetigt += k.diff 
                    }
                })
            }
        }
        asyncSetKorrektur()
    }, [massnahmen, korr_temp]);
        
    useEffect(() => {
        async function initBerechnung()
        {
            berechnenClick()
        }
        // console.log("initBerechnung called " + initcomplete)
        if (initcomplete === 0) {
            initBerechnung()      
        }
    }, [initcomplete]);
    
    function buildrange(s,e,p) {
        return {
            start : s,
            end : e,
            proz : p,
            betrag : 0
        }
    }

    function validateRanges(ranges)
    {
        if(ranges && ranges.length > 1)
        {
            // console.log("Ranges length > 1")
            for (let num = 0; num < ranges.length-1; num++ )
            {                
                const sd = ranges[num].start
                const ed = ranges[num].end
                for (let n2 = num+1; n2 < ranges.length; n2++)
                {
                    // console.log("Validate Range " + num + " gegen " + (n2))
                    const s = ranges[n2].start
                    const e = ranges[n2].end
                    if ((s < sd) && (e >= sd))
                    {
                        seterrortext("Unterbrechung " + ranges[num].id + " überlappt mit Unterbrechung " + ranges[n2].id)
                        return false
                    }
                    if ((s <= ed) && (e > sd))
                    {
                        seterrortext("Unterbrechung " + ranges[num].id + " überlappt mit Unterbrechung " + ranges[n2].id)
                        return false
                    }
                }
            }                       
        }
        return true
    }

    function buildranges(ranges, s1, e1, p1, s2, e2, p2, s3, e3, p3)
    {
        const tmp = []

        if (ranges & 1) {
            tmp.push(buildrange(s1,e1, p1))
        }
        if (ranges & 2) {
            tmp.push(buildrange(s2, e2, p2))           
        }
        if (ranges & 4) {
            tmp.push(buildrange(s3, e3, p3))            
        }
        return tmp;
    }

    function validateRange(sd, ed, s, e, bereich) 
    {
        if (s < sd || s > ed) {
            seterrortext("Unterbrechung " + bereich + ": Beginn der Unterbrechung kleiner als das Startdatum oder größer als das Enddatum")
            return false;
        }
        if (e < sd || e > ed) {
            seterrortext("Unterbrechung " + bereich + ": Ende der Unterbrechung kleiner als das Startdatum oder größer als das Enddatum")
            return false;
        }
        if (s>e) {
            seterrortext("Unterbrechung " + bereich + ": Das Startdatum der Unterbrechung ist größer als das Enddatum der Unterbrechung")
            return false;
        }
        return true;
    }

    const btnVerrechnungBerechnenClick = ( async() => {
        berechnenClick()
    })
    
    async function berechnenClick()
    {      
        const result = []
        seterrortext('');     
        if (!Dates.isvalid(start)) 
        {
            seterrortext("Startdatum ungültig")
            return;
        }
        if (!Dates.isvalid(end))
        {
            seterrortext("Enddatum ungültig")
            return;
        }
        const sd = new Date(start);
        const ed = new Date(end);
        if (sd > ed) {
            seterrortext("Startdatum ist größer als das Enddatum")
            return;
        }

        let s1 = null;
        let e1 = null;
        let s2 = null;
        let e2 = null;
        let s3 = null;
        let e3 = null;
        let ranges = 0;

        if (Dates.isvalid(ub1von) && Dates.isvalid(ub1bis)) 
        {
            s1 = new Date(ub1von)
            e1 = new Date(ub1bis)
            if (!validateRange(sd, ed, s1, e1, 1)) {
                return;
            }           
            ranges |= 1;
        }
        
        if (Dates.isvalid(ub2von) && Dates.isvalid(ub2bis))
        {
            s2 = new Date(ub2von)
            e2 = new Date(ub2bis)
            if (!validateRange(sd, ed, s2, e2, 2)) {
                return;
            }            
            ranges |= 2;
        }

        if (Dates.isvalid(ub3von) && Dates.isvalid(ub3bis))
        {
            s3 = new Date(ub3von)
            e3 = new Date(ub3bis)
            if (!validateRange(sd, ed, s3, e3, 3)) {
                return;
            }            
            ranges |= 4;
        }

        const sv = await FetchService.calculateErstattung( start, end, fachid, prozentual)        
        const kuerzung = buildrange(start, end, prozentual)         
        kuerzung.betrag = - (betragausgezahlt-sv.data.betragBerechnet)
        kuerzung.id = 0
        result.push(kuerzung)

        // console.log(kuerzung)

        let abzug = sv.data.betragBerechnet - betragausgezahlt

        if (ranges > 0) {            
            const rangeData = buildranges(ranges, s1, e1, ub1proz, s2, e2, ub2proz, s3, e3, ub3proz)   
            for (let num=0; num < rangeData.length; num++) {
                rangeData[num].id = (num+1)
            }

            if (!validateRanges(rangeData))
                return;         
            
            for (let num=0; num < rangeData.length; num++) {
                const es3 = await FetchService.calculateErstattung( rangeData[num].start, 
                    rangeData[num].end, fachid, 100)
                
                const betr = es3.data.betragBerechnet * (prozentual - rangeData[num].proz)/100;
                                                
                rangeData[num].betrag = -betr // es3.data.betragBerechnet
                abzug -= betr // es3.data.betragBerechnet                            
                // console.log(rangeData[num])
            }
            // console.log(rangeData)
            rangeData.forEach( (item) => result.push(item))
        }        
        // console.log(result)

        setBerechnungsliste(result)
        // console.log(abzug)
        setdifferenzbetrag (abzug)
        setneuerbetrag ( betragausgezahlt + abzug)
        let summeVerrechnet = 0
        if (massnahmen) {
            massnahmen.forEach ( (item) => { summeVerrechnet += (item.betragBerechnet - item.betragBestaetigt)} )
        }
        let offen = round(abzug + summeVerrechnet, 2, null)
        // console.log("offenerbetrag " + offenerbetrag + " " + typeof(offenerbetrag))
        // console.log("offen       g " + offen         + " " + typeof(offen))
        // console.log( offenerbetrag === offen)

        setoffenerbetrag( offen )   
    }

    // const btnMassnahmenVerrechnenClick = (() => {
    //     console.log("Massnahme mit gefundenen Maßnahmen verrechnen")
    // })

    function hasDifference(diff) {
        return Math.abs(diff * 100) > 1
    } 

    function createSource()
    {        
        return ({
            id : korr_id,
            massnahmen_id : props.mid,
            korr_mid_verrechnung: korr_mid_verrechnung,
            korr_datum : korr_datum, 
            korr_von : start,
            korr_bis : end,
            korr_fachid : fachid,
            korr_prozentual : prozentual,
            korr_betrag     : neuerbetrag,
            korr_differenz  : differenzbetrag,
            korr_ub1_von :  (ub1von === Values.INV_DATE ? null : ub1von),
            korr_ub1_bis :  (ub1bis === Values.INV_DATE ? null : ub1bis) , 
            korr_ub1_proz : ub1proz,
            korr_ub2_von :  (ub2von === Values.INV_DATE ? null : ub2von) ,
            korr_ub2_bis :  (ub2bis === Values.INV_DATE ? null : ub2bis) , 
            korr_ub2_proz : ub2proz,
            korr_ub3_von :  (ub3von === Values.INV_DATE ? null : ub3von) ,
            korr_ub3_bis :  (ub3bis === Values.INV_DATE ? null : ub3bis) , 
            korr_ub3_proz : ub3proz,
            korr_temp : createTemp(),
        })
    }

    function createDest() {
        const result = []
        if (massnahmen && massnahmen.length > 0)
        {
            massnahmen.forEach( (item) => {
                if (item.status === 9 || item.status === 10)
                {
                    result.push(
                        {
                            mid : item.id,
                            betragBerechnet  : item.betragBerechnet, 
                            betragBestaetigt : item.betragBestaetigt
                        }
                    )
                }
            })
        }
        return ( result );
    }

    function createTemp() {
        const result = []
        if (massnahmen && massnahmen.length > 0)
        {
            massnahmen.forEach( (item) => {                
                if (item.status === 9)
                {
                    const diff = item.betragBestaetigt - item.betragBerechnet
                    if (hasDifference( diff ))
                    {
                        result.push({
                            mid  : item.id,
                            diff : diff
                        })
                    }
                }
            })
        }
        return JSON.stringify(result);
    }

    const btnkorrekturverbuchenClick = (async () => {
        if (!window.confirm("Achtung!\nDie Maßnahme wird gekürzt und verrechnet.\n" + 
            "Diese Operation kann nicht rückgängig gemacht werden.\n\n" + 
            "Kürzung vornehmen?"))
        {
            return;
        }

        let success = false
        let result = null
        const data = 
        {
            mid : props.mid,
            src : createSource(),
            dst : createDest()
        }
        // console.log(data)
        // console.log(JSON.stringify(data))
        try {
            const response = await fetch("/data/kuerzungennachzahlung/massnahme/updateall", {
                headers: {                    
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data),
            });
            if (response.ok) {
                result = await response.text();
                seterrortext(result);
                success = true
            }
            else 
            {
                seterrortext(response.status + " " + response.statusText)
            }
            
        }
        catch(err) {
            seterrortext("" + err);
        }
        if (success) {
            props.callback('save', result)
        }
    })

    const btnVerrechnungZurueckstellenClick = ( async() => {
        let success = false
        const data = 
        {
            mid : props.mid,
            src : createSource(),
        }
        // console.log(data)
        // console.log(JSON.stringify(data))
        try {
            const response = await fetch("/data/kuerzungennachzahlung/massnahme/update", {
                headers: {                    
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data),
            });
            if (response.ok) {
                const res = await response.text();                
                seterrortext(res);
                success = true
            }
            else 
            {
                seterrortext(response.status + " " + response.statusText)
            }
        }
        catch(err) {            
            seterrortext("" + err);
        }
        if (success) {
            props.callback('save', null)
        }
    })

    const isaktiondisabled = ( () => {        
        return !hasDifference( differenzbetrag )
    })

    const aktion = ( (evt) => {        
        {
            const id = Number.parseInt(evt.target.id)
            const mm = massnahmen.find( (item) => item.id === id)
            // console.log("Differenzbetrag " + differenzbetrag)
            // console.log("Offen betrag    " + offenerbetrag)
            // console.log("VerrechnungsID  " + mm.verrechnungen_id)
            if (mm.verrechnungen_id)
            {
                seterrortext("Maßnahme ist bereits verrechnet.")
                return;
            }
            if (mm.betragBestaetigt === mm.betragBerechnet)
            {
                if (offenerbetrag !== 0) 
                {
                    let diff = Math.max(offenerbetrag, - mm.betragBerechnet) // negativ
                    // console.log("diff " + diff)
                    mm.betragBestaetigt = mm.betragBerechnet + diff
                    if (mm.betragBestaetigt === 0) {
                        diff = diff + 100
                        mm.betragBestaetigt += 100
                    }
                    setoffenerbetrag(offenerbetrag - diff)
                    // console.log(mm.betragBerechnet + " " + mm.betragBestaetigt)
                }
                else
                {
                    seterrortext("Kein Betrag mehr offen")
                }
            }
            else
            {
                const diff = mm.betragBestaetigt - mm.betragBerechnet // negativ
                mm.betragBestaetigt = mm.betragBestaetigt - diff
                setoffenerbetrag(offenerbetrag + diff)
                // console.log(mm.betragBerechnet + " " + mm.betragBestaetigt)
            }
        }        
    })

    return <div>
        <table className='sm_table' id='mainknztable'>
            <tbody>
                <tr>
                    <td className='td_label td_error' colSpan={3}>{errortext}</td>
                </tr>
                <tr>
                    <td>          
                        <table>
                            <tbody>
                                <tr>
                                    <td className={td_label}>Maßnahme:</td>
                                    <td>
                                        <FormGroup
                                            type={Styles.DefaultDate}  
                                            name="mstart"      
                                            value={mstart}
                                            onChange={setmstart} 
                                            disabled={true}                               
                                        >
                                        </FormGroup></td>
                                    <td>
                                        <FormGroup
                                            type={Styles.DefaultDate}
                                            name="mend"
                                            value={mend}
                                            onChange={setmend}
                                            disabled={true}
                                        >
                                        </FormGroup>
                                    </td>
                                    <td>
                                        <FormGroup
                                            type={Styles.DefaultNumber}
                                            name="mprozentual"
                                            min='0'
                                            max='100'
                                            value={mprozentual}
                                            onChange={setmprozentual}
                                            disabled={true}
                                        >
                                        </FormGroup>
                                    </td>
                                    <td>%</td>
                                </tr>                                
                                <tr>
                                    <td className={td_label}>Fachgebiet:</td>
                                    <td colSpan={2}>
                                        <FormGroup
                                            type={Styles.DefaultCombo}                                        
                                            name="fachgebieteselect"
                                            options={fachgebietSelectData}
                                            value={fachid} 
                                            onChange={setFachid}
                                        >
                                        </FormGroup>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className='table-blank-row4'></tr>    
                                <tr>
                                    <td className={td_label}>Kürzung:</td>
                                    <td>
                                        <FormGroup
                                            type={Styles.DefaultDate}  
                                            name="start"      
                                            value={start}
                                            onChange={setstart}                                
                                        >
                                        </FormGroup></td>
                                    <td>
                                        <FormGroup
                                            type={Styles.DefaultDate}
                                            name="end"
                                            value={end}
                                            onChange={setend}
                                        >
                                        </FormGroup>
                                    </td>
                                    <td>
                                        <FormGroup
                                            type={Styles.DefaultNumber}
                                            name="prozentual"
                                            min='0'
                                            max='100'
                                            value={prozentual}
                                            onChange={setprozentual}
                                        >
                                        </FormGroup>
                                    </td>
                                    <td>%</td>
                                </tr>
                                <tr>
                                    <td className={td_label}>Unterbrechung 1:</td>
                                    <td>
                                        <FormGroup
                                            type={Styles.DefaultDate}
                                            name="ub1von"
                                            value={ub1von}
                                            min={Dates.toIsoDateString(start)}
                                            max={Dates.toIsoDateString(end)}
                                            onChange={setub1von}
                                        >
                                        </FormGroup>
                                    </td>
                                    <td>
                                        <FormGroup
                                            type={Styles.DefaultDate}
                                            name="ub1bis"
                                            value={ub1bis}
                                            min={Dates.toIsoDateString(start)}
                                            max={Dates.toIsoDateString(end)}
                                            onChange={setub1bis}
                                        >
                                        </FormGroup>
                                    </td>
                                    <td>
                                        {/* <FormGroup
                                            type={Styles.DefaultNumber}
                                            name="ub1proz"
                                            min='0'
                                            max={prozentual}
                                            value={ub1proz}
                                            onChange={setub1proz}
                                        />                                         */}
                                    </td>
                                    <td>
                                        {/* % */}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={td_label}>Unterbrechung 2:</td>
                                    <td>
                                        <FormGroup
                                            type={Styles.DefaultDate}
                                            name="ub2von"
                                            value={ub2von}
                                            min={Dates.toIsoDateString(start)}
                                            max={Dates.toIsoDateString(end)}
                                            onChange={setub2von}
                                        >
                                        </FormGroup>
                                    </td>
                                    <td>
                                        <FormGroup
                                            type={Styles.DefaultDate}
                                            name="ub2bis"
                                            value={ub2bis}
                                            min={Dates.toIsoDateString(start)}
                                            max={Dates.toIsoDateString(end)}
                                            onChange={setub2bis}
                                        >
                                        </FormGroup>
                                    </td>
                                    <td>
                                        {/* <FormGroup
                                            type={Styles.DefaultNumber}
                                            name="ub2proz"
                                            min='0'
                                            max={prozentual}
                                            value={ub2proz}
                                            onChange={setub2proz}
                                        />  */}
                                    </td>
                                    <td>
                                        {/* % */}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={td_label}>Unterbrechung 3:</td>
                                    <td>
                                        <FormGroup
                                            type={Styles.DefaultDate}
                                            name="ub3von"
                                            value={ub3von}
                                            min={Dates.toIsoDateString(start)}
                                            max={Dates.toIsoDateString(end)}
                                            onChange={setub3von}
                                        >
                                        </FormGroup>
                                    </td>
                                    <td>
                                        <FormGroup
                                            type={Styles.DefaultDate}
                                            name="ub3bis"
                                            value={ub3bis}
                                            min={Dates.toIsoDateString(start)}
                                            max={Dates.toIsoDateString(end)}
                                            onChange={setub3bis}
                                        >
                                        </FormGroup>
                                    </td>
                                    <td>
                                        {/* <FormGroup
                                            type={Styles.DefaultNumber}
                                            name="ub3proz"
                                            min='0'
                                            max={prozentual}
                                            value={ub3proz}
                                            onChange={setub3proz}                                            
                                        />  */}
                                    </td>
                                    <td>
                                        {/* % */}
                                    </td>
                                </tr>
                                <tr className='table-blank-row'></tr>
                                <tr>
                                    <td className='td_label'>Einrichtung:</td><td colSpan={4}>{formatEinrichtung(einrichtung)}</td>
                                </tr>
                                {/* <tr className='table-blank-row'></tr> */}
                                <tr>
                                    <td className='td_label'>Teilnehmer:</td><td colSpan={4}>{formatTeilnehmer(teilnehmer)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                <td>
                    <table>
                        <tbody
                            style={{overflow:'auto',height:'50px'}}
                        >                            
                            <tr className='table-blank-row'></tr>    
                            <tr>
                                <td className="td_fitwidth">{toShortDateString(mstart)}</td>
                                <td className="td_fitwidth">{toShortDateString(mend)}</td>
                                <td className="td_fitwidth">{mprozentual}%</td>
                                <td className="td_fitwidth">{formatCurrency(betragausgezahlt)}</td>
                                <td></td>
                            </tr>
                            {/* <tr>
                                <td className="td_fitwidth">{toShortDateString(start)}</td>
                                <td className="td_fitwidth">{toShortDateString(end)}</td>
                                <td className="td_fitwidth">{prozentual}%</td>
                                <td className="td_fitwidth">{formatCurrency(betragausgezahlt)}</td>
                                <td></td>
                            </tr> */}
                            { berechnungsliste && (berechnungsliste.length > 0) && <>
                                {
                                    berechnungsliste.map( ({ id, start, end, proz, betrag } ) => {
                                        return (
                                            <tr key={id}>
                                                <td className="td_fitwidth">{toShortDateString(start)}</td>
                                                <td className="td_fitwidth">{toShortDateString(end)}</td>
                                                <td className="td_fitwidth">{proz}%</td>
                                                <td className="td_fitwidth">{formatCurrency(betrag)}</td>
                                                <td></td>
                                            </tr>)                                        
                                    })

                                }
                                </>
                            }                                                                                    
                            <tr>
                                <td className={td_blue_label}>Summe</td>
                                <td></td>
                                <td></td>
                                <td className={td_blue_label}>{formatCurrency(neuerbetrag)}</td>
                            </tr> 
                            <tr className='table-blank-row'></tr>
                            <tr>                            
                                <td className='td_button' colSpan={2}>
                                    <button 
                                        className='exportbtn'
                                        name="btnverrechungberechnen"
                                        onClick={btnVerrechnungBerechnenClick}
                                    >
                                        Verrechnung berechnen
                                    </button>
                                </td>                            
                                <td className='td_button' colSpan={2}>
                                    {/* <button
                                        className='exportbtn'
                                        name='btnmassnahmenverrechnen'
                                        onClick={btnMassnahmenVerrechnenClick}                        
                                        disabled={!massnahmen || massnahmen.length === 0 || offenerbetrag === 0}
                                    >
                                    Maßnahmen verrechnen
                                    </button>                         */}
                                </td>
                            </tr>                                    
                        </tbody>
                    </table>                    
                </td>
                <td>
                    <table>
                        <tbody>
                            <tr>
                                <td className={td_label}>Betrag gezahlt:</td>
                                <td className={td_label}>{formatCurrency(betragausgezahlt)}</td>                                
                            </tr>
                            <tr>
                                <td className={td_label}>Neuer Betrag:</td>
                                <td className={td_label}>{formatCurrency(neuerbetrag)}</td>
                            </tr>
                            <tr>
                                <td className={td_label}>Differenz:</td>
                                <td className={td_label}>{formatCurrency(differenzbetrag)}</td>
                            </tr>
                            <tr>
                                <td className='td_label'>Offen:</td>
                                <td className='td_label' 
                                    style={offenerbetrag ? {background:'#FFFF00'} : {background:'#00FF00'}}
                                >{formatCurrency(offenerbetrag)}</td>
                            </tr>
                        </tbody>
                    </table>           
                </td>                
            </tr>            
        </tbody>
        </table>        
        <table className='sm_table'>
            <tbody>
                <tr>
                    <th className={td_head}>MID</th>
                    <th className={td_head}>Teilnehmer</th>
                    <th className={td_head}>Status</th>
                    <th className={td_head}>Von</th>
                    <th className={td_head}>Bis</th>
                    <th className={td_head}>Berechnet</th>
                    <th className={td_head}>Abzug</th>
                    <th>Aktion</th>
                </tr>
                {
                    massnahmen && massnahmen.length>0 && <>
                    {
                        massnahmen.map( ({ id, Teilnehmer, status, Massnahmenstatus, f_von, f_bis, betragBerechnet, betragBestaetigt } ) => {
                            return (
                                <tr key={id}>
                                    <td className="td_fitwidth">{id}</td>
                                    <td>{formatTeilnehmer(Teilnehmer)}</td>
                                    <td className="td_fitwidth">{Massnahmenstatus.kurzbezeichnung} ({status})</td>
                                    <td className="td_fitwidth">{toShortDateString(f_von)}</td>
                                    <td className="td_fitwidth">{toShortDateString(f_bis)}</td>
                                    <td className="td_fitwidth">{formatCurrency(betragBerechnet)}</td>
                                    <td className="td_fitwidth">{formatCurrency(betragBerechnet - betragBestaetigt)}</td>
                                    <td className="td_label_center">
                                        <button id={id}
                                            className="exportbtn"
                                            disabled={isaktiondisabled()}
                                            onClick={aktion}
                                        >
                                            &nbsp;&plusmn;&nbsp;
                                        </button>
                                    </td>
                                    {/* <td className="td_fitwidth"><button>&minus;</button></td> */}

                                </tr>)                                        
                        })
                    }
                    </>
                }
            </tbody>
        </table>
        <table className='sm_table'>
            <tbody>
                <tr className='table-blank-row4' />
                <tr>
                    <td className="td_fitwidth">
                        <button 
                            className='exportbtn'
                            name='btnkorrekturverbuchen'
                            onClick={btnkorrekturverbuchenClick}
                            disabled={offenerbetrag !== 0 || (massnahmen && massnahmen.length === 0)}
                        
                        >Korrektur verbuchen</button>
                    </td>
                    <td className="td_fitwidth">
                        <button 
                            className='exportbtn'
                            name='btnverrechnungzurueckstellen'
                            onClick={btnVerrechnungZurueckstellenClick}

                        
                        >Speichern</button>          
                        {/* Verrechnung zurückstellen</button> */}
                    </td>
                    <td align='right'>
                        <button className='exportbtn' onClick={canceldata}>Abbrechen</button>                        
                    </td>
                </tr>
            </tbody>

        </table>      
  </div>
};

KuerzungNachZahlungDialog.propTypes = {
    callback : PropTypes.func,
    mid : PropTypes.number
};

export default KuerzungNachZahlungDialog;
