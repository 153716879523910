
import { FetchService } from "../../services";

class PO
{
    #mprintid;
    #mprintmessage;
    #showdialogcallback;
    #showdialogcallbacktarget;
  
    constructor(par) {
        // console.log("Constructor PO " + par) 
        par = null;       
    }

    get printid() { return this.#mprintid; }
    get printmessage() { return this.#mprintmessage; }

    initComponent(target, showdialogcallback) {
        // console.log("PO initComponent")
        // console.log(target)
        // console.log(showdialogcallback)
        this.#showdialogcallback = showdialogcallback        
        this.#showdialogcallbacktarget = target
    }

    createPrintData(filename, printid, mid) 
    {      
        // console.log("PO createPrintData " + filename + " " + printid + " " + mid)  
        this.#mprintid = printid
        this.#mprintmessage = '\nIst die Datei\n' + filename + '\nerfolgreich gedruckt worden?\n'               
        this.#showdialogcallback.apply(this.#showdialogcallbacktarget, [true]);
        // this.#showdialogcallback(true)

        const pd = {
            filename : filename,
            printid : printid,
            mid : mid
        }        
        return pd;
    }    
    
    documentprint_callback (cmd, param)
    {     
        if (param) {                        
            if (cmd === 'yes') {
                let res = FetchService.updatePrintjob([param], true)                
                document.location.reload(false)                
            } else if (cmd === 'no')  {
                let res = FetchService.updatePrintjob([param], false)                
            }
        } else if (cmd === 'close') {
            this.#mprintmessage = null
            this.#mprintid = 0
        }
        this.#showdialogcallback.apply(this.#showdialogcallbacktarget, [false]);
    }
}

// const PO = new PrintObject("PO")

export { PO };