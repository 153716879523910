import React, { useEffect, useState } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { AiOutlineSave } from 'react-icons/ai';
import { FcCancel } from 'react-icons/fc';
import { getFuseOptions } from '../../util/search';


// import de from "date-fns/locale/de";

// import MaskedInput from 'react-maskedinput'
// import Tooltip from '@material-ui/core/Tooltip';
import ConfigIcon from '../../util/configicon';
import FormGroup from '../../components/shared/form-group'
import LastEdit from '../../components/app/last-edit'
import { formatEinrichtung, formatTeilnehmer } from '../../util/formate.js';
import { selectForEinwilligungsUmfangCombo } from '../../util/combo-utils';
import FetchService from '../../services/FetchService';
// import { getFuseOptions } from '../../util/search';
import { getReactSearchAutocompleteStyle } from '../../util/style';
// import HistoryStack from '../../util/HistoryStack';
// import { useHistory } from 'react-router-dom';
import { authenticationService } from '../../services';
import UpdateHandler from '../../util/UpdateHandler';
import { Dates } from 'dkgcommon';


function BeschaeftigungEdit( props ) {    

   
    // console.log("KontenEdit " + JSON.stringify(props))
    const uri = new URL(window.location.href)
    const bid = uri.searchParams.get("bid")
    const tid = uri.searchParams.get("tid");
    // console.log("bid " + bid)
    // console.log("tid " + tid)

    // Überschrift des Forms
    const [teilnehmerName, setTeilnehmerName] = useState(null);    


    const [beschaeftigungsId, setBeschaeftigungsId] = useState(null);
    const [einrichtungsName, setEinrichtungsName] = useState("");
    const [einrichtungsId, setEinrichtungsId] = useState(null);

    const [beginnBeschaeftigung, setBeginnBeschaeftigung] = useState("Invalid Date");    
    const [etikettBenoetigt, setEtikettBenoetigt] = useState(false)
    const [einwilligung, setEinwilligung] = useState(false);
    const [einwilligungsUmfang, setEinwilligungsUmfang] = useState(null);  
    
    // @todo werden die Felder gebraucht?
    const [datumletzteBestaetigung, setDatumLetzteBestaetigung] = useState(null);
    const [anzahlBestaetigungen, setAnzahlBestaetigungen] = useState(0)
    
    

    const [editUser, setEditUser] = useState(null);
    const [editTime, setEditTime] = useState(null);
    const [canEdit] = useState(authenticationService.currentUser.category > 0)

    // Combobx Einwilligungen zur Evaluation
    const [einwilligungsUmfangData, setEinwilligungsUmfangData] = useState(null);

    const [errorMessage, setErrorMessage] = useState(null);

    let foundEinrichtungsId = 0;
    
    useEffect(() => {        
        async function asyncLoadBeschaeftigung()
        {   
            // console.log("tid " + tid)  
            // console.log("bid " + bid)
            if (bid) {
                try {                                
                    const response = await fetch('/data/beschaeftigungen/' + bid)
                    if (response.ok) {                    
                        const result = await response.json();                        
                        if (Array.isArray(result))
                        {     
                            // console.log(result)                   
                            // setErrorMessage(JSON.stringify(result))

                            setBeschaeftigungsId(result[0].id)
                            setEinwilligung(result[0].einwilligungEvaluation)
                            setEinwilligungsUmfang(result[0].einwilligungsUmfang)
                            if (result[0].beginnBeschaeftigung) {
                                setBeginnBeschaeftigung(new Date(Date.parse(result[0].beginnBeschaeftigung)))
                            }
                            setEtikettBenoetigt(result[0].etikettBenoetigt)
                            setEditUser(result[0].LetzterBearbeiter)
                            if (result[0].LetzteAenderung) {
                                setEditTime(new Date(Date.parse(result[0].LetzteAenderung)))    
                            }
                            setAnzahlBestaetigungen(result[0].anzahlBestaetigungen);
                            setDatumLetzteBestaetigung(result[0].datumLetzteBestaetigung);

                            // Einrichtungs Name
                            setEinrichtungsName(formatEinrichtung(result[0].Einrichtungen))
                            setEinrichtungsId(result[0].Einrichtungen.id)

                            // Kopfzeile
                            // Neues Beschaftigungsverhältnis für Name, Vorname GeburtsName (GeburtsDatum)"
                            setTeilnehmerName("Beschäftigungsverhältnis für " + formatTeilnehmer(
                                result[0].Teilnehmer) + " bearbeiten")
                            return;
                        }                   
                        throw new Error(result.name)                                    
                    }
                    else
                    {                     
                        throw new Error(response.status + " " + response.statusText)
                    }
                }
                catch (err) {                
                    console.log(err)
                    setErrorMessage(err)
                }         
            }
            else if (tid) 
            {
                try {                    
                    const response = await fetch('/data/teilnehmer/' + tid)
                    if (response.ok) {                    
                        const result = await response.json();  
                        // console.log("Teilnehmer " + JSON.stringify(result))
                        setTeilnehmerName("Neues Beschäftigungsverhältnis für " + formatTeilnehmer(result))
                        setEtikettBenoetigt(true)
                        setEinwilligung(true)
                        setEinwilligungsUmfang(2)
                        return;
                    }                    
                    else 
                    {
                        throw new Error(response.status + " " + response.statusText)
                    }
                }
                catch (err) {                
                    console.log(err)
                    setErrorMessage(err)
                }
            }
        }
        if ((tid) || (bid)) {
            asyncLoadBeschaeftigung()
        }
    }, [tid, bid])

    useEffect( () => 
    {
        async function asyncFetchEinwilligungsUmfangData()
        {   
            setEinwilligungsUmfangData(await selectForEinwilligungsUmfangCombo())
        }
        if (!einwilligungsUmfangData) {
            asyncFetchEinwilligungsUmfangData()                    
        }
    }, [einwilligungsUmfangData])
    
    const [elist, setElist] = useState( [{id:0,name:""}]);    
    useEffect(() => {
        async function loadElist()
        {
            const res = await FetchService.loadEinrichtungsDatalist(null)       
            if (Array.isArray(res))
            {   
                // console.log(res[0])   
                let lst = []
                res.forEach(function(item) 
                {   
                    const val = { id:item.id, name: formatEinrichtung(item, "nopk") }                    
                    lst.push(val)
                });      
                setElist(lst) 
           
                foundEinrichtungsId = localStorage.getItem("FOUND_EID")
                if (foundEinrichtungsId) {
                    const found = lst.find( item => item.id === Number.parseInt(foundEinrichtungsId))
                    foundEinrichtungsId = 0
                    localStorage.setItem("FOUND_EID", null)
                    if (found) {
                        handleOnSelect(found)
                    }
                }
            }
        }
        // console.log("loadElis " + einrichtungsId)
        loadElist()
    }, [])

    const selectEinrichtung = () => {
        window.location.href = 'SearchEinrichtung/Select'
    }

     
    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log("onsearch " , string, results)
        // if (!string.startsWith(' ')) {
        //     setEinrichtungsName (" " + string);
        // }
    }

    const handleOnHover = (result) => { // the item hovered
       console.log(result)
    }

    const handleOnSelect = (item) => {  // the item selected
        // console.log(item)
        setEinrichtungsName(item.name)
        setEinrichtungsId(item.id)
    }

    const handleOnFocus = () => {
    //  console.log('Focused')
    }

    const formatResult = (item) => { return item.name;  }
        
    const savedata = async () =>
    { 
        if (!einrichtungsId) {
            alert("Fehler: Es muss ein Einrichtung ausgewählt werden")
            return
        }
        const data = 
        {
            id : bid,
            teilnehmer_id : tid,
            einrichtungen_id : einrichtungsId,
            beginnBeschaeftigung : Dates.toValidDate(beginnBeschaeftigung),
            einwilligungEvaluation  : einwilligung,
            einwilligungsUmfang: einwilligungsUmfang,
            datumLetzteBestaetigung : datumletzteBestaetigung,
            anzahlBestaetigungen : anzahlBestaetigungen,
            etikettBenoetigt : etikettBenoetigt,
            // LetzterBearbeiter : wird von der Datenschicht gesetzt
            // LetzteAenderung : wird von der Datenschicht gesetzt
        }
        const msg = await FetchService.saveBeschaeftigung(data);
        if (msg !== "OK")
        {
             alert("Fehler: " + msg)
             return;
        }         
        UpdateHandler.updateStorageBL(tid)
        window.history.back();
    }

    const canceldata = () =>
    {      
        window.history.back();     
    }  

    return (  
        <> 
        {
            (errorMessage) && <h3>{`${errorMessage}`}</h3>   
        }        
        {
            (!errorMessage) && <div className="sm_table_div"> 
                <table> 
                    <thead>
                        <tr className="table-blank-row"><td></td></tr>
                        <tr>
                            <th colSpan="6"><h3>{`${teilnehmerName}`}</h3></th>                      
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="table-blank-row"><td></td><td></td></tr>
                        <tr>
                            <td className="td_label">Beschäftigungs ID:</td>
                            <td colSpan="1" className="td_input">{beschaeftigungsId}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>                            
                        </tr>
                        <tr>
                            <td className="td_label">Einrichtung:</td>
                            {canEdit && <>
                                <td className="td_input" height="36px">                                                            
                                    <ReactSearchAutocomplete    
                                        className="rsac_eid"                                                  
                                        items={elist}                                
                                        fuseOptions={getFuseOptions(0.4, 5)}
                                        onSearch={handleOnSearch}
                                        onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        autoFocus
                                        formatResult={formatResult}
                                        inputSearchString={einrichtungsName}
                                        showIcon={false}
                                        placeholder="Einrichtungsname eingeben"
                                        styling={getReactSearchAutocompleteStyle()}
                                        readonly={true}
                                        maxResults={20}
                                    />                                
                                </td>
                                <td>
                                    <button onClick={selectEinrichtung}>Select</button>
                                </td>
                            </>}
                            {!canEdit && 
                            <td>
                                <FormGroup
                                    type="text"
                                    value={einrichtungsName}
                                    readonly={true}
                                />       
                            </td>                     
                            }                            
                        </tr>
                        <tr>
                            <td className="td_label">Beginn der Beschäftigung:</td>
                            <td colSpan="1" className="td_input">
                                <div id="div_datepicker1">
                                    <FormGroup
                                        type="html-date"
                                        className="form-control" //"td_datepicker"
                                        contentClassName={'react-datepicker-popper'}
                                        value={beginnBeschaeftigung}
                                        onChange={setBeginnBeschaeftigung}
                                    />
                                    {/* <DatePicker
                                        className="form-control" //"td_datepicker"
                                        selected={beginnBeschaeftigung}
                                        onChange={setBeginnBeschaeftigung}
                                        dateFormat={"dd.MM.yyyy"}
                                        // isClearable={true}
                                        showYearDropdown={true}
                                    /> */}
                                </div>
                            </td> 
                            <td></td>                       
                        </tr>
                        <tr>
                            <td className="td_label">Etikett benötigt:</td>
                            <td className="td_checkbox">
                                <FormGroup 
                                    type="checkbox"                                    
                                    name="etikett"                             
                                    value={etikettBenoetigt} 
                                    onChange={setEtikettBenoetigt}                                                                
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="td_label">Einwilligung zur Evaluation:</td>
                            <td className="td_checkbox">
                                <FormGroup 
                                    type="checkbox"
                                    // label="Einwilligung zur Evaluation"
                                    id="einwilligung"
                                    name="einwilligung"                             
                                    value={einwilligung} 
                                    onChange={setEinwilligung}                                                                
                                />
                            </td>
                        </tr>

                        <tr>
                            <td className="td_label">Einwilligungsumfang:</td>
                            <td colSpan="1" className="td_input">
                                <FormGroup 
                                    type="select"
                                    label=""
                                    name="umfang"             
                                    options={einwilligungsUmfangData}                
                                    value={einwilligungsUmfang} 
                                    onChange={setEinwilligungsUmfang}  
                                    readonly={!einwilligung}                                         
                                />
                            </td>                                                                       
                        </tr>
                        
                        <tr className="table-blank-row"></tr>
                        
                        <tr>
                            <td className='td_label' colSpan="4">
                                <LastEdit edituser={editUser} edittime={editTime}></LastEdit>
                            </td>
                        </tr>
                        <tr className="table-blank-row"><td></td></tr>
                        <tr>        
                            <td className="td_label">
                                <button className="exportbtn" title="Änderungen speichern" onClick={savedata}>                                
                                    <ConfigIcon><AiOutlineSave/></ConfigIcon>&nbsp;Speichern
                                </button>                                
                            </td>                        
                            <td >
                                <button className="cancelbtn" title="Zurück ohne Speichern" onClick={canceldata}>                                
                                    <ConfigIcon><FcCancel/></ConfigIcon>&nbsp;Abbrechen
                                </button>    
                            </td>           
                        </tr>
                    </tbody>
                </table>            
            </div>
        }      
        </>            
    )
}

export default BeschaeftigungEdit
