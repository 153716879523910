import React , { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const EditKuerzungNachzahlung = (props) => 
{    
    let kid = new URL(window.location.href).searchParams.get("kid");  
    
    const [errortext, seterrortext] = useState(null)
    const [kuerzung, setkuerzung] = useState(null)

    useEffect(() => {
        async function asyncLoadKuerzung()
        {
            if (kid) 
            {
                try
                {
                    const response = await fetch('/data/kuerzungennachzahlung/' + kid)
                    if (response.ok) {
                        const result = await response.json();    
                        // console.log(result)
                        // seterrortext(JSON.stringify(result))
                        setkuerzung(result)
                    }
                    else
                    {
                        seterrortext(response.status + " " + response.statusText)
                    }
                }            
                catch (err) {
                    seterrortext(err)
                }
            }
            else {
                seterrortext("Keine Kürzung gefunden")
            }

        }
        asyncLoadKuerzung()
    }, [kid]);
    
  
    return (
        <div>
            {errortext}
            <table>
                <tbody>
                    {
                    kuerzung && <>
                        {
                            Object.entries(kuerzung).map((item) => 
                            {
                                return (
                                <tr>
                                    <td>
                                        {""+item[0]}:
                                    </td>
                                    <td>
                                        {""+item[1]}
                                    </td>                                        
                                </tr> )
                            })
                        }  
                        <tr>
                            <td>
                                <button>Löschen</button>
                            </td>
                            <td>
                            </td>
                        </tr>                            
                    </>
                    }
                </tbody>
            </table>           
        </div>);
};

EditKuerzungNachzahlung.propTypes = {};

export default EditKuerzungNachzahlung;
