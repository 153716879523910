import React, { useEffect, useState }  from 'react'; //, ResourceProvider
import PropTypes from 'prop-types';

import FileSaver from 'file-saver';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import { toShortDateString } from '../../util/date-utils'
import { formatCurrency } from '../../util/number-utils'
import { formatTeilnehmer, formatEinrichtung } from '../../util/formate';
import BescheinigungErstellen from './BescheinigungErstellen';
import Auth from '../../services/authenticationService';
import { PO } from './PO';
import NonModal from '../../components/shared/nonmodal/NonModal';

const po = new PO("ZVerrechnet")

const ZVerrechnet = (props) => {
    
    const [mResult, setmResult] = useState(null)
    const [action, setaction] = useState(false)
    const [showprintdialog, setshowprintdialog] = useState(false)
    const nonmodalid = 'NonModalZVerrechnet';

    po.initComponent(this, setshowprintdialog)

    function showPrintDialog()
    {       
        if (po && po.printid && po.printmessage) 
        {
            const element = document.getElementById(nonmodalid)
            if (element) {
                element.style.display = 'block';                                    
            }        
        }
    }

    function documentprint_callback(cmd, param) { po.documentprint_callback(cmd, param)}

    useEffect(() => {
        async function func()
        { 
            if (showprintdialog) { showPrintDialog() }
        }
        func()
    }, [showprintdialog]);    

    useEffect( () => {
        async function asyncFetchMassnahmenZumVerrechnen()
        {                      
            const response = await fetch('/data/verrechnungen/zuverrechnen/mid', { method: 'GET' })
            if (response.ok) {                        
                const result = await response.json();   
                if (Array.isArray(result))
                { 
                    const hasaction = result.some( (item) => { return item.status === 10 })                    
                    setaction(hasaction)
                    // if (result.length > 0)                      
                    //     console.log(result [0])
                    setmResult(result)
                }
            }          
        }
        asyncFetchMassnahmenZumVerrechnen()
    },[])

    async function cmdBescheinigungForClick(evt)  { // = ( async(evt) =>
        const mid = Number.parseInt(evt.currentTarget.getAttribute('mid'))
        const mn = mResult.find( (item) => { return item.id === mid})
        if (mn) {            
            const res = await BescheinigungErstellen(mid, new Date(Date.parse(mn.f_bis)))
            if (res) {
                if (res.status === 200 && res.data) {                         
                    const pd = po.createPrintData('Nachweis' + mid + '.docx', res.data.printid, mid)                    
                    FileSaver.saveAs( res.data.document, pd.filename);                                 
                }
                else {
                    alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
                    document.location.reload(true)
                }
            }            
        }        
    }

    function getValue(rec, index) {
        if (rec) {
            const idxOf = index.indexOf(".")            
            if (idxOf > 0) {
                
                return getValue( rec[index.substring(0, idxOf)], index.substring(idxOf+1) )
            }
            else {
                return rec[index]
            }
        }
        return null;
    }

    const [sortOrder , setSortOrder] = useState(false)
    const [lastSort , setLastSort] = useState(null)
    const clickSort = ((evt) => 
    { 
        const txt = evt.currentTarget.innerText        
        if (lastSort !== txt) {
            setSortOrder(false)
            setLastSort(txt)
        }
        let idx = null;
        switch (txt) 
        {
            case 'TID' : idx = 'teilnehmer_id'; break;
            case 'MID' : idx = 'id'; break;
            case 'VID' : idx = 'Verrechnungen.id'; break;      
            case 'Teilnehmer': idx = 'Teilnehmer.nachname'; break;
            case 'Einrichtung': idx = 'Einrichtungen.kurzbezeichnung'; break;
            case 'Von' : idx = 'f_von'; break;
            case 'Bis' : idx = 'f_bis'; break;
            case 'Berechnet' : idx = 'betragBerechnet'; break;
            case 'Bestaetigt' : idx = 'betragBestaetigt'; break;            
            default : idx=null; break;      
        }
        if (idx)
        {
            mResult.sort( (a, b) => {  
                if (getValue(a, idx) < getValue(b , idx)) {
                    return (sortOrder ? -1 : 1);
                } else if(getValue(a, idx) > getValue(b , idx)) {
                    return (sortOrder ? 1 : -1);
                }
                return 0;
            })
            setSortOrder(!sortOrder)            
            setmResult(mResult)
        }
    })

  return (
  <div>        
      {mResult && mResult.length > 0 && <div className="sm_table_div" >
            <table className="sm_table">
                <thead>
                    <tr>
                        <th onClick={clickSort}>MID{lastSort === 'MID' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>
                        <th onClick={clickSort}>TID{lastSort === 'TID' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>
                        {action && 
                        <th onClick={clickSort}>Aktion{lastSort === 'Aktion' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th> 
                        }
                        {Auth.isAdmin && 
                        <th onClick={clickSort}>VID{lastSort === 'VID' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th> 
                        }
                        <th onClick={clickSort}>Teilnehmer{lastSort === 'Teilnehmer' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>
                        <th onClick={clickSort}>Einrichtung{lastSort === 'Einrichtung' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>
                        <th onClick={clickSort}>Von{lastSort === 'Von' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>
                        <th onClick={clickSort}>Bis{lastSort === 'Bis' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>                       
                        <th>Berechnet</th>
                        <th>Bestaetigt</th>
                        <th onClick={clickSort}>Gekürzte&nbsp;Maßnahme{lastSort === 'Gekürzte Maßnahme' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>
                    </tr>
                </thead>
                <tbody>                
                {
                    mResult && mResult.map( ({ id, status, Teilnehmer, Einrichtungen, f_von, f_bis, Verrechnungen, betragBerechnet, betragBestaetigt }) => 
                    {  
                        return (
                        <tr className="liste" key={id}>
                        <td className="td_fitwidth"><a href={`/Massnahme/Edit?mid=${id}`}>{id}</a></td>                                    
    { Teilnehmer ?  <td className="td_fitwidth"><a href={`/Teilnehmer/Edit?tid=${Teilnehmer.id}`}>{Teilnehmer.id}</a></td> : <td></td> }
    { action && (status === 10 ? 
        <td className='td_fitwidth'>
            <button className='exportbtn' onClick={cmdBescheinigungForClick} mid={id}>Bescheinigung erstellen</button></td> : 
        <td></td>)
    }
    { Auth.isAdmin &&
         (Verrechnungen ? <td className="td_fitwidth"><a href={`/Verrechnung/Edit?vid=${Verrechnungen.id}`}>{Verrechnungen.id}</a></td> : <td></td>)
    }
                        <td className="td_fitwidth">{formatTeilnehmer(Teilnehmer, "vngd")}</td> 
                        <td className="td_fitwidth" >{formatEinrichtung(Einrichtungen,"no" )}</td>
                        <td className="td_fitwidth">{toShortDateString(f_von)}</td>
                        <td className="td_fitwidth">{toShortDateString(f_bis)}</td>
                        <td className="td_fitwidth">{formatCurrency(betragBerechnet)}</td>
                        <td className="td_fitwidth">{formatCurrency(betragBestaetigt)}</td>
    { Verrechnungen ? <td className='td_fitwidth'><a href={`/Massnahme/Edit?mid=${Verrechnungen.massnahmen_id_quelle}&tabpage=3`}>{Verrechnungen.massnahmen_id_quelle}</a></td> : <td></td> }
                        </tr>
                        )
                    })
                }
                </tbody>
            </table>
            <NonModal id={nonmodalid} type='yesno' title='Druckbestätigung' message={po.printmessage} callback={documentprint_callback} data={po.printid}></NonModal>
            </div>
            }

  </div>
  );
};

ZVerrechnet.propTypes = {};

export default ZVerrechnet;
