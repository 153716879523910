
class NumberUtils
{
    static isCharNumber(c) 
    {
        return c >= '0' && c <= '9';
    }

    static getRandomInt(max) 
    {
        return Math.floor(Math.random() * max);
    }
 
    static formatCurrency(number)
    {
        if ((number) || Number.isInteger(number)) {
            return number.toLocaleString("de-DE", {style:'currency', currency : 'EUR'});
        }
        return ''
    }
}

module.exports = NumberUtils;