import React, {useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import AnsprechpartnerListPage from './AnsprechpartnerListPage'

const AnsprechpartnerList = (props) => {
    // console.log("AnsprechpartnerList EID: " + props.eid)

    const [ansprechpartnerList, setAnsprechpartnerList] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)

 
    useEffect( () => {
        async function asyncFetchAnsprechpartnerForEinrichtungenId()
        {   
            const defaultError = [{
                anrede : 0,
                telefon: "",
                eMail: "",                
            }]
           
            try {
                // console.log("fetch ansprechpartner")
                const response = await fetch('/data/ansprechpartner/' + props.eid)
                if (response.ok) {
                    const result = await response.json();   
                    // console.log(result) 
                    if (Array.isArray(result))
                    {
                        setAnsprechpartnerList(result)
                        setIsLoaded(true)
                        return;
                    }
                    defaultError[0].anrede = "E:" + result.name
                    throw new Error(result.name)                                    
                }
                else
                {
                    defaultError[0].anrede = "E:" + response.status
                    throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                setAnsprechpartnerList(defaultError)
                console.log(err)
            }
        }
        if (props.eid) {
            // console.log("Load AnsprechpartnerList " + props.eid + " " + isLoaded)
            asyncFetchAnsprechpartnerForEinrichtungenId()
        }
    }, [props.eid, isLoaded])

    const callback = async (cmd, param) => {
        if (cmd === 'delete')
        {            
            const body = JSON.stringify({ id : param })            
            const res = await fetch('/data/admin/deleteasp',  {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: body
            });
            
            if (res.status === 200) {
                setIsLoaded(false)               
            } else {
                const txt = await res.text()
                alert(txt)
            }
        }
    }

    return (
        <AnsprechpartnerListPage 
            isLoaded={isLoaded} 
            liste={ansprechpartnerList} 
            eid={props.eid}
            callback={callback}
        />
        )
}

AnsprechpartnerList.propTypes = {
    eid : PropTypes.string
}

export default AnsprechpartnerList;
