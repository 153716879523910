import React, { Component } from 'react';
import { authenticationService } from '../../services/';
import { toShortDateString} from '../../util/date-utils';

import Spinner from '../../components/shared/spinner/Spinner';
import { version, date } from '../../../package.json'; // name,

// import { PO } from '../edit-massnahme/PO'
// import FileSaver from 'file-saver';
// import { FetchService } from '../../services';

class StartPage extends Component {

    constructor()
    {
        super();
        this.state = {
            data: "Not yet gotten",
            loaded: false,
            error: false,
            stat : {
                jahrZahlungslauf:0,
                anzMassnahmen:0,
                sumKosten:0,
                anzMassnahmenGesamt:0,
                anzTeilnehmer:0,
                anzTeilnehmerDubletten:0,
                anzEinrichtungen:0,
                anzLfdMassnahmen:0,
                anzOhneNachweis:0,
                anzNachweisbereit:0,
                anzNachweise:0,
                sumAbrechnung:0,
                datumRechnungsabschluss:0,

                datumZahlungslauf1:0,
                datumZahlungslauf2:0,
                datumZahlungslauf3:0,
                anzahlZahlungslauf1:0,
                anzahlZahlungslauf2:0,
                anzahlZahlungslauf3:0,
            },
            backupresult : null,
            restoreresult : null
        }
        this.backupDatabase = this.backupDatabase.bind(this);
        this.restoreDatabase = this.restoreDatabase.bind(this);
    }

    componentDidMount()
    {
        this.fetchData()        
    }

    async fetchData()  
    {
        let res = "DATA";
        

        const response = await fetch('/data');
        if (response.ok) 
        {
            res = await response.json();                 
            res = JSON.stringify(res);  
            // console.log(res)                   
            const jo = JSON.parse(res);            
            this.setState( {
                loaded : true, 
                data : res, 
                stat : jo.stat,
            } )
        }
        else 
        {
            const jo = {
                status : response.status,
                text : response.statusText
            }           
                                       
            if (response.status === 401)                
            {
                console.log("STATUS 401")
                if (await authenticationService.logout()) {
                    console.log("LOGOUT")
                    document.location.href = '/login';
                    return;
                }
            }
            res = JSON.stringify(jo);   
            this.state.error = true           
            this.state.data = res;
            this.setState(this.state)       
        }        
    }

    async restoreDatabase()
    {
        // console.log("restore database")
        this.setState( () => ({loaded : false }))
        let result = ""
        try {                            
            const response = await fetch('/data/database/restore')
            result = await response.text();               
        }
        catch(err) {
           result = ""+err
        }
        this.setState( () => ({ loaded: true, restoreresult : result }))
    }

    async backupDatabase() 
    {    
        // console.log("backup database " )
        this.setState( () => ({loaded : false }))
        let result = ""
        try {                            
            const response = await fetch('/data/database/backup')
            result = await response.text();               
        }
        catch(err) {
           result = ""+err
        }
        this.setState( () => ({ loaded : true, backupresult : result }))
    }

    async openDoubletten()
    {
        window.location.href = 'Doubletten'
    }
       
    render() 
    {                
        // console.log("currentUser      " + JSON.stringify(authenticationService.currentUser));            
        // console.log("currentUser Logged In  " + authenticationService.loggedIn);
        return (
            <div>
                
                {/* <button onClick={this.handleButtonClicked}>Get Weather in Berlin</button> */}
                {/* <h3>The weather in Berlin is: { [this.state.weather] } </h3>  */}
                {/* <h3> { this.state.data } </h3> */}
                {/* className="sm_table_div"> */}
                { this.state.loaded && 
                <div className='sm_table_div'>                 
                    <table className="sm_table_80">
                        <thead>
                            <tr className="table-blank-row"><td></td></tr>
                            <tr>
                                <th align="right">Letzter Zahlungslauf</th>
                                <th></th>
                                <th width={200}></th>
                                {/* <th width={200}></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="td_label_right">Jahr des letzten Zahlungslaufes:</td>
                                <td className='td_label'>{this.state.stat.jahrZahlungslauf}</td>
                                <td className='td_button' rowSpan={2}>
                                    <button 
                                        className='exportbtn warnbtn'
                                        onClick={this.restoreDatabase}
                                    >
                                        Datenbank wiederherstellen                                        
                                    </button>
                                </td>
                                <td className='td_label' rowSpan={2}>{this.state.restoreresult}</td>
                            </tr>
                            <tr>
                                <td className="td_label_right">Anzahl Ma&szlig;nahmen:</td>
                                <td className='td_label'>{this.state.stat.anzMassnahmen}</td>                                
                            </tr>
                            <tr>
                                <td className="td_label_right">Kosten:</td>
                                <td className='td_label'>{(this.state.stat.sumKosten ?? 0).toLocaleString("de-DE", {minimumFractionDigits:2, maximumFractionDigits : 2})} €</td>                                
                            </tr>
                            <tr>
                                <td className="td_label_right">Abrechnungsdatum:</td>
                                <td className='td_label'>{toShortDateString(this.state.stat.rechnungsDatum)}</td>
                            </tr>
                            
                            { this.state.stat.datumZahlungslauf1 &&
                                <>
                                    <tr>
                                        <td className="td_label_right">1. Zahlungslauf:</td>
                                        <td className='td_label'>{toShortDateString(this.state.stat.datumZahlungslauf1)}</td>
                                        <td className='td_label'>Anzahl:&nbsp;{this.state.stat.anzahlZahlungslauf1}</td>
                                    </tr>
                                </>
                            }
                            { this.state.stat.datumZahlungslauf2 &&
                                <>
                                    <tr>
                                        <td className="td_label_right">2. Zahlungslauf:</td>
                                        <td className='td_label'>{toShortDateString(this.state.stat.datumZahlungslauf2)}</td>
                                        <td className='td_label'>Anzahl:&nbsp;{this.state.stat.anzahlZahlungslauf2}</td>
                                    </tr>                            
                                </>
                            }
                            { this.state.stat.datumZahlungslauf3 &&
                                <>
                                    <tr>
                                        <td className="td_label_right">3. Zahlungslauf:</td>
                                        <td>{toShortDateString(this.state.stat.datumZahlungslauf3)}</td>
                                        <td>Anzahl:</td>
                                        <td className='td_label'>{this.state.stat.anzahlZahlungslauf3}</td>
                                    </tr>                            
                                </>
                            }                            
                            <tr className="table-blank-row"><td></td></tr>
                            <tr><th align="right">Gesamt&uuml;bersicht</th></tr>
                            <tr>
                                <td className="td_label_right">Anzahl Ma&szlig;nahmen (gesamt):</td>
                                <td className='td_label'>{this.state.stat.anzMassnahmenGesamt}</td>
                                { authenticationService.isAdmin && <>
                                    <td className='td_button' rowSpan={2}>
                                        <button
                                            className='exportbtn warnbtn'
                                            onClick={this.backupDatabase}
                                        >
                                            Datenbank sichern
                                        </button>
                                    </td>
                                    <td className='td_label' rowSpan={2}>
                                        {this.state.backupresult}
                                    </td>
                                </>
                                }                                
                            </tr>
                            <tr>
                                <td className="td_label_right">Anzahl Einrichtungen (gesamt):</td>
                                <td className='td_label'>{this.state.stat.anzEinrichtungen}</td>
                            </tr>
                            <tr>
                                <td className="td_label_right">Anzahl Teilnehmer (gesamt):</td>
                                <td className='td_label'>{this.state.stat.anzTeilnehmer}</td>                              
                                <td className='td_button' rowSpan={2}>
                                    <button 
                                        className='exportbtn'
                                        onClick={this.openDoubletten}
                                    >
                                        Doubletten suchen                                        
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td className="td_label_right">Anzahl Teilnehmer Doubletten:</td>
                                <td className='td_label'>{this.state.stat.anzTeilnehmerDubletten}</td>
                            </tr>                            
                            <tr className="table-blank-row"><td></td></tr>

                            <tr><th align="right">Laufende Ma&szlig;nahmen</th></tr>
                            <tr><td className="td_label_right">Gesamt:</td><td className='td_label'>{this.state.stat.anzLfdMassnahmen}</td></tr>
                            <tr><td className="td_label_right">Mit Nachweis:</td><td className='td_label'>{this.state.stat.anzNachweise}</td></tr>
                            <tr>
                                <td className="td_label_right">Kosten:</td>
                                <td className='td_label'>{(this.state.stat.sumAbrechnung ?? 0).toLocaleString("de-DE", {minimumFractionDigits:2, maximumFractionDigits : 2})} €</td>
                            </tr>
                            <tr><td className="td_label_right">Ohne Nachweis:</td><td className='td_label'>{this.state.stat.anzOhneNachweis}</td></tr>
                            <tr><td className="td_label_right">Nachweisbereit:</td><td className='td_label'>{this.state.stat.anzNachweisbereit}</td></tr>
                            <tr className="table-blank-row"><td></td></tr>

                            <tr><th align="right">Versionen</th></tr>
                            <tr><td className="td_label_right">Browser:</td><td className='td_label'>{version + "/" + toShortDateString(date)}</td></tr>
                            <tr><td className="td_label_right">Server:</td><td className='td_label'>{this.state.stat.nodeVersion + "/" + toShortDateString(this.state.stat.nodeDate) }</td></tr>
                            <tr><td className="td_label_right">Datenbank:</td><td className='td_label'>{this.state.stat.dbVersion + "/" + toShortDateString(this.state.stat.dbDate)}</td></tr>
                            <tr><td className="td_label_right">Import:</td><td className='td_label'>{toShortDateString(this.state.stat.importDate)}</td></tr>                         
                        </tbody>
                    </table>
                </div>
                }
                { !this.state.loaded && !this.state.error &&
                    <Spinner />
                }   
                { this.state.error &&
                    <h3>{this.state.data}</h3>
                }             
            </div>
        )        
    }    
}

export default StartPage;
