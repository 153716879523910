
export class ColumnSorter 
{
    rdata = []
    lastSort = null
    sortOrder = false
    columns = []

    constructor(arrayToSort, columnsToSort) {
        this.rdata = arrayToSort;
        this.columns = columnsToSort;
    }

    #getValue(rec, index) { // @todo use of getValue for structure search
        if (rec) {
            const idxOf = index.indexOf(".")            
            if (idxOf > 0) {
                
                return this.#getValue( rec[index.substring(0, idxOf)], index.substring(idxOf+1) )
            }
            else {
                return rec[index]
            }
        }
        return null;
    }

    sortColumn(evt) 
    {        
        if (!this.rdata || !this.columns) {
            console.log ("ColumnsSorter: rdata || columns where null")
            return null;
        }
        const txt = evt.currentTarget.innerText.trim() 
        // console.log("ColumnSorter: sort visible column " + txt)

        if (this.lastSort !== txt) {
            this.sortOrder = true
            this.lastSort = txt
        }
        
        let fcol = this.columns.find( (elem) => {
            return elem[0] === txt;
        })      
        if (!fcol) {
            console.log("ColumnSorter: column to sort not found")
            return null;
        }
        let idx = (Array.isArray(fcol) ? fcol[1] : fcol)        
        
        if (idx)
        {
            // console.log("ColumnSorter: sort by data column " + idx)              
            this.rdata.sort( (a, b) => {  
                const av = this.#getValue(a, idx)
                const bv = this.#getValue(b, idx)
                if (av === null) {
                    if (bv === null)
                        return 0;
                    else
                        return (this.sortOrder ? -1 : 1);
                }
                if (bv === null) {
                    return (this.sortOrder ? 1 : -1);
                }
                if (av < bv) {
                    return (this.sortOrder ? -1 : 1);
                } else if(av > bv) {
                    return (this.sortOrder ? 1 : -1);
                }
                return 0;
            })
            this.sortOrder = !this.sortOrder
            return this.rdata            
        }
        return null;
    }
}

export default ColumnSorter
